/*============================================================================*\
   Logo
\*============================================================================*/

.logo {
	z-index: 11;
	color: c(white);
	text-decoration: none;

	span {
		display: block;
	}
}

.logo--header {
	float: left;
}

.logo--footer {
	@media #{md("s")} {
		float: left;
	}
}

.logo__title {
	font-size: 20px;
	font-weight: 800;
	line-height: 17px;
	margin-bottom: 3px;
}

.logo__subtitle {
	font-size: 11px;
	line-height: 11px;
}
