/*============================================================================*\
	 Main file
\*============================================================================*/
/*============================================================================*\
	 Configuration file
\*============================================================================*/
/* Colors
\*============================================================================*/
/* Spaces
\*============================================================================*/
/* Typography
\*============================================================================*/
/* Displays
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/**
 * Map of width based breakpoints names and values in pixels
 *
 * @type {Map}
 */
/**
 * Map of height based breakpoints names and values in pixels
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @param  {string} $orientation     The orientation of the media query (width or height)
 * @return {string}                  A media query expression
 */
/**
 * Alias of the `media($breakpoint, $type, $unit, $orientation)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract the loop over each breakpoints
 *
 * @param  {ArgList} $excludes The breakpoints to exclude
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/**
 * Map of layer names and values to use
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {String} $layer    The name of the z-index
 * @param  {Number} $modifier The modifier if needed
 * @return {Number}           The corresponding z-index based on the $layers var
 */
/**
 * Alias for the `layer($layer, $modifier)` function above
 *
 * @param  {String} $layer    The name of the z-index
 * @param  {Number} $modifier The modifier if needed
 * @return {Number}           The corresponding z-index based on the $layers var
 */
/**
 * Deprecated alias for the `layer($layer, $modifier)` function
 *
 * @param  {String} $layer    The name of the z-index
 * @param  {Number} $modifier The modifier if needed
 * @return {Number}           The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all layers with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/**
 * Map of color names and values
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/**
 * Alias for the `color($color)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all colors with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Number} The base value of all spacings */
/** @type {List} List of all space factors */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Alias for the `space($space)` function above
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-4--xxl` class.
 *
 */
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/** @type {List} List of display values to use */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/*============================================================================*\
   Generic mixins
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 *
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-size  The minimum font-size
 * @param  {integer} $max-size  The maximum font-size
 */
/**
 * Antialiasing for better font rendering
 */
/*============================================================================*\
   Type sizes definitions
\*============================================================================*/
/**
 * A map to define all type-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * @type {Map}
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 *
 * @param  {string} $type-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/**
 * Alias for the `font-size($type-size, $unit)` mixin defined above
 */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Type font families definitions
\*============================================================================*/
/** @type {String} The path to the webfonts directory */
/** @type {String} The value for all `font-display` properties */
/**
 * A map to define all font-families specifications which we might refer to by a
 * named identifier. The map is formatted as follow:
 *
 * (
 *   <identifier>: (
 *     name: <font-family-name>,
 *     stack: <font-family-stack>,
 *     webfonts: (
 *       (
 *         filename: <webfont-filename>,
 *         weight: <webfont-weight>,
 *         style: <webfont-style>,
 *       ),
 *     ),
 *   ),
 * )
 *
 * @type {Map}
 */
/**
 * A function to get a font-family stack by its identifier
 *
 * @param  {string} $type-font The font-family identifier
 * @return {string}            The font-family defined stack
 */
/**
 * Alias for the `font-family($type-font)` function defined above
 *
 * @param  {string} $type-font The font-family identifier
 * @return {string}            The font-family defined stack
 */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Type alignement definition
\*============================================================================*/
/** @type {List} List of alignment values to use */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/** @type {List} List of font weights values to use */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Type spacings definitions
\*============================================================================*/
/** @type {List} List of letter spacings values to use */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Type transforms definitions
\*============================================================================*/
/** @type {List} List of text transform values to use */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Type decorations definitions
\*============================================================================*/
/** @type {List} List of text decoration values to use */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Flex helpers
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/*============================================================================*\
   Flex display
\*============================================================================*/
/*============================================================================*\
   Align (items / self / content)
\*============================================================================*/
/*============================================================================*\
   Justify (content / self)
\*============================================================================*/
/*============================================================================*\
   Direction
\*============================================================================*/
/*============================================================================*\
   Wrap
\*============================================================================*/
/*============================================================================*\
   Grow / Shrink
\*============================================================================*/
/** @type {List} List of flex-grow values to use */
/** @type {List} List of flex-shrink values to use */
/** @type {List} List of flex values to use */
/* Class helpers
\*============================================================================*/
/*============================================================================*\
   Opacities
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/** @type {List} List of opacity values to use */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to simplify the usage of opacities
 *
 * @param  {string} $opacity  The 100 based opacity value
 * @return {number}           The 1 based computed opacity
 */
/**
 * Alias for the `opacity($opacity)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
/*============================================================================*\
   Positions
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/** @type {List} List of position values to use */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to simplify the usage of positions
 *
 * @param  {string} $position  The position value
 * @return {number}            The position value
 */
/**
 * Alias for the `position($position)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
/* Grid
\*============================================================================*/
/*============================================================================*\
   Main framework file
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/**
 * Map of width based breakpoints names and values in pixels
 *
 * @type {Map}
 */
/**
 * Map of height based breakpoints names and values in pixels
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @param  {string} $orientation     The orientation of the media query (width or height)
 * @return {string}                  A media query expression
 */
/**
 * Alias of the `media($breakpoint, $type, $unit, $orientation)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract the loop over each breakpoints
 *
 * @param  {ArgList} $excludes The breakpoints to exclude
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Layers (z-index) definitions
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/**
 * Map of layer names and values to use
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($layers, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {String} $layer    The name of the z-index
 * @param  {Number} $modifier The modifier if needed
 * @return {Number}           The corresponding z-index based on the $layers var
 */
/**
 * Alias for the `layer($layer, $modifier)` function above
 *
 * @param  {String} $layer    The name of the z-index
 * @param  {Number} $modifier The modifier if needed
 * @return {Number}           The corresponding z-index based on the $layers var
 */
/**
 * Deprecated alias for the `layer($layer, $modifier)` function
 *
 * @param  {String} $layer    The name of the z-index
 * @param  {Number} $modifier The modifier if needed
 * @return {Number}           The corresponding z-index based on the $layers var
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all layers with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
.layer-goku {
  z-index: 9000;
}

.layer-default {
  z-index: 1;
}

.layer-limbo {
  z-index: -999;
}

/*============================================================================*\
   Easings
\*============================================================================*/
/*============================================================================*\
   Colors
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/**
 * Map of color names and values
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to avoid having to type `map-get($colors, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $color The name of the color
 * @return {number}        The value corresponding to the color's name
 */
/**
 * Alias for the `color($color)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all colors with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
.color-orange {
  color: #e39e70;
}

.background-orange {
  background-color: #e39e70;
}

.fill-orange {
  fill: #e39e70;
}

.stroke-orange {
  stroke: #e39e70;
}

.color-orange-dark {
  color: #bc7342;
}

.background-orange-dark {
  background-color: #bc7342;
}

.fill-orange-dark {
  fill: #bc7342;
}

.stroke-orange-dark {
  stroke: #bc7342;
}

.color-green {
  color: #005350;
}

.background-green {
  background-color: #005350;
}

.fill-green {
  fill: #005350;
}

.stroke-green {
  stroke: #005350;
}

.color-white {
  color: #fffbf8;
}

.background-white {
  background-color: #fffbf8;
}

.fill-white {
  fill: #fffbf8;
}

.stroke-white {
  stroke: #fffbf8;
}

.color-green-light {
  color: #739e9c;
}

.background-green-light {
  background-color: #739e9c;
}

.fill-green-light {
  fill: #739e9c;
}

.stroke-green-light {
  stroke: #739e9c;
}

.color-green-border {
  color: #236a67;
}

.background-green-border {
  background-color: #236a67;
}

.fill-green-border {
  fill: #236a67;
}

.stroke-green-border {
  stroke: #236a67;
}

/*============================================================================*\
   Spaces variables
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Number} The base value of all spacings */
/** @type {List} List of all space factors */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Alias for the `space($space)` function above
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-4--xxl` class.
 *
 */
.space-m-0 {
  margin: 0rem;
}

.space-mx-0 {
  margin-right: 0rem;
  margin-left: 0rem;
}

.space-my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.space-mt-0 {
  margin-top: 0rem;
}

.space-mr-0 {
  margin-right: 0rem;
}

.space-mb-0 {
  margin-bottom: 0rem;
}

.space-ml-0 {
  margin-left: 0rem;
}

.space-p-0 {
  padding: 0rem;
}

.space-px-0 {
  padding-right: 0rem;
  padding-left: 0rem;
}

.space-py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.space-pt-0 {
  padding-top: 0rem;
}

.space-pr-0 {
  padding-right: 0rem;
}

.space-pb-0 {
  padding-bottom: 0rem;
}

.space-pl-0 {
  padding-left: 0rem;
}

.space-m-1 {
  margin: 0.5rem;
}

.space-mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.space-my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.space-mt-1 {
  margin-top: 0.5rem;
}

.space-mr-1 {
  margin-right: 0.5rem;
}

.space-mb-1 {
  margin-bottom: 0.5rem;
}

.space-ml-1 {
  margin-left: 0.5rem;
}

.space-p-1 {
  padding: 0.5rem;
}

.space-px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.space-py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.space-pt-1 {
  padding-top: 0.5rem;
}

.space-pr-1 {
  padding-right: 0.5rem;
}

.space-pb-1 {
  padding-bottom: 0.5rem;
}

.space-pl-1 {
  padding-left: 0.5rem;
}

.space-m-2 {
  margin: 1rem;
}

.space-mx-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.space-my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.space-mt-2 {
  margin-top: 1rem;
}

.space-mr-2 {
  margin-right: 1rem;
}

.space-mb-2 {
  margin-bottom: 1rem;
}

.space-ml-2 {
  margin-left: 1rem;
}

.space-p-2 {
  padding: 1rem;
}

.space-px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.space-py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.space-pt-2 {
  padding-top: 1rem;
}

.space-pr-2 {
  padding-right: 1rem;
}

.space-pb-2 {
  padding-bottom: 1rem;
}

.space-pl-2 {
  padding-left: 1rem;
}

.space-m-3 {
  margin: 1.5rem;
}

.space-mx-3 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.space-my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.space-mt-3 {
  margin-top: 1.5rem;
}

.space-mr-3 {
  margin-right: 1.5rem;
}

.space-mb-3 {
  margin-bottom: 1.5rem;
}

.space-ml-3 {
  margin-left: 1.5rem;
}

.space-p-3 {
  padding: 1.5rem;
}

.space-px-3 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.space-py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.space-pt-3 {
  padding-top: 1.5rem;
}

.space-pr-3 {
  padding-right: 1.5rem;
}

.space-pb-3 {
  padding-bottom: 1.5rem;
}

.space-pl-3 {
  padding-left: 1.5rem;
}

.space-m-4 {
  margin: 2rem;
}

.space-mx-4 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.space-my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.space-mt-4 {
  margin-top: 2rem;
}

.space-mr-4 {
  margin-right: 2rem;
}

.space-mb-4 {
  margin-bottom: 2rem;
}

.space-ml-4 {
  margin-left: 2rem;
}

.space-p-4 {
  padding: 2rem;
}

.space-px-4 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.space-py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.space-pt-4 {
  padding-top: 2rem;
}

.space-pr-4 {
  padding-right: 2rem;
}

.space-pb-4 {
  padding-bottom: 2rem;
}

.space-pl-4 {
  padding-left: 2rem;
}

.space-m-5 {
  margin: 2.5rem;
}

.space-mx-5 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.space-my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.space-mt-5 {
  margin-top: 2.5rem;
}

.space-mr-5 {
  margin-right: 2.5rem;
}

.space-mb-5 {
  margin-bottom: 2.5rem;
}

.space-ml-5 {
  margin-left: 2.5rem;
}

.space-p-5 {
  padding: 2.5rem;
}

.space-px-5 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.space-py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.space-pt-5 {
  padding-top: 2.5rem;
}

.space-pr-5 {
  padding-right: 2.5rem;
}

.space-pb-5 {
  padding-bottom: 2.5rem;
}

.space-pl-5 {
  padding-left: 2.5rem;
}

.space-m-6 {
  margin: 3rem;
}

.space-mx-6 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.space-my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.space-mt-6 {
  margin-top: 3rem;
}

.space-mr-6 {
  margin-right: 3rem;
}

.space-mb-6 {
  margin-bottom: 3rem;
}

.space-ml-6 {
  margin-left: 3rem;
}

.space-p-6 {
  padding: 3rem;
}

.space-px-6 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.space-py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.space-pt-6 {
  padding-top: 3rem;
}

.space-pr-6 {
  padding-right: 3rem;
}

.space-pb-6 {
  padding-bottom: 3rem;
}

.space-pl-6 {
  padding-left: 3rem;
}

.space-m-7 {
  margin: 3.5rem;
}

.space-mx-7 {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.space-my-7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.space-mt-7 {
  margin-top: 3.5rem;
}

.space-mr-7 {
  margin-right: 3.5rem;
}

.space-mb-7 {
  margin-bottom: 3.5rem;
}

.space-ml-7 {
  margin-left: 3.5rem;
}

.space-p-7 {
  padding: 3.5rem;
}

.space-px-7 {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}

.space-py-7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.space-pt-7 {
  padding-top: 3.5rem;
}

.space-pr-7 {
  padding-right: 3.5rem;
}

.space-pb-7 {
  padding-bottom: 3.5rem;
}

.space-pl-7 {
  padding-left: 3.5rem;
}

.space-m-8 {
  margin: 4rem;
}

.space-mx-8 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.space-my-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.space-mt-8 {
  margin-top: 4rem;
}

.space-mr-8 {
  margin-right: 4rem;
}

.space-mb-8 {
  margin-bottom: 4rem;
}

.space-ml-8 {
  margin-left: 4rem;
}

.space-p-8 {
  padding: 4rem;
}

.space-px-8 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.space-py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.space-pt-8 {
  padding-top: 4rem;
}

.space-pr-8 {
  padding-right: 4rem;
}

.space-pb-8 {
  padding-bottom: 4rem;
}

.space-pl-8 {
  padding-left: 4rem;
}

.space-m-9 {
  margin: 4.5rem;
}

.space-mx-9 {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}

.space-my-9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.space-mt-9 {
  margin-top: 4.5rem;
}

.space-mr-9 {
  margin-right: 4.5rem;
}

.space-mb-9 {
  margin-bottom: 4.5rem;
}

.space-ml-9 {
  margin-left: 4.5rem;
}

.space-p-9 {
  padding: 4.5rem;
}

.space-px-9 {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}

.space-py-9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.space-pt-9 {
  padding-top: 4.5rem;
}

.space-pr-9 {
  padding-right: 4.5rem;
}

.space-pb-9 {
  padding-bottom: 4.5rem;
}

.space-pl-9 {
  padding-left: 4.5rem;
}

.space-m-12 {
  margin: 6rem;
}

.space-mx-12 {
  margin-right: 6rem;
  margin-left: 6rem;
}

.space-my-12 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.space-mt-12 {
  margin-top: 6rem;
}

.space-mr-12 {
  margin-right: 6rem;
}

.space-mb-12 {
  margin-bottom: 6rem;
}

.space-ml-12 {
  margin-left: 6rem;
}

.space-p-12 {
  padding: 6rem;
}

.space-px-12 {
  padding-right: 6rem;
  padding-left: 6rem;
}

.space-py-12 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.space-pt-12 {
  padding-top: 6rem;
}

.space-pr-12 {
  padding-right: 6rem;
}

.space-pb-12 {
  padding-bottom: 6rem;
}

.space-pl-12 {
  padding-left: 6rem;
}

.space-m-16 {
  margin: 8rem;
}

.space-mx-16 {
  margin-right: 8rem;
  margin-left: 8rem;
}

.space-my-16 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.space-mt-16 {
  margin-top: 8rem;
}

.space-mr-16 {
  margin-right: 8rem;
}

.space-mb-16 {
  margin-bottom: 8rem;
}

.space-ml-16 {
  margin-left: 8rem;
}

.space-p-16 {
  padding: 8rem;
}

.space-px-16 {
  padding-right: 8rem;
  padding-left: 8rem;
}

.space-py-16 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.space-pt-16 {
  padding-top: 8rem;
}

.space-pr-16 {
  padding-right: 8rem;
}

.space-pb-16 {
  padding-bottom: 8rem;
}

.space-pl-16 {
  padding-left: 8rem;
}

.space-m-22 {
  margin: 11rem;
}

.space-mx-22 {
  margin-right: 11rem;
  margin-left: 11rem;
}

.space-my-22 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.space-mt-22 {
  margin-top: 11rem;
}

.space-mr-22 {
  margin-right: 11rem;
}

.space-mb-22 {
  margin-bottom: 11rem;
}

.space-ml-22 {
  margin-left: 11rem;
}

.space-p-22 {
  padding: 11rem;
}

.space-px-22 {
  padding-right: 11rem;
  padding-left: 11rem;
}

.space-py-22 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.space-pt-22 {
  padding-top: 11rem;
}

.space-pr-22 {
  padding-right: 11rem;
}

.space-pb-22 {
  padding-bottom: 11rem;
}

.space-pl-22 {
  padding-left: 11rem;
}

.space-m-27 {
  margin: 13.5rem;
}

.space-mx-27 {
  margin-right: 13.5rem;
  margin-left: 13.5rem;
}

.space-my-27 {
  margin-top: 13.5rem;
  margin-bottom: 13.5rem;
}

.space-mt-27 {
  margin-top: 13.5rem;
}

.space-mr-27 {
  margin-right: 13.5rem;
}

.space-mb-27 {
  margin-bottom: 13.5rem;
}

.space-ml-27 {
  margin-left: 13.5rem;
}

.space-p-27 {
  padding: 13.5rem;
}

.space-px-27 {
  padding-right: 13.5rem;
  padding-left: 13.5rem;
}

.space-py-27 {
  padding-top: 13.5rem;
  padding-bottom: 13.5rem;
}

.space-pt-27 {
  padding-top: 13.5rem;
}

.space-pr-27 {
  padding-right: 13.5rem;
}

.space-pb-27 {
  padding-bottom: 13.5rem;
}

.space-pl-27 {
  padding-left: 13.5rem;
}

.space-m-30 {
  margin: 15rem;
}

.space-mx-30 {
  margin-right: 15rem;
  margin-left: 15rem;
}

.space-my-30 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.space-mt-30 {
  margin-top: 15rem;
}

.space-mr-30 {
  margin-right: 15rem;
}

.space-mb-30 {
  margin-bottom: 15rem;
}

.space-ml-30 {
  margin-left: 15rem;
}

.space-p-30 {
  padding: 15rem;
}

.space-px-30 {
  padding-right: 15rem;
  padding-left: 15rem;
}

.space-py-30 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.space-pt-30 {
  padding-top: 15rem;
}

.space-pr-30 {
  padding-right: 15rem;
}

.space-pb-30 {
  padding-bottom: 15rem;
}

.space-pl-30 {
  padding-left: 15rem;
}

.space-m-46 {
  margin: 23rem;
}

.space-mx-46 {
  margin-right: 23rem;
  margin-left: 23rem;
}

.space-my-46 {
  margin-top: 23rem;
  margin-bottom: 23rem;
}

.space-mt-46 {
  margin-top: 23rem;
}

.space-mr-46 {
  margin-right: 23rem;
}

.space-mb-46 {
  margin-bottom: 23rem;
}

.space-ml-46 {
  margin-left: 23rem;
}

.space-p-46 {
  padding: 23rem;
}

.space-px-46 {
  padding-right: 23rem;
  padding-left: 23rem;
}

.space-py-46 {
  padding-top: 23rem;
  padding-bottom: 23rem;
}

.space-pt-46 {
  padding-top: 23rem;
}

.space-pr-46 {
  padding-right: 23rem;
}

.space-pb-46 {
  padding-bottom: 23rem;
}

.space-pl-46 {
  padding-left: 23rem;
}

.space-m-60 {
  margin: 30rem;
}

.space-mx-60 {
  margin-right: 30rem;
  margin-left: 30rem;
}

.space-my-60 {
  margin-top: 30rem;
  margin-bottom: 30rem;
}

.space-mt-60 {
  margin-top: 30rem;
}

.space-mr-60 {
  margin-right: 30rem;
}

.space-mb-60 {
  margin-bottom: 30rem;
}

.space-ml-60 {
  margin-left: 30rem;
}

.space-p-60 {
  padding: 30rem;
}

.space-px-60 {
  padding-right: 30rem;
  padding-left: 30rem;
}

.space-py-60 {
  padding-top: 30rem;
  padding-bottom: 30rem;
}

.space-pt-60 {
  padding-top: 30rem;
}

.space-pr-60 {
  padding-right: 30rem;
}

.space-pb-60 {
  padding-bottom: 30rem;
}

.space-pl-60 {
  padding-left: 30rem;
}

@media (min-width: 0em) {
  .space-m-0--xxs {
    margin: 0rem;
  }

  .space-mx-0--xxs {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xxs {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xxs {
    margin-top: 0rem;
  }

  .space-mr-0--xxs {
    margin-right: 0rem;
  }

  .space-mb-0--xxs {
    margin-bottom: 0rem;
  }

  .space-ml-0--xxs {
    margin-left: 0rem;
  }

  .space-p-0--xxs {
    padding: 0rem;
  }

  .space-px-0--xxs {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xxs {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xxs {
    padding-top: 0rem;
  }

  .space-pr-0--xxs {
    padding-right: 0rem;
  }

  .space-pb-0--xxs {
    padding-bottom: 0rem;
  }

  .space-pl-0--xxs {
    padding-left: 0rem;
  }

  .space-m-1--xxs {
    margin: 0.5rem;
  }

  .space-mx-1--xxs {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xxs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xxs {
    margin-top: 0.5rem;
  }

  .space-mr-1--xxs {
    margin-right: 0.5rem;
  }

  .space-mb-1--xxs {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xxs {
    margin-left: 0.5rem;
  }

  .space-p-1--xxs {
    padding: 0.5rem;
  }

  .space-px-1--xxs {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xxs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xxs {
    padding-top: 0.5rem;
  }

  .space-pr-1--xxs {
    padding-right: 0.5rem;
  }

  .space-pb-1--xxs {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xxs {
    padding-left: 0.5rem;
  }

  .space-m-2--xxs {
    margin: 1rem;
  }

  .space-mx-2--xxs {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xxs {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xxs {
    margin-top: 1rem;
  }

  .space-mr-2--xxs {
    margin-right: 1rem;
  }

  .space-mb-2--xxs {
    margin-bottom: 1rem;
  }

  .space-ml-2--xxs {
    margin-left: 1rem;
  }

  .space-p-2--xxs {
    padding: 1rem;
  }

  .space-px-2--xxs {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xxs {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xxs {
    padding-top: 1rem;
  }

  .space-pr-2--xxs {
    padding-right: 1rem;
  }

  .space-pb-2--xxs {
    padding-bottom: 1rem;
  }

  .space-pl-2--xxs {
    padding-left: 1rem;
  }

  .space-m-3--xxs {
    margin: 1.5rem;
  }

  .space-mx-3--xxs {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xxs {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xxs {
    margin-top: 1.5rem;
  }

  .space-mr-3--xxs {
    margin-right: 1.5rem;
  }

  .space-mb-3--xxs {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xxs {
    margin-left: 1.5rem;
  }

  .space-p-3--xxs {
    padding: 1.5rem;
  }

  .space-px-3--xxs {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xxs {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xxs {
    padding-top: 1.5rem;
  }

  .space-pr-3--xxs {
    padding-right: 1.5rem;
  }

  .space-pb-3--xxs {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xxs {
    padding-left: 1.5rem;
  }

  .space-m-4--xxs {
    margin: 2rem;
  }

  .space-mx-4--xxs {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xxs {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xxs {
    margin-top: 2rem;
  }

  .space-mr-4--xxs {
    margin-right: 2rem;
  }

  .space-mb-4--xxs {
    margin-bottom: 2rem;
  }

  .space-ml-4--xxs {
    margin-left: 2rem;
  }

  .space-p-4--xxs {
    padding: 2rem;
  }

  .space-px-4--xxs {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xxs {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xxs {
    padding-top: 2rem;
  }

  .space-pr-4--xxs {
    padding-right: 2rem;
  }

  .space-pb-4--xxs {
    padding-bottom: 2rem;
  }

  .space-pl-4--xxs {
    padding-left: 2rem;
  }

  .space-m-5--xxs {
    margin: 2.5rem;
  }

  .space-mx-5--xxs {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xxs {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xxs {
    margin-top: 2.5rem;
  }

  .space-mr-5--xxs {
    margin-right: 2.5rem;
  }

  .space-mb-5--xxs {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xxs {
    margin-left: 2.5rem;
  }

  .space-p-5--xxs {
    padding: 2.5rem;
  }

  .space-px-5--xxs {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xxs {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xxs {
    padding-top: 2.5rem;
  }

  .space-pr-5--xxs {
    padding-right: 2.5rem;
  }

  .space-pb-5--xxs {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xxs {
    padding-left: 2.5rem;
  }

  .space-m-6--xxs {
    margin: 3rem;
  }

  .space-mx-6--xxs {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xxs {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xxs {
    margin-top: 3rem;
  }

  .space-mr-6--xxs {
    margin-right: 3rem;
  }

  .space-mb-6--xxs {
    margin-bottom: 3rem;
  }

  .space-ml-6--xxs {
    margin-left: 3rem;
  }

  .space-p-6--xxs {
    padding: 3rem;
  }

  .space-px-6--xxs {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xxs {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xxs {
    padding-top: 3rem;
  }

  .space-pr-6--xxs {
    padding-right: 3rem;
  }

  .space-pb-6--xxs {
    padding-bottom: 3rem;
  }

  .space-pl-6--xxs {
    padding-left: 3rem;
  }

  .space-m-7--xxs {
    margin: 3.5rem;
  }

  .space-mx-7--xxs {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xxs {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xxs {
    margin-top: 3.5rem;
  }

  .space-mr-7--xxs {
    margin-right: 3.5rem;
  }

  .space-mb-7--xxs {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xxs {
    margin-left: 3.5rem;
  }

  .space-p-7--xxs {
    padding: 3.5rem;
  }

  .space-px-7--xxs {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xxs {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xxs {
    padding-top: 3.5rem;
  }

  .space-pr-7--xxs {
    padding-right: 3.5rem;
  }

  .space-pb-7--xxs {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xxs {
    padding-left: 3.5rem;
  }

  .space-m-8--xxs {
    margin: 4rem;
  }

  .space-mx-8--xxs {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xxs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xxs {
    margin-top: 4rem;
  }

  .space-mr-8--xxs {
    margin-right: 4rem;
  }

  .space-mb-8--xxs {
    margin-bottom: 4rem;
  }

  .space-ml-8--xxs {
    margin-left: 4rem;
  }

  .space-p-8--xxs {
    padding: 4rem;
  }

  .space-px-8--xxs {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xxs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xxs {
    padding-top: 4rem;
  }

  .space-pr-8--xxs {
    padding-right: 4rem;
  }

  .space-pb-8--xxs {
    padding-bottom: 4rem;
  }

  .space-pl-8--xxs {
    padding-left: 4rem;
  }

  .space-m-9--xxs {
    margin: 4.5rem;
  }

  .space-mx-9--xxs {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xxs {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xxs {
    margin-top: 4.5rem;
  }

  .space-mr-9--xxs {
    margin-right: 4.5rem;
  }

  .space-mb-9--xxs {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xxs {
    margin-left: 4.5rem;
  }

  .space-p-9--xxs {
    padding: 4.5rem;
  }

  .space-px-9--xxs {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xxs {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xxs {
    padding-top: 4.5rem;
  }

  .space-pr-9--xxs {
    padding-right: 4.5rem;
  }

  .space-pb-9--xxs {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xxs {
    padding-left: 4.5rem;
  }

  .space-m-12--xxs {
    margin: 6rem;
  }

  .space-mx-12--xxs {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xxs {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xxs {
    margin-top: 6rem;
  }

  .space-mr-12--xxs {
    margin-right: 6rem;
  }

  .space-mb-12--xxs {
    margin-bottom: 6rem;
  }

  .space-ml-12--xxs {
    margin-left: 6rem;
  }

  .space-p-12--xxs {
    padding: 6rem;
  }

  .space-px-12--xxs {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xxs {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xxs {
    padding-top: 6rem;
  }

  .space-pr-12--xxs {
    padding-right: 6rem;
  }

  .space-pb-12--xxs {
    padding-bottom: 6rem;
  }

  .space-pl-12--xxs {
    padding-left: 6rem;
  }

  .space-m-16--xxs {
    margin: 8rem;
  }

  .space-mx-16--xxs {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xxs {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xxs {
    margin-top: 8rem;
  }

  .space-mr-16--xxs {
    margin-right: 8rem;
  }

  .space-mb-16--xxs {
    margin-bottom: 8rem;
  }

  .space-ml-16--xxs {
    margin-left: 8rem;
  }

  .space-p-16--xxs {
    padding: 8rem;
  }

  .space-px-16--xxs {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xxs {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xxs {
    padding-top: 8rem;
  }

  .space-pr-16--xxs {
    padding-right: 8rem;
  }

  .space-pb-16--xxs {
    padding-bottom: 8rem;
  }

  .space-pl-16--xxs {
    padding-left: 8rem;
  }

  .space-m-22--xxs {
    margin: 11rem;
  }

  .space-mx-22--xxs {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xxs {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xxs {
    margin-top: 11rem;
  }

  .space-mr-22--xxs {
    margin-right: 11rem;
  }

  .space-mb-22--xxs {
    margin-bottom: 11rem;
  }

  .space-ml-22--xxs {
    margin-left: 11rem;
  }

  .space-p-22--xxs {
    padding: 11rem;
  }

  .space-px-22--xxs {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xxs {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xxs {
    padding-top: 11rem;
  }

  .space-pr-22--xxs {
    padding-right: 11rem;
  }

  .space-pb-22--xxs {
    padding-bottom: 11rem;
  }

  .space-pl-22--xxs {
    padding-left: 11rem;
  }

  .space-m-27--xxs {
    margin: 13.5rem;
  }

  .space-mx-27--xxs {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xxs {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xxs {
    margin-top: 13.5rem;
  }

  .space-mr-27--xxs {
    margin-right: 13.5rem;
  }

  .space-mb-27--xxs {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xxs {
    margin-left: 13.5rem;
  }

  .space-p-27--xxs {
    padding: 13.5rem;
  }

  .space-px-27--xxs {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xxs {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xxs {
    padding-top: 13.5rem;
  }

  .space-pr-27--xxs {
    padding-right: 13.5rem;
  }

  .space-pb-27--xxs {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xxs {
    padding-left: 13.5rem;
  }

  .space-m-30--xxs {
    margin: 15rem;
  }

  .space-mx-30--xxs {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xxs {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xxs {
    margin-top: 15rem;
  }

  .space-mr-30--xxs {
    margin-right: 15rem;
  }

  .space-mb-30--xxs {
    margin-bottom: 15rem;
  }

  .space-ml-30--xxs {
    margin-left: 15rem;
  }

  .space-p-30--xxs {
    padding: 15rem;
  }

  .space-px-30--xxs {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xxs {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xxs {
    padding-top: 15rem;
  }

  .space-pr-30--xxs {
    padding-right: 15rem;
  }

  .space-pb-30--xxs {
    padding-bottom: 15rem;
  }

  .space-pl-30--xxs {
    padding-left: 15rem;
  }

  .space-m-46--xxs {
    margin: 23rem;
  }

  .space-mx-46--xxs {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xxs {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xxs {
    margin-top: 23rem;
  }

  .space-mr-46--xxs {
    margin-right: 23rem;
  }

  .space-mb-46--xxs {
    margin-bottom: 23rem;
  }

  .space-ml-46--xxs {
    margin-left: 23rem;
  }

  .space-p-46--xxs {
    padding: 23rem;
  }

  .space-px-46--xxs {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xxs {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xxs {
    padding-top: 23rem;
  }

  .space-pr-46--xxs {
    padding-right: 23rem;
  }

  .space-pb-46--xxs {
    padding-bottom: 23rem;
  }

  .space-pl-46--xxs {
    padding-left: 23rem;
  }

  .space-m-60--xxs {
    margin: 30rem;
  }

  .space-mx-60--xxs {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xxs {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xxs {
    margin-top: 30rem;
  }

  .space-mr-60--xxs {
    margin-right: 30rem;
  }

  .space-mb-60--xxs {
    margin-bottom: 30rem;
  }

  .space-ml-60--xxs {
    margin-left: 30rem;
  }

  .space-p-60--xxs {
    padding: 30rem;
  }

  .space-px-60--xxs {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xxs {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xxs {
    padding-top: 30rem;
  }

  .space-pr-60--xxs {
    padding-right: 30rem;
  }

  .space-pb-60--xxs {
    padding-bottom: 30rem;
  }

  .space-pl-60--xxs {
    padding-left: 30rem;
  }
}
@media (min-width: 30em) {
  .space-m-0--xs {
    margin: 0rem;
  }

  .space-mx-0--xs {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xs {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xs {
    margin-top: 0rem;
  }

  .space-mr-0--xs {
    margin-right: 0rem;
  }

  .space-mb-0--xs {
    margin-bottom: 0rem;
  }

  .space-ml-0--xs {
    margin-left: 0rem;
  }

  .space-p-0--xs {
    padding: 0rem;
  }

  .space-px-0--xs {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xs {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xs {
    padding-top: 0rem;
  }

  .space-pr-0--xs {
    padding-right: 0rem;
  }

  .space-pb-0--xs {
    padding-bottom: 0rem;
  }

  .space-pl-0--xs {
    padding-left: 0rem;
  }

  .space-m-1--xs {
    margin: 0.5rem;
  }

  .space-mx-1--xs {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xs {
    margin-top: 0.5rem;
  }

  .space-mr-1--xs {
    margin-right: 0.5rem;
  }

  .space-mb-1--xs {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xs {
    margin-left: 0.5rem;
  }

  .space-p-1--xs {
    padding: 0.5rem;
  }

  .space-px-1--xs {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xs {
    padding-top: 0.5rem;
  }

  .space-pr-1--xs {
    padding-right: 0.5rem;
  }

  .space-pb-1--xs {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xs {
    padding-left: 0.5rem;
  }

  .space-m-2--xs {
    margin: 1rem;
  }

  .space-mx-2--xs {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xs {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xs {
    margin-top: 1rem;
  }

  .space-mr-2--xs {
    margin-right: 1rem;
  }

  .space-mb-2--xs {
    margin-bottom: 1rem;
  }

  .space-ml-2--xs {
    margin-left: 1rem;
  }

  .space-p-2--xs {
    padding: 1rem;
  }

  .space-px-2--xs {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xs {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xs {
    padding-top: 1rem;
  }

  .space-pr-2--xs {
    padding-right: 1rem;
  }

  .space-pb-2--xs {
    padding-bottom: 1rem;
  }

  .space-pl-2--xs {
    padding-left: 1rem;
  }

  .space-m-3--xs {
    margin: 1.5rem;
  }

  .space-mx-3--xs {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xs {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xs {
    margin-top: 1.5rem;
  }

  .space-mr-3--xs {
    margin-right: 1.5rem;
  }

  .space-mb-3--xs {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xs {
    margin-left: 1.5rem;
  }

  .space-p-3--xs {
    padding: 1.5rem;
  }

  .space-px-3--xs {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xs {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xs {
    padding-top: 1.5rem;
  }

  .space-pr-3--xs {
    padding-right: 1.5rem;
  }

  .space-pb-3--xs {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xs {
    padding-left: 1.5rem;
  }

  .space-m-4--xs {
    margin: 2rem;
  }

  .space-mx-4--xs {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xs {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xs {
    margin-top: 2rem;
  }

  .space-mr-4--xs {
    margin-right: 2rem;
  }

  .space-mb-4--xs {
    margin-bottom: 2rem;
  }

  .space-ml-4--xs {
    margin-left: 2rem;
  }

  .space-p-4--xs {
    padding: 2rem;
  }

  .space-px-4--xs {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xs {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xs {
    padding-top: 2rem;
  }

  .space-pr-4--xs {
    padding-right: 2rem;
  }

  .space-pb-4--xs {
    padding-bottom: 2rem;
  }

  .space-pl-4--xs {
    padding-left: 2rem;
  }

  .space-m-5--xs {
    margin: 2.5rem;
  }

  .space-mx-5--xs {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xs {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xs {
    margin-top: 2.5rem;
  }

  .space-mr-5--xs {
    margin-right: 2.5rem;
  }

  .space-mb-5--xs {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xs {
    margin-left: 2.5rem;
  }

  .space-p-5--xs {
    padding: 2.5rem;
  }

  .space-px-5--xs {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xs {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xs {
    padding-top: 2.5rem;
  }

  .space-pr-5--xs {
    padding-right: 2.5rem;
  }

  .space-pb-5--xs {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xs {
    padding-left: 2.5rem;
  }

  .space-m-6--xs {
    margin: 3rem;
  }

  .space-mx-6--xs {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xs {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xs {
    margin-top: 3rem;
  }

  .space-mr-6--xs {
    margin-right: 3rem;
  }

  .space-mb-6--xs {
    margin-bottom: 3rem;
  }

  .space-ml-6--xs {
    margin-left: 3rem;
  }

  .space-p-6--xs {
    padding: 3rem;
  }

  .space-px-6--xs {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xs {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xs {
    padding-top: 3rem;
  }

  .space-pr-6--xs {
    padding-right: 3rem;
  }

  .space-pb-6--xs {
    padding-bottom: 3rem;
  }

  .space-pl-6--xs {
    padding-left: 3rem;
  }

  .space-m-7--xs {
    margin: 3.5rem;
  }

  .space-mx-7--xs {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xs {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xs {
    margin-top: 3.5rem;
  }

  .space-mr-7--xs {
    margin-right: 3.5rem;
  }

  .space-mb-7--xs {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xs {
    margin-left: 3.5rem;
  }

  .space-p-7--xs {
    padding: 3.5rem;
  }

  .space-px-7--xs {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xs {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xs {
    padding-top: 3.5rem;
  }

  .space-pr-7--xs {
    padding-right: 3.5rem;
  }

  .space-pb-7--xs {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xs {
    padding-left: 3.5rem;
  }

  .space-m-8--xs {
    margin: 4rem;
  }

  .space-mx-8--xs {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xs {
    margin-top: 4rem;
  }

  .space-mr-8--xs {
    margin-right: 4rem;
  }

  .space-mb-8--xs {
    margin-bottom: 4rem;
  }

  .space-ml-8--xs {
    margin-left: 4rem;
  }

  .space-p-8--xs {
    padding: 4rem;
  }

  .space-px-8--xs {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xs {
    padding-top: 4rem;
  }

  .space-pr-8--xs {
    padding-right: 4rem;
  }

  .space-pb-8--xs {
    padding-bottom: 4rem;
  }

  .space-pl-8--xs {
    padding-left: 4rem;
  }

  .space-m-9--xs {
    margin: 4.5rem;
  }

  .space-mx-9--xs {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xs {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xs {
    margin-top: 4.5rem;
  }

  .space-mr-9--xs {
    margin-right: 4.5rem;
  }

  .space-mb-9--xs {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xs {
    margin-left: 4.5rem;
  }

  .space-p-9--xs {
    padding: 4.5rem;
  }

  .space-px-9--xs {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xs {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xs {
    padding-top: 4.5rem;
  }

  .space-pr-9--xs {
    padding-right: 4.5rem;
  }

  .space-pb-9--xs {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xs {
    padding-left: 4.5rem;
  }

  .space-m-12--xs {
    margin: 6rem;
  }

  .space-mx-12--xs {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xs {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xs {
    margin-top: 6rem;
  }

  .space-mr-12--xs {
    margin-right: 6rem;
  }

  .space-mb-12--xs {
    margin-bottom: 6rem;
  }

  .space-ml-12--xs {
    margin-left: 6rem;
  }

  .space-p-12--xs {
    padding: 6rem;
  }

  .space-px-12--xs {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xs {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xs {
    padding-top: 6rem;
  }

  .space-pr-12--xs {
    padding-right: 6rem;
  }

  .space-pb-12--xs {
    padding-bottom: 6rem;
  }

  .space-pl-12--xs {
    padding-left: 6rem;
  }

  .space-m-16--xs {
    margin: 8rem;
  }

  .space-mx-16--xs {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xs {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xs {
    margin-top: 8rem;
  }

  .space-mr-16--xs {
    margin-right: 8rem;
  }

  .space-mb-16--xs {
    margin-bottom: 8rem;
  }

  .space-ml-16--xs {
    margin-left: 8rem;
  }

  .space-p-16--xs {
    padding: 8rem;
  }

  .space-px-16--xs {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xs {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xs {
    padding-top: 8rem;
  }

  .space-pr-16--xs {
    padding-right: 8rem;
  }

  .space-pb-16--xs {
    padding-bottom: 8rem;
  }

  .space-pl-16--xs {
    padding-left: 8rem;
  }

  .space-m-22--xs {
    margin: 11rem;
  }

  .space-mx-22--xs {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xs {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xs {
    margin-top: 11rem;
  }

  .space-mr-22--xs {
    margin-right: 11rem;
  }

  .space-mb-22--xs {
    margin-bottom: 11rem;
  }

  .space-ml-22--xs {
    margin-left: 11rem;
  }

  .space-p-22--xs {
    padding: 11rem;
  }

  .space-px-22--xs {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xs {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xs {
    padding-top: 11rem;
  }

  .space-pr-22--xs {
    padding-right: 11rem;
  }

  .space-pb-22--xs {
    padding-bottom: 11rem;
  }

  .space-pl-22--xs {
    padding-left: 11rem;
  }

  .space-m-27--xs {
    margin: 13.5rem;
  }

  .space-mx-27--xs {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xs {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xs {
    margin-top: 13.5rem;
  }

  .space-mr-27--xs {
    margin-right: 13.5rem;
  }

  .space-mb-27--xs {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xs {
    margin-left: 13.5rem;
  }

  .space-p-27--xs {
    padding: 13.5rem;
  }

  .space-px-27--xs {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xs {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xs {
    padding-top: 13.5rem;
  }

  .space-pr-27--xs {
    padding-right: 13.5rem;
  }

  .space-pb-27--xs {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xs {
    padding-left: 13.5rem;
  }

  .space-m-30--xs {
    margin: 15rem;
  }

  .space-mx-30--xs {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xs {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xs {
    margin-top: 15rem;
  }

  .space-mr-30--xs {
    margin-right: 15rem;
  }

  .space-mb-30--xs {
    margin-bottom: 15rem;
  }

  .space-ml-30--xs {
    margin-left: 15rem;
  }

  .space-p-30--xs {
    padding: 15rem;
  }

  .space-px-30--xs {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xs {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xs {
    padding-top: 15rem;
  }

  .space-pr-30--xs {
    padding-right: 15rem;
  }

  .space-pb-30--xs {
    padding-bottom: 15rem;
  }

  .space-pl-30--xs {
    padding-left: 15rem;
  }

  .space-m-46--xs {
    margin: 23rem;
  }

  .space-mx-46--xs {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xs {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xs {
    margin-top: 23rem;
  }

  .space-mr-46--xs {
    margin-right: 23rem;
  }

  .space-mb-46--xs {
    margin-bottom: 23rem;
  }

  .space-ml-46--xs {
    margin-left: 23rem;
  }

  .space-p-46--xs {
    padding: 23rem;
  }

  .space-px-46--xs {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xs {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xs {
    padding-top: 23rem;
  }

  .space-pr-46--xs {
    padding-right: 23rem;
  }

  .space-pb-46--xs {
    padding-bottom: 23rem;
  }

  .space-pl-46--xs {
    padding-left: 23rem;
  }

  .space-m-60--xs {
    margin: 30rem;
  }

  .space-mx-60--xs {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xs {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xs {
    margin-top: 30rem;
  }

  .space-mr-60--xs {
    margin-right: 30rem;
  }

  .space-mb-60--xs {
    margin-bottom: 30rem;
  }

  .space-ml-60--xs {
    margin-left: 30rem;
  }

  .space-p-60--xs {
    padding: 30rem;
  }

  .space-px-60--xs {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xs {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xs {
    padding-top: 30rem;
  }

  .space-pr-60--xs {
    padding-right: 30rem;
  }

  .space-pb-60--xs {
    padding-bottom: 30rem;
  }

  .space-pl-60--xs {
    padding-left: 30rem;
  }
}
@media (min-width: 48em) {
  .space-m-0--s {
    margin: 0rem;
  }

  .space-mx-0--s {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--s {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--s {
    margin-top: 0rem;
  }

  .space-mr-0--s {
    margin-right: 0rem;
  }

  .space-mb-0--s {
    margin-bottom: 0rem;
  }

  .space-ml-0--s {
    margin-left: 0rem;
  }

  .space-p-0--s {
    padding: 0rem;
  }

  .space-px-0--s {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--s {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--s {
    padding-top: 0rem;
  }

  .space-pr-0--s {
    padding-right: 0rem;
  }

  .space-pb-0--s {
    padding-bottom: 0rem;
  }

  .space-pl-0--s {
    padding-left: 0rem;
  }

  .space-m-1--s {
    margin: 0.5rem;
  }

  .space-mx-1--s {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--s {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--s {
    margin-top: 0.5rem;
  }

  .space-mr-1--s {
    margin-right: 0.5rem;
  }

  .space-mb-1--s {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--s {
    margin-left: 0.5rem;
  }

  .space-p-1--s {
    padding: 0.5rem;
  }

  .space-px-1--s {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--s {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--s {
    padding-top: 0.5rem;
  }

  .space-pr-1--s {
    padding-right: 0.5rem;
  }

  .space-pb-1--s {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--s {
    padding-left: 0.5rem;
  }

  .space-m-2--s {
    margin: 1rem;
  }

  .space-mx-2--s {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--s {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--s {
    margin-top: 1rem;
  }

  .space-mr-2--s {
    margin-right: 1rem;
  }

  .space-mb-2--s {
    margin-bottom: 1rem;
  }

  .space-ml-2--s {
    margin-left: 1rem;
  }

  .space-p-2--s {
    padding: 1rem;
  }

  .space-px-2--s {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--s {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--s {
    padding-top: 1rem;
  }

  .space-pr-2--s {
    padding-right: 1rem;
  }

  .space-pb-2--s {
    padding-bottom: 1rem;
  }

  .space-pl-2--s {
    padding-left: 1rem;
  }

  .space-m-3--s {
    margin: 1.5rem;
  }

  .space-mx-3--s {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--s {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--s {
    margin-top: 1.5rem;
  }

  .space-mr-3--s {
    margin-right: 1.5rem;
  }

  .space-mb-3--s {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--s {
    margin-left: 1.5rem;
  }

  .space-p-3--s {
    padding: 1.5rem;
  }

  .space-px-3--s {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--s {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--s {
    padding-top: 1.5rem;
  }

  .space-pr-3--s {
    padding-right: 1.5rem;
  }

  .space-pb-3--s {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--s {
    padding-left: 1.5rem;
  }

  .space-m-4--s {
    margin: 2rem;
  }

  .space-mx-4--s {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--s {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--s {
    margin-top: 2rem;
  }

  .space-mr-4--s {
    margin-right: 2rem;
  }

  .space-mb-4--s {
    margin-bottom: 2rem;
  }

  .space-ml-4--s {
    margin-left: 2rem;
  }

  .space-p-4--s {
    padding: 2rem;
  }

  .space-px-4--s {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--s {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--s {
    padding-top: 2rem;
  }

  .space-pr-4--s {
    padding-right: 2rem;
  }

  .space-pb-4--s {
    padding-bottom: 2rem;
  }

  .space-pl-4--s {
    padding-left: 2rem;
  }

  .space-m-5--s {
    margin: 2.5rem;
  }

  .space-mx-5--s {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--s {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--s {
    margin-top: 2.5rem;
  }

  .space-mr-5--s {
    margin-right: 2.5rem;
  }

  .space-mb-5--s {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--s {
    margin-left: 2.5rem;
  }

  .space-p-5--s {
    padding: 2.5rem;
  }

  .space-px-5--s {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--s {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--s {
    padding-top: 2.5rem;
  }

  .space-pr-5--s {
    padding-right: 2.5rem;
  }

  .space-pb-5--s {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--s {
    padding-left: 2.5rem;
  }

  .space-m-6--s {
    margin: 3rem;
  }

  .space-mx-6--s {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--s {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--s {
    margin-top: 3rem;
  }

  .space-mr-6--s {
    margin-right: 3rem;
  }

  .space-mb-6--s {
    margin-bottom: 3rem;
  }

  .space-ml-6--s {
    margin-left: 3rem;
  }

  .space-p-6--s {
    padding: 3rem;
  }

  .space-px-6--s {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--s {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--s {
    padding-top: 3rem;
  }

  .space-pr-6--s {
    padding-right: 3rem;
  }

  .space-pb-6--s {
    padding-bottom: 3rem;
  }

  .space-pl-6--s {
    padding-left: 3rem;
  }

  .space-m-7--s {
    margin: 3.5rem;
  }

  .space-mx-7--s {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--s {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--s {
    margin-top: 3.5rem;
  }

  .space-mr-7--s {
    margin-right: 3.5rem;
  }

  .space-mb-7--s {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--s {
    margin-left: 3.5rem;
  }

  .space-p-7--s {
    padding: 3.5rem;
  }

  .space-px-7--s {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--s {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--s {
    padding-top: 3.5rem;
  }

  .space-pr-7--s {
    padding-right: 3.5rem;
  }

  .space-pb-7--s {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--s {
    padding-left: 3.5rem;
  }

  .space-m-8--s {
    margin: 4rem;
  }

  .space-mx-8--s {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--s {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--s {
    margin-top: 4rem;
  }

  .space-mr-8--s {
    margin-right: 4rem;
  }

  .space-mb-8--s {
    margin-bottom: 4rem;
  }

  .space-ml-8--s {
    margin-left: 4rem;
  }

  .space-p-8--s {
    padding: 4rem;
  }

  .space-px-8--s {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--s {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--s {
    padding-top: 4rem;
  }

  .space-pr-8--s {
    padding-right: 4rem;
  }

  .space-pb-8--s {
    padding-bottom: 4rem;
  }

  .space-pl-8--s {
    padding-left: 4rem;
  }

  .space-m-9--s {
    margin: 4.5rem;
  }

  .space-mx-9--s {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--s {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--s {
    margin-top: 4.5rem;
  }

  .space-mr-9--s {
    margin-right: 4.5rem;
  }

  .space-mb-9--s {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--s {
    margin-left: 4.5rem;
  }

  .space-p-9--s {
    padding: 4.5rem;
  }

  .space-px-9--s {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--s {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--s {
    padding-top: 4.5rem;
  }

  .space-pr-9--s {
    padding-right: 4.5rem;
  }

  .space-pb-9--s {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--s {
    padding-left: 4.5rem;
  }

  .space-m-12--s {
    margin: 6rem;
  }

  .space-mx-12--s {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--s {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--s {
    margin-top: 6rem;
  }

  .space-mr-12--s {
    margin-right: 6rem;
  }

  .space-mb-12--s {
    margin-bottom: 6rem;
  }

  .space-ml-12--s {
    margin-left: 6rem;
  }

  .space-p-12--s {
    padding: 6rem;
  }

  .space-px-12--s {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--s {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--s {
    padding-top: 6rem;
  }

  .space-pr-12--s {
    padding-right: 6rem;
  }

  .space-pb-12--s {
    padding-bottom: 6rem;
  }

  .space-pl-12--s {
    padding-left: 6rem;
  }

  .space-m-16--s {
    margin: 8rem;
  }

  .space-mx-16--s {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--s {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--s {
    margin-top: 8rem;
  }

  .space-mr-16--s {
    margin-right: 8rem;
  }

  .space-mb-16--s {
    margin-bottom: 8rem;
  }

  .space-ml-16--s {
    margin-left: 8rem;
  }

  .space-p-16--s {
    padding: 8rem;
  }

  .space-px-16--s {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--s {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--s {
    padding-top: 8rem;
  }

  .space-pr-16--s {
    padding-right: 8rem;
  }

  .space-pb-16--s {
    padding-bottom: 8rem;
  }

  .space-pl-16--s {
    padding-left: 8rem;
  }

  .space-m-22--s {
    margin: 11rem;
  }

  .space-mx-22--s {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--s {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--s {
    margin-top: 11rem;
  }

  .space-mr-22--s {
    margin-right: 11rem;
  }

  .space-mb-22--s {
    margin-bottom: 11rem;
  }

  .space-ml-22--s {
    margin-left: 11rem;
  }

  .space-p-22--s {
    padding: 11rem;
  }

  .space-px-22--s {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--s {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--s {
    padding-top: 11rem;
  }

  .space-pr-22--s {
    padding-right: 11rem;
  }

  .space-pb-22--s {
    padding-bottom: 11rem;
  }

  .space-pl-22--s {
    padding-left: 11rem;
  }

  .space-m-27--s {
    margin: 13.5rem;
  }

  .space-mx-27--s {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--s {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--s {
    margin-top: 13.5rem;
  }

  .space-mr-27--s {
    margin-right: 13.5rem;
  }

  .space-mb-27--s {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--s {
    margin-left: 13.5rem;
  }

  .space-p-27--s {
    padding: 13.5rem;
  }

  .space-px-27--s {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--s {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--s {
    padding-top: 13.5rem;
  }

  .space-pr-27--s {
    padding-right: 13.5rem;
  }

  .space-pb-27--s {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--s {
    padding-left: 13.5rem;
  }

  .space-m-30--s {
    margin: 15rem;
  }

  .space-mx-30--s {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--s {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--s {
    margin-top: 15rem;
  }

  .space-mr-30--s {
    margin-right: 15rem;
  }

  .space-mb-30--s {
    margin-bottom: 15rem;
  }

  .space-ml-30--s {
    margin-left: 15rem;
  }

  .space-p-30--s {
    padding: 15rem;
  }

  .space-px-30--s {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--s {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--s {
    padding-top: 15rem;
  }

  .space-pr-30--s {
    padding-right: 15rem;
  }

  .space-pb-30--s {
    padding-bottom: 15rem;
  }

  .space-pl-30--s {
    padding-left: 15rem;
  }

  .space-m-46--s {
    margin: 23rem;
  }

  .space-mx-46--s {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--s {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--s {
    margin-top: 23rem;
  }

  .space-mr-46--s {
    margin-right: 23rem;
  }

  .space-mb-46--s {
    margin-bottom: 23rem;
  }

  .space-ml-46--s {
    margin-left: 23rem;
  }

  .space-p-46--s {
    padding: 23rem;
  }

  .space-px-46--s {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--s {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--s {
    padding-top: 23rem;
  }

  .space-pr-46--s {
    padding-right: 23rem;
  }

  .space-pb-46--s {
    padding-bottom: 23rem;
  }

  .space-pl-46--s {
    padding-left: 23rem;
  }

  .space-m-60--s {
    margin: 30rem;
  }

  .space-mx-60--s {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--s {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--s {
    margin-top: 30rem;
  }

  .space-mr-60--s {
    margin-right: 30rem;
  }

  .space-mb-60--s {
    margin-bottom: 30rem;
  }

  .space-ml-60--s {
    margin-left: 30rem;
  }

  .space-p-60--s {
    padding: 30rem;
  }

  .space-px-60--s {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--s {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--s {
    padding-top: 30rem;
  }

  .space-pr-60--s {
    padding-right: 30rem;
  }

  .space-pb-60--s {
    padding-bottom: 30rem;
  }

  .space-pl-60--s {
    padding-left: 30rem;
  }
}
@media (min-width: 64em) {
  .space-m-0--m {
    margin: 0rem;
  }

  .space-mx-0--m {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--m {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--m {
    margin-top: 0rem;
  }

  .space-mr-0--m {
    margin-right: 0rem;
  }

  .space-mb-0--m {
    margin-bottom: 0rem;
  }

  .space-ml-0--m {
    margin-left: 0rem;
  }

  .space-p-0--m {
    padding: 0rem;
  }

  .space-px-0--m {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--m {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--m {
    padding-top: 0rem;
  }

  .space-pr-0--m {
    padding-right: 0rem;
  }

  .space-pb-0--m {
    padding-bottom: 0rem;
  }

  .space-pl-0--m {
    padding-left: 0rem;
  }

  .space-m-1--m {
    margin: 0.5rem;
  }

  .space-mx-1--m {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--m {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--m {
    margin-top: 0.5rem;
  }

  .space-mr-1--m {
    margin-right: 0.5rem;
  }

  .space-mb-1--m {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--m {
    margin-left: 0.5rem;
  }

  .space-p-1--m {
    padding: 0.5rem;
  }

  .space-px-1--m {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--m {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--m {
    padding-top: 0.5rem;
  }

  .space-pr-1--m {
    padding-right: 0.5rem;
  }

  .space-pb-1--m {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--m {
    padding-left: 0.5rem;
  }

  .space-m-2--m {
    margin: 1rem;
  }

  .space-mx-2--m {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--m {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--m {
    margin-top: 1rem;
  }

  .space-mr-2--m {
    margin-right: 1rem;
  }

  .space-mb-2--m {
    margin-bottom: 1rem;
  }

  .space-ml-2--m {
    margin-left: 1rem;
  }

  .space-p-2--m {
    padding: 1rem;
  }

  .space-px-2--m {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--m {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--m {
    padding-top: 1rem;
  }

  .space-pr-2--m {
    padding-right: 1rem;
  }

  .space-pb-2--m {
    padding-bottom: 1rem;
  }

  .space-pl-2--m {
    padding-left: 1rem;
  }

  .space-m-3--m {
    margin: 1.5rem;
  }

  .space-mx-3--m {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--m {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--m {
    margin-top: 1.5rem;
  }

  .space-mr-3--m {
    margin-right: 1.5rem;
  }

  .space-mb-3--m {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--m {
    margin-left: 1.5rem;
  }

  .space-p-3--m {
    padding: 1.5rem;
  }

  .space-px-3--m {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--m {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--m {
    padding-top: 1.5rem;
  }

  .space-pr-3--m {
    padding-right: 1.5rem;
  }

  .space-pb-3--m {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--m {
    padding-left: 1.5rem;
  }

  .space-m-4--m {
    margin: 2rem;
  }

  .space-mx-4--m {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--m {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--m {
    margin-top: 2rem;
  }

  .space-mr-4--m {
    margin-right: 2rem;
  }

  .space-mb-4--m {
    margin-bottom: 2rem;
  }

  .space-ml-4--m {
    margin-left: 2rem;
  }

  .space-p-4--m {
    padding: 2rem;
  }

  .space-px-4--m {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--m {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--m {
    padding-top: 2rem;
  }

  .space-pr-4--m {
    padding-right: 2rem;
  }

  .space-pb-4--m {
    padding-bottom: 2rem;
  }

  .space-pl-4--m {
    padding-left: 2rem;
  }

  .space-m-5--m {
    margin: 2.5rem;
  }

  .space-mx-5--m {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--m {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--m {
    margin-top: 2.5rem;
  }

  .space-mr-5--m {
    margin-right: 2.5rem;
  }

  .space-mb-5--m {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--m {
    margin-left: 2.5rem;
  }

  .space-p-5--m {
    padding: 2.5rem;
  }

  .space-px-5--m {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--m {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--m {
    padding-top: 2.5rem;
  }

  .space-pr-5--m {
    padding-right: 2.5rem;
  }

  .space-pb-5--m {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--m {
    padding-left: 2.5rem;
  }

  .space-m-6--m {
    margin: 3rem;
  }

  .space-mx-6--m {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--m {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--m {
    margin-top: 3rem;
  }

  .space-mr-6--m {
    margin-right: 3rem;
  }

  .space-mb-6--m {
    margin-bottom: 3rem;
  }

  .space-ml-6--m {
    margin-left: 3rem;
  }

  .space-p-6--m {
    padding: 3rem;
  }

  .space-px-6--m {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--m {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--m {
    padding-top: 3rem;
  }

  .space-pr-6--m {
    padding-right: 3rem;
  }

  .space-pb-6--m {
    padding-bottom: 3rem;
  }

  .space-pl-6--m {
    padding-left: 3rem;
  }

  .space-m-7--m {
    margin: 3.5rem;
  }

  .space-mx-7--m {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--m {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--m {
    margin-top: 3.5rem;
  }

  .space-mr-7--m {
    margin-right: 3.5rem;
  }

  .space-mb-7--m {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--m {
    margin-left: 3.5rem;
  }

  .space-p-7--m {
    padding: 3.5rem;
  }

  .space-px-7--m {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--m {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--m {
    padding-top: 3.5rem;
  }

  .space-pr-7--m {
    padding-right: 3.5rem;
  }

  .space-pb-7--m {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--m {
    padding-left: 3.5rem;
  }

  .space-m-8--m {
    margin: 4rem;
  }

  .space-mx-8--m {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--m {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--m {
    margin-top: 4rem;
  }

  .space-mr-8--m {
    margin-right: 4rem;
  }

  .space-mb-8--m {
    margin-bottom: 4rem;
  }

  .space-ml-8--m {
    margin-left: 4rem;
  }

  .space-p-8--m {
    padding: 4rem;
  }

  .space-px-8--m {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--m {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--m {
    padding-top: 4rem;
  }

  .space-pr-8--m {
    padding-right: 4rem;
  }

  .space-pb-8--m {
    padding-bottom: 4rem;
  }

  .space-pl-8--m {
    padding-left: 4rem;
  }

  .space-m-9--m {
    margin: 4.5rem;
  }

  .space-mx-9--m {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--m {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--m {
    margin-top: 4.5rem;
  }

  .space-mr-9--m {
    margin-right: 4.5rem;
  }

  .space-mb-9--m {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--m {
    margin-left: 4.5rem;
  }

  .space-p-9--m {
    padding: 4.5rem;
  }

  .space-px-9--m {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--m {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--m {
    padding-top: 4.5rem;
  }

  .space-pr-9--m {
    padding-right: 4.5rem;
  }

  .space-pb-9--m {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--m {
    padding-left: 4.5rem;
  }

  .space-m-12--m {
    margin: 6rem;
  }

  .space-mx-12--m {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--m {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--m {
    margin-top: 6rem;
  }

  .space-mr-12--m {
    margin-right: 6rem;
  }

  .space-mb-12--m {
    margin-bottom: 6rem;
  }

  .space-ml-12--m {
    margin-left: 6rem;
  }

  .space-p-12--m {
    padding: 6rem;
  }

  .space-px-12--m {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--m {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--m {
    padding-top: 6rem;
  }

  .space-pr-12--m {
    padding-right: 6rem;
  }

  .space-pb-12--m {
    padding-bottom: 6rem;
  }

  .space-pl-12--m {
    padding-left: 6rem;
  }

  .space-m-16--m {
    margin: 8rem;
  }

  .space-mx-16--m {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--m {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--m {
    margin-top: 8rem;
  }

  .space-mr-16--m {
    margin-right: 8rem;
  }

  .space-mb-16--m {
    margin-bottom: 8rem;
  }

  .space-ml-16--m {
    margin-left: 8rem;
  }

  .space-p-16--m {
    padding: 8rem;
  }

  .space-px-16--m {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--m {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--m {
    padding-top: 8rem;
  }

  .space-pr-16--m {
    padding-right: 8rem;
  }

  .space-pb-16--m {
    padding-bottom: 8rem;
  }

  .space-pl-16--m {
    padding-left: 8rem;
  }

  .space-m-22--m {
    margin: 11rem;
  }

  .space-mx-22--m {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--m {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--m {
    margin-top: 11rem;
  }

  .space-mr-22--m {
    margin-right: 11rem;
  }

  .space-mb-22--m {
    margin-bottom: 11rem;
  }

  .space-ml-22--m {
    margin-left: 11rem;
  }

  .space-p-22--m {
    padding: 11rem;
  }

  .space-px-22--m {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--m {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--m {
    padding-top: 11rem;
  }

  .space-pr-22--m {
    padding-right: 11rem;
  }

  .space-pb-22--m {
    padding-bottom: 11rem;
  }

  .space-pl-22--m {
    padding-left: 11rem;
  }

  .space-m-27--m {
    margin: 13.5rem;
  }

  .space-mx-27--m {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--m {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--m {
    margin-top: 13.5rem;
  }

  .space-mr-27--m {
    margin-right: 13.5rem;
  }

  .space-mb-27--m {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--m {
    margin-left: 13.5rem;
  }

  .space-p-27--m {
    padding: 13.5rem;
  }

  .space-px-27--m {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--m {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--m {
    padding-top: 13.5rem;
  }

  .space-pr-27--m {
    padding-right: 13.5rem;
  }

  .space-pb-27--m {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--m {
    padding-left: 13.5rem;
  }

  .space-m-30--m {
    margin: 15rem;
  }

  .space-mx-30--m {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--m {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--m {
    margin-top: 15rem;
  }

  .space-mr-30--m {
    margin-right: 15rem;
  }

  .space-mb-30--m {
    margin-bottom: 15rem;
  }

  .space-ml-30--m {
    margin-left: 15rem;
  }

  .space-p-30--m {
    padding: 15rem;
  }

  .space-px-30--m {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--m {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--m {
    padding-top: 15rem;
  }

  .space-pr-30--m {
    padding-right: 15rem;
  }

  .space-pb-30--m {
    padding-bottom: 15rem;
  }

  .space-pl-30--m {
    padding-left: 15rem;
  }

  .space-m-46--m {
    margin: 23rem;
  }

  .space-mx-46--m {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--m {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--m {
    margin-top: 23rem;
  }

  .space-mr-46--m {
    margin-right: 23rem;
  }

  .space-mb-46--m {
    margin-bottom: 23rem;
  }

  .space-ml-46--m {
    margin-left: 23rem;
  }

  .space-p-46--m {
    padding: 23rem;
  }

  .space-px-46--m {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--m {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--m {
    padding-top: 23rem;
  }

  .space-pr-46--m {
    padding-right: 23rem;
  }

  .space-pb-46--m {
    padding-bottom: 23rem;
  }

  .space-pl-46--m {
    padding-left: 23rem;
  }

  .space-m-60--m {
    margin: 30rem;
  }

  .space-mx-60--m {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--m {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--m {
    margin-top: 30rem;
  }

  .space-mr-60--m {
    margin-right: 30rem;
  }

  .space-mb-60--m {
    margin-bottom: 30rem;
  }

  .space-ml-60--m {
    margin-left: 30rem;
  }

  .space-p-60--m {
    padding: 30rem;
  }

  .space-px-60--m {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--m {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--m {
    padding-top: 30rem;
  }

  .space-pr-60--m {
    padding-right: 30rem;
  }

  .space-pb-60--m {
    padding-bottom: 30rem;
  }

  .space-pl-60--m {
    padding-left: 30rem;
  }
}
@media (min-width: 80em) {
  .space-m-0--l {
    margin: 0rem;
  }

  .space-mx-0--l {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--l {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--l {
    margin-top: 0rem;
  }

  .space-mr-0--l {
    margin-right: 0rem;
  }

  .space-mb-0--l {
    margin-bottom: 0rem;
  }

  .space-ml-0--l {
    margin-left: 0rem;
  }

  .space-p-0--l {
    padding: 0rem;
  }

  .space-px-0--l {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--l {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--l {
    padding-top: 0rem;
  }

  .space-pr-0--l {
    padding-right: 0rem;
  }

  .space-pb-0--l {
    padding-bottom: 0rem;
  }

  .space-pl-0--l {
    padding-left: 0rem;
  }

  .space-m-1--l {
    margin: 0.5rem;
  }

  .space-mx-1--l {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--l {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--l {
    margin-top: 0.5rem;
  }

  .space-mr-1--l {
    margin-right: 0.5rem;
  }

  .space-mb-1--l {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--l {
    margin-left: 0.5rem;
  }

  .space-p-1--l {
    padding: 0.5rem;
  }

  .space-px-1--l {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--l {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--l {
    padding-top: 0.5rem;
  }

  .space-pr-1--l {
    padding-right: 0.5rem;
  }

  .space-pb-1--l {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--l {
    padding-left: 0.5rem;
  }

  .space-m-2--l {
    margin: 1rem;
  }

  .space-mx-2--l {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--l {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--l {
    margin-top: 1rem;
  }

  .space-mr-2--l {
    margin-right: 1rem;
  }

  .space-mb-2--l {
    margin-bottom: 1rem;
  }

  .space-ml-2--l {
    margin-left: 1rem;
  }

  .space-p-2--l {
    padding: 1rem;
  }

  .space-px-2--l {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--l {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--l {
    padding-top: 1rem;
  }

  .space-pr-2--l {
    padding-right: 1rem;
  }

  .space-pb-2--l {
    padding-bottom: 1rem;
  }

  .space-pl-2--l {
    padding-left: 1rem;
  }

  .space-m-3--l {
    margin: 1.5rem;
  }

  .space-mx-3--l {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--l {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--l {
    margin-top: 1.5rem;
  }

  .space-mr-3--l {
    margin-right: 1.5rem;
  }

  .space-mb-3--l {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--l {
    margin-left: 1.5rem;
  }

  .space-p-3--l {
    padding: 1.5rem;
  }

  .space-px-3--l {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--l {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--l {
    padding-top: 1.5rem;
  }

  .space-pr-3--l {
    padding-right: 1.5rem;
  }

  .space-pb-3--l {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--l {
    padding-left: 1.5rem;
  }

  .space-m-4--l {
    margin: 2rem;
  }

  .space-mx-4--l {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--l {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--l {
    margin-top: 2rem;
  }

  .space-mr-4--l {
    margin-right: 2rem;
  }

  .space-mb-4--l {
    margin-bottom: 2rem;
  }

  .space-ml-4--l {
    margin-left: 2rem;
  }

  .space-p-4--l {
    padding: 2rem;
  }

  .space-px-4--l {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--l {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--l {
    padding-top: 2rem;
  }

  .space-pr-4--l {
    padding-right: 2rem;
  }

  .space-pb-4--l {
    padding-bottom: 2rem;
  }

  .space-pl-4--l {
    padding-left: 2rem;
  }

  .space-m-5--l {
    margin: 2.5rem;
  }

  .space-mx-5--l {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--l {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--l {
    margin-top: 2.5rem;
  }

  .space-mr-5--l {
    margin-right: 2.5rem;
  }

  .space-mb-5--l {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--l {
    margin-left: 2.5rem;
  }

  .space-p-5--l {
    padding: 2.5rem;
  }

  .space-px-5--l {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--l {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--l {
    padding-top: 2.5rem;
  }

  .space-pr-5--l {
    padding-right: 2.5rem;
  }

  .space-pb-5--l {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--l {
    padding-left: 2.5rem;
  }

  .space-m-6--l {
    margin: 3rem;
  }

  .space-mx-6--l {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--l {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--l {
    margin-top: 3rem;
  }

  .space-mr-6--l {
    margin-right: 3rem;
  }

  .space-mb-6--l {
    margin-bottom: 3rem;
  }

  .space-ml-6--l {
    margin-left: 3rem;
  }

  .space-p-6--l {
    padding: 3rem;
  }

  .space-px-6--l {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--l {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--l {
    padding-top: 3rem;
  }

  .space-pr-6--l {
    padding-right: 3rem;
  }

  .space-pb-6--l {
    padding-bottom: 3rem;
  }

  .space-pl-6--l {
    padding-left: 3rem;
  }

  .space-m-7--l {
    margin: 3.5rem;
  }

  .space-mx-7--l {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--l {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--l {
    margin-top: 3.5rem;
  }

  .space-mr-7--l {
    margin-right: 3.5rem;
  }

  .space-mb-7--l {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--l {
    margin-left: 3.5rem;
  }

  .space-p-7--l {
    padding: 3.5rem;
  }

  .space-px-7--l {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--l {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--l {
    padding-top: 3.5rem;
  }

  .space-pr-7--l {
    padding-right: 3.5rem;
  }

  .space-pb-7--l {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--l {
    padding-left: 3.5rem;
  }

  .space-m-8--l {
    margin: 4rem;
  }

  .space-mx-8--l {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--l {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--l {
    margin-top: 4rem;
  }

  .space-mr-8--l {
    margin-right: 4rem;
  }

  .space-mb-8--l {
    margin-bottom: 4rem;
  }

  .space-ml-8--l {
    margin-left: 4rem;
  }

  .space-p-8--l {
    padding: 4rem;
  }

  .space-px-8--l {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--l {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--l {
    padding-top: 4rem;
  }

  .space-pr-8--l {
    padding-right: 4rem;
  }

  .space-pb-8--l {
    padding-bottom: 4rem;
  }

  .space-pl-8--l {
    padding-left: 4rem;
  }

  .space-m-9--l {
    margin: 4.5rem;
  }

  .space-mx-9--l {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--l {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--l {
    margin-top: 4.5rem;
  }

  .space-mr-9--l {
    margin-right: 4.5rem;
  }

  .space-mb-9--l {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--l {
    margin-left: 4.5rem;
  }

  .space-p-9--l {
    padding: 4.5rem;
  }

  .space-px-9--l {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--l {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--l {
    padding-top: 4.5rem;
  }

  .space-pr-9--l {
    padding-right: 4.5rem;
  }

  .space-pb-9--l {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--l {
    padding-left: 4.5rem;
  }

  .space-m-12--l {
    margin: 6rem;
  }

  .space-mx-12--l {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--l {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--l {
    margin-top: 6rem;
  }

  .space-mr-12--l {
    margin-right: 6rem;
  }

  .space-mb-12--l {
    margin-bottom: 6rem;
  }

  .space-ml-12--l {
    margin-left: 6rem;
  }

  .space-p-12--l {
    padding: 6rem;
  }

  .space-px-12--l {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--l {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--l {
    padding-top: 6rem;
  }

  .space-pr-12--l {
    padding-right: 6rem;
  }

  .space-pb-12--l {
    padding-bottom: 6rem;
  }

  .space-pl-12--l {
    padding-left: 6rem;
  }

  .space-m-16--l {
    margin: 8rem;
  }

  .space-mx-16--l {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--l {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--l {
    margin-top: 8rem;
  }

  .space-mr-16--l {
    margin-right: 8rem;
  }

  .space-mb-16--l {
    margin-bottom: 8rem;
  }

  .space-ml-16--l {
    margin-left: 8rem;
  }

  .space-p-16--l {
    padding: 8rem;
  }

  .space-px-16--l {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--l {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--l {
    padding-top: 8rem;
  }

  .space-pr-16--l {
    padding-right: 8rem;
  }

  .space-pb-16--l {
    padding-bottom: 8rem;
  }

  .space-pl-16--l {
    padding-left: 8rem;
  }

  .space-m-22--l {
    margin: 11rem;
  }

  .space-mx-22--l {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--l {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--l {
    margin-top: 11rem;
  }

  .space-mr-22--l {
    margin-right: 11rem;
  }

  .space-mb-22--l {
    margin-bottom: 11rem;
  }

  .space-ml-22--l {
    margin-left: 11rem;
  }

  .space-p-22--l {
    padding: 11rem;
  }

  .space-px-22--l {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--l {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--l {
    padding-top: 11rem;
  }

  .space-pr-22--l {
    padding-right: 11rem;
  }

  .space-pb-22--l {
    padding-bottom: 11rem;
  }

  .space-pl-22--l {
    padding-left: 11rem;
  }

  .space-m-27--l {
    margin: 13.5rem;
  }

  .space-mx-27--l {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--l {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--l {
    margin-top: 13.5rem;
  }

  .space-mr-27--l {
    margin-right: 13.5rem;
  }

  .space-mb-27--l {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--l {
    margin-left: 13.5rem;
  }

  .space-p-27--l {
    padding: 13.5rem;
  }

  .space-px-27--l {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--l {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--l {
    padding-top: 13.5rem;
  }

  .space-pr-27--l {
    padding-right: 13.5rem;
  }

  .space-pb-27--l {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--l {
    padding-left: 13.5rem;
  }

  .space-m-30--l {
    margin: 15rem;
  }

  .space-mx-30--l {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--l {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--l {
    margin-top: 15rem;
  }

  .space-mr-30--l {
    margin-right: 15rem;
  }

  .space-mb-30--l {
    margin-bottom: 15rem;
  }

  .space-ml-30--l {
    margin-left: 15rem;
  }

  .space-p-30--l {
    padding: 15rem;
  }

  .space-px-30--l {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--l {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--l {
    padding-top: 15rem;
  }

  .space-pr-30--l {
    padding-right: 15rem;
  }

  .space-pb-30--l {
    padding-bottom: 15rem;
  }

  .space-pl-30--l {
    padding-left: 15rem;
  }

  .space-m-46--l {
    margin: 23rem;
  }

  .space-mx-46--l {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--l {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--l {
    margin-top: 23rem;
  }

  .space-mr-46--l {
    margin-right: 23rem;
  }

  .space-mb-46--l {
    margin-bottom: 23rem;
  }

  .space-ml-46--l {
    margin-left: 23rem;
  }

  .space-p-46--l {
    padding: 23rem;
  }

  .space-px-46--l {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--l {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--l {
    padding-top: 23rem;
  }

  .space-pr-46--l {
    padding-right: 23rem;
  }

  .space-pb-46--l {
    padding-bottom: 23rem;
  }

  .space-pl-46--l {
    padding-left: 23rem;
  }

  .space-m-60--l {
    margin: 30rem;
  }

  .space-mx-60--l {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--l {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--l {
    margin-top: 30rem;
  }

  .space-mr-60--l {
    margin-right: 30rem;
  }

  .space-mb-60--l {
    margin-bottom: 30rem;
  }

  .space-ml-60--l {
    margin-left: 30rem;
  }

  .space-p-60--l {
    padding: 30rem;
  }

  .space-px-60--l {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--l {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--l {
    padding-top: 30rem;
  }

  .space-pr-60--l {
    padding-right: 30rem;
  }

  .space-pb-60--l {
    padding-bottom: 30rem;
  }

  .space-pl-60--l {
    padding-left: 30rem;
  }
}
@media (min-width: 90em) {
  .space-m-0--xl {
    margin: 0rem;
  }

  .space-mx-0--xl {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xl {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xl {
    margin-top: 0rem;
  }

  .space-mr-0--xl {
    margin-right: 0rem;
  }

  .space-mb-0--xl {
    margin-bottom: 0rem;
  }

  .space-ml-0--xl {
    margin-left: 0rem;
  }

  .space-p-0--xl {
    padding: 0rem;
  }

  .space-px-0--xl {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xl {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xl {
    padding-top: 0rem;
  }

  .space-pr-0--xl {
    padding-right: 0rem;
  }

  .space-pb-0--xl {
    padding-bottom: 0rem;
  }

  .space-pl-0--xl {
    padding-left: 0rem;
  }

  .space-m-1--xl {
    margin: 0.5rem;
  }

  .space-mx-1--xl {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xl {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xl {
    margin-top: 0.5rem;
  }

  .space-mr-1--xl {
    margin-right: 0.5rem;
  }

  .space-mb-1--xl {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xl {
    margin-left: 0.5rem;
  }

  .space-p-1--xl {
    padding: 0.5rem;
  }

  .space-px-1--xl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xl {
    padding-top: 0.5rem;
  }

  .space-pr-1--xl {
    padding-right: 0.5rem;
  }

  .space-pb-1--xl {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xl {
    padding-left: 0.5rem;
  }

  .space-m-2--xl {
    margin: 1rem;
  }

  .space-mx-2--xl {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xl {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xl {
    margin-top: 1rem;
  }

  .space-mr-2--xl {
    margin-right: 1rem;
  }

  .space-mb-2--xl {
    margin-bottom: 1rem;
  }

  .space-ml-2--xl {
    margin-left: 1rem;
  }

  .space-p-2--xl {
    padding: 1rem;
  }

  .space-px-2--xl {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xl {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xl {
    padding-top: 1rem;
  }

  .space-pr-2--xl {
    padding-right: 1rem;
  }

  .space-pb-2--xl {
    padding-bottom: 1rem;
  }

  .space-pl-2--xl {
    padding-left: 1rem;
  }

  .space-m-3--xl {
    margin: 1.5rem;
  }

  .space-mx-3--xl {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xl {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xl {
    margin-top: 1.5rem;
  }

  .space-mr-3--xl {
    margin-right: 1.5rem;
  }

  .space-mb-3--xl {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xl {
    margin-left: 1.5rem;
  }

  .space-p-3--xl {
    padding: 1.5rem;
  }

  .space-px-3--xl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xl {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xl {
    padding-top: 1.5rem;
  }

  .space-pr-3--xl {
    padding-right: 1.5rem;
  }

  .space-pb-3--xl {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xl {
    padding-left: 1.5rem;
  }

  .space-m-4--xl {
    margin: 2rem;
  }

  .space-mx-4--xl {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xl {
    margin-top: 2rem;
  }

  .space-mr-4--xl {
    margin-right: 2rem;
  }

  .space-mb-4--xl {
    margin-bottom: 2rem;
  }

  .space-ml-4--xl {
    margin-left: 2rem;
  }

  .space-p-4--xl {
    padding: 2rem;
  }

  .space-px-4--xl {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xl {
    padding-top: 2rem;
  }

  .space-pr-4--xl {
    padding-right: 2rem;
  }

  .space-pb-4--xl {
    padding-bottom: 2rem;
  }

  .space-pl-4--xl {
    padding-left: 2rem;
  }

  .space-m-5--xl {
    margin: 2.5rem;
  }

  .space-mx-5--xl {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xl {
    margin-top: 2.5rem;
  }

  .space-mr-5--xl {
    margin-right: 2.5rem;
  }

  .space-mb-5--xl {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xl {
    margin-left: 2.5rem;
  }

  .space-p-5--xl {
    padding: 2.5rem;
  }

  .space-px-5--xl {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xl {
    padding-top: 2.5rem;
  }

  .space-pr-5--xl {
    padding-right: 2.5rem;
  }

  .space-pb-5--xl {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xl {
    padding-left: 2.5rem;
  }

  .space-m-6--xl {
    margin: 3rem;
  }

  .space-mx-6--xl {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xl {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xl {
    margin-top: 3rem;
  }

  .space-mr-6--xl {
    margin-right: 3rem;
  }

  .space-mb-6--xl {
    margin-bottom: 3rem;
  }

  .space-ml-6--xl {
    margin-left: 3rem;
  }

  .space-p-6--xl {
    padding: 3rem;
  }

  .space-px-6--xl {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xl {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xl {
    padding-top: 3rem;
  }

  .space-pr-6--xl {
    padding-right: 3rem;
  }

  .space-pb-6--xl {
    padding-bottom: 3rem;
  }

  .space-pl-6--xl {
    padding-left: 3rem;
  }

  .space-m-7--xl {
    margin: 3.5rem;
  }

  .space-mx-7--xl {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xl {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xl {
    margin-top: 3.5rem;
  }

  .space-mr-7--xl {
    margin-right: 3.5rem;
  }

  .space-mb-7--xl {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xl {
    margin-left: 3.5rem;
  }

  .space-p-7--xl {
    padding: 3.5rem;
  }

  .space-px-7--xl {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xl {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xl {
    padding-top: 3.5rem;
  }

  .space-pr-7--xl {
    padding-right: 3.5rem;
  }

  .space-pb-7--xl {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xl {
    padding-left: 3.5rem;
  }

  .space-m-8--xl {
    margin: 4rem;
  }

  .space-mx-8--xl {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xl {
    margin-top: 4rem;
  }

  .space-mr-8--xl {
    margin-right: 4rem;
  }

  .space-mb-8--xl {
    margin-bottom: 4rem;
  }

  .space-ml-8--xl {
    margin-left: 4rem;
  }

  .space-p-8--xl {
    padding: 4rem;
  }

  .space-px-8--xl {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xl {
    padding-top: 4rem;
  }

  .space-pr-8--xl {
    padding-right: 4rem;
  }

  .space-pb-8--xl {
    padding-bottom: 4rem;
  }

  .space-pl-8--xl {
    padding-left: 4rem;
  }

  .space-m-9--xl {
    margin: 4.5rem;
  }

  .space-mx-9--xl {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xl {
    margin-top: 4.5rem;
  }

  .space-mr-9--xl {
    margin-right: 4.5rem;
  }

  .space-mb-9--xl {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xl {
    margin-left: 4.5rem;
  }

  .space-p-9--xl {
    padding: 4.5rem;
  }

  .space-px-9--xl {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xl {
    padding-top: 4.5rem;
  }

  .space-pr-9--xl {
    padding-right: 4.5rem;
  }

  .space-pb-9--xl {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xl {
    padding-left: 4.5rem;
  }

  .space-m-12--xl {
    margin: 6rem;
  }

  .space-mx-12--xl {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xl {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xl {
    margin-top: 6rem;
  }

  .space-mr-12--xl {
    margin-right: 6rem;
  }

  .space-mb-12--xl {
    margin-bottom: 6rem;
  }

  .space-ml-12--xl {
    margin-left: 6rem;
  }

  .space-p-12--xl {
    padding: 6rem;
  }

  .space-px-12--xl {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xl {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xl {
    padding-top: 6rem;
  }

  .space-pr-12--xl {
    padding-right: 6rem;
  }

  .space-pb-12--xl {
    padding-bottom: 6rem;
  }

  .space-pl-12--xl {
    padding-left: 6rem;
  }

  .space-m-16--xl {
    margin: 8rem;
  }

  .space-mx-16--xl {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xl {
    margin-top: 8rem;
  }

  .space-mr-16--xl {
    margin-right: 8rem;
  }

  .space-mb-16--xl {
    margin-bottom: 8rem;
  }

  .space-ml-16--xl {
    margin-left: 8rem;
  }

  .space-p-16--xl {
    padding: 8rem;
  }

  .space-px-16--xl {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xl {
    padding-top: 8rem;
  }

  .space-pr-16--xl {
    padding-right: 8rem;
  }

  .space-pb-16--xl {
    padding-bottom: 8rem;
  }

  .space-pl-16--xl {
    padding-left: 8rem;
  }

  .space-m-22--xl {
    margin: 11rem;
  }

  .space-mx-22--xl {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xl {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xl {
    margin-top: 11rem;
  }

  .space-mr-22--xl {
    margin-right: 11rem;
  }

  .space-mb-22--xl {
    margin-bottom: 11rem;
  }

  .space-ml-22--xl {
    margin-left: 11rem;
  }

  .space-p-22--xl {
    padding: 11rem;
  }

  .space-px-22--xl {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xl {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xl {
    padding-top: 11rem;
  }

  .space-pr-22--xl {
    padding-right: 11rem;
  }

  .space-pb-22--xl {
    padding-bottom: 11rem;
  }

  .space-pl-22--xl {
    padding-left: 11rem;
  }

  .space-m-27--xl {
    margin: 13.5rem;
  }

  .space-mx-27--xl {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xl {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xl {
    margin-top: 13.5rem;
  }

  .space-mr-27--xl {
    margin-right: 13.5rem;
  }

  .space-mb-27--xl {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xl {
    margin-left: 13.5rem;
  }

  .space-p-27--xl {
    padding: 13.5rem;
  }

  .space-px-27--xl {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xl {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xl {
    padding-top: 13.5rem;
  }

  .space-pr-27--xl {
    padding-right: 13.5rem;
  }

  .space-pb-27--xl {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xl {
    padding-left: 13.5rem;
  }

  .space-m-30--xl {
    margin: 15rem;
  }

  .space-mx-30--xl {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xl {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xl {
    margin-top: 15rem;
  }

  .space-mr-30--xl {
    margin-right: 15rem;
  }

  .space-mb-30--xl {
    margin-bottom: 15rem;
  }

  .space-ml-30--xl {
    margin-left: 15rem;
  }

  .space-p-30--xl {
    padding: 15rem;
  }

  .space-px-30--xl {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xl {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xl {
    padding-top: 15rem;
  }

  .space-pr-30--xl {
    padding-right: 15rem;
  }

  .space-pb-30--xl {
    padding-bottom: 15rem;
  }

  .space-pl-30--xl {
    padding-left: 15rem;
  }

  .space-m-46--xl {
    margin: 23rem;
  }

  .space-mx-46--xl {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xl {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xl {
    margin-top: 23rem;
  }

  .space-mr-46--xl {
    margin-right: 23rem;
  }

  .space-mb-46--xl {
    margin-bottom: 23rem;
  }

  .space-ml-46--xl {
    margin-left: 23rem;
  }

  .space-p-46--xl {
    padding: 23rem;
  }

  .space-px-46--xl {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xl {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xl {
    padding-top: 23rem;
  }

  .space-pr-46--xl {
    padding-right: 23rem;
  }

  .space-pb-46--xl {
    padding-bottom: 23rem;
  }

  .space-pl-46--xl {
    padding-left: 23rem;
  }

  .space-m-60--xl {
    margin: 30rem;
  }

  .space-mx-60--xl {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xl {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xl {
    margin-top: 30rem;
  }

  .space-mr-60--xl {
    margin-right: 30rem;
  }

  .space-mb-60--xl {
    margin-bottom: 30rem;
  }

  .space-ml-60--xl {
    margin-left: 30rem;
  }

  .space-p-60--xl {
    padding: 30rem;
  }

  .space-px-60--xl {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xl {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xl {
    padding-top: 30rem;
  }

  .space-pr-60--xl {
    padding-right: 30rem;
  }

  .space-pb-60--xl {
    padding-bottom: 30rem;
  }

  .space-pl-60--xl {
    padding-left: 30rem;
  }
}
@media (min-width: 120em) {
  .space-m-0--xxl {
    margin: 0rem;
  }

  .space-mx-0--xxl {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xxl {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xxl {
    margin-top: 0rem;
  }

  .space-mr-0--xxl {
    margin-right: 0rem;
  }

  .space-mb-0--xxl {
    margin-bottom: 0rem;
  }

  .space-ml-0--xxl {
    margin-left: 0rem;
  }

  .space-p-0--xxl {
    padding: 0rem;
  }

  .space-px-0--xxl {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xxl {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xxl {
    padding-top: 0rem;
  }

  .space-pr-0--xxl {
    padding-right: 0rem;
  }

  .space-pb-0--xxl {
    padding-bottom: 0rem;
  }

  .space-pl-0--xxl {
    padding-left: 0rem;
  }

  .space-m-1--xxl {
    margin: 0.5rem;
  }

  .space-mx-1--xxl {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xxl {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xxl {
    margin-top: 0.5rem;
  }

  .space-mr-1--xxl {
    margin-right: 0.5rem;
  }

  .space-mb-1--xxl {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xxl {
    margin-left: 0.5rem;
  }

  .space-p-1--xxl {
    padding: 0.5rem;
  }

  .space-px-1--xxl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xxl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xxl {
    padding-top: 0.5rem;
  }

  .space-pr-1--xxl {
    padding-right: 0.5rem;
  }

  .space-pb-1--xxl {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xxl {
    padding-left: 0.5rem;
  }

  .space-m-2--xxl {
    margin: 1rem;
  }

  .space-mx-2--xxl {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xxl {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xxl {
    margin-top: 1rem;
  }

  .space-mr-2--xxl {
    margin-right: 1rem;
  }

  .space-mb-2--xxl {
    margin-bottom: 1rem;
  }

  .space-ml-2--xxl {
    margin-left: 1rem;
  }

  .space-p-2--xxl {
    padding: 1rem;
  }

  .space-px-2--xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xxl {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xxl {
    padding-top: 1rem;
  }

  .space-pr-2--xxl {
    padding-right: 1rem;
  }

  .space-pb-2--xxl {
    padding-bottom: 1rem;
  }

  .space-pl-2--xxl {
    padding-left: 1rem;
  }

  .space-m-3--xxl {
    margin: 1.5rem;
  }

  .space-mx-3--xxl {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xxl {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xxl {
    margin-top: 1.5rem;
  }

  .space-mr-3--xxl {
    margin-right: 1.5rem;
  }

  .space-mb-3--xxl {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xxl {
    margin-left: 1.5rem;
  }

  .space-p-3--xxl {
    padding: 1.5rem;
  }

  .space-px-3--xxl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xxl {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xxl {
    padding-top: 1.5rem;
  }

  .space-pr-3--xxl {
    padding-right: 1.5rem;
  }

  .space-pb-3--xxl {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xxl {
    padding-left: 1.5rem;
  }

  .space-m-4--xxl {
    margin: 2rem;
  }

  .space-mx-4--xxl {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xxl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xxl {
    margin-top: 2rem;
  }

  .space-mr-4--xxl {
    margin-right: 2rem;
  }

  .space-mb-4--xxl {
    margin-bottom: 2rem;
  }

  .space-ml-4--xxl {
    margin-left: 2rem;
  }

  .space-p-4--xxl {
    padding: 2rem;
  }

  .space-px-4--xxl {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xxl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xxl {
    padding-top: 2rem;
  }

  .space-pr-4--xxl {
    padding-right: 2rem;
  }

  .space-pb-4--xxl {
    padding-bottom: 2rem;
  }

  .space-pl-4--xxl {
    padding-left: 2rem;
  }

  .space-m-5--xxl {
    margin: 2.5rem;
  }

  .space-mx-5--xxl {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xxl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xxl {
    margin-top: 2.5rem;
  }

  .space-mr-5--xxl {
    margin-right: 2.5rem;
  }

  .space-mb-5--xxl {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xxl {
    margin-left: 2.5rem;
  }

  .space-p-5--xxl {
    padding: 2.5rem;
  }

  .space-px-5--xxl {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xxl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xxl {
    padding-top: 2.5rem;
  }

  .space-pr-5--xxl {
    padding-right: 2.5rem;
  }

  .space-pb-5--xxl {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xxl {
    padding-left: 2.5rem;
  }

  .space-m-6--xxl {
    margin: 3rem;
  }

  .space-mx-6--xxl {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xxl {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xxl {
    margin-top: 3rem;
  }

  .space-mr-6--xxl {
    margin-right: 3rem;
  }

  .space-mb-6--xxl {
    margin-bottom: 3rem;
  }

  .space-ml-6--xxl {
    margin-left: 3rem;
  }

  .space-p-6--xxl {
    padding: 3rem;
  }

  .space-px-6--xxl {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xxl {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xxl {
    padding-top: 3rem;
  }

  .space-pr-6--xxl {
    padding-right: 3rem;
  }

  .space-pb-6--xxl {
    padding-bottom: 3rem;
  }

  .space-pl-6--xxl {
    padding-left: 3rem;
  }

  .space-m-7--xxl {
    margin: 3.5rem;
  }

  .space-mx-7--xxl {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xxl {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xxl {
    margin-top: 3.5rem;
  }

  .space-mr-7--xxl {
    margin-right: 3.5rem;
  }

  .space-mb-7--xxl {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xxl {
    margin-left: 3.5rem;
  }

  .space-p-7--xxl {
    padding: 3.5rem;
  }

  .space-px-7--xxl {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xxl {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xxl {
    padding-top: 3.5rem;
  }

  .space-pr-7--xxl {
    padding-right: 3.5rem;
  }

  .space-pb-7--xxl {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xxl {
    padding-left: 3.5rem;
  }

  .space-m-8--xxl {
    margin: 4rem;
  }

  .space-mx-8--xxl {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xxl {
    margin-top: 4rem;
  }

  .space-mr-8--xxl {
    margin-right: 4rem;
  }

  .space-mb-8--xxl {
    margin-bottom: 4rem;
  }

  .space-ml-8--xxl {
    margin-left: 4rem;
  }

  .space-p-8--xxl {
    padding: 4rem;
  }

  .space-px-8--xxl {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xxl {
    padding-top: 4rem;
  }

  .space-pr-8--xxl {
    padding-right: 4rem;
  }

  .space-pb-8--xxl {
    padding-bottom: 4rem;
  }

  .space-pl-8--xxl {
    padding-left: 4rem;
  }

  .space-m-9--xxl {
    margin: 4.5rem;
  }

  .space-mx-9--xxl {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xxl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xxl {
    margin-top: 4.5rem;
  }

  .space-mr-9--xxl {
    margin-right: 4.5rem;
  }

  .space-mb-9--xxl {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xxl {
    margin-left: 4.5rem;
  }

  .space-p-9--xxl {
    padding: 4.5rem;
  }

  .space-px-9--xxl {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xxl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xxl {
    padding-top: 4.5rem;
  }

  .space-pr-9--xxl {
    padding-right: 4.5rem;
  }

  .space-pb-9--xxl {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xxl {
    padding-left: 4.5rem;
  }

  .space-m-12--xxl {
    margin: 6rem;
  }

  .space-mx-12--xxl {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xxl {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xxl {
    margin-top: 6rem;
  }

  .space-mr-12--xxl {
    margin-right: 6rem;
  }

  .space-mb-12--xxl {
    margin-bottom: 6rem;
  }

  .space-ml-12--xxl {
    margin-left: 6rem;
  }

  .space-p-12--xxl {
    padding: 6rem;
  }

  .space-px-12--xxl {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xxl {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xxl {
    padding-top: 6rem;
  }

  .space-pr-12--xxl {
    padding-right: 6rem;
  }

  .space-pb-12--xxl {
    padding-bottom: 6rem;
  }

  .space-pl-12--xxl {
    padding-left: 6rem;
  }

  .space-m-16--xxl {
    margin: 8rem;
  }

  .space-mx-16--xxl {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xxl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xxl {
    margin-top: 8rem;
  }

  .space-mr-16--xxl {
    margin-right: 8rem;
  }

  .space-mb-16--xxl {
    margin-bottom: 8rem;
  }

  .space-ml-16--xxl {
    margin-left: 8rem;
  }

  .space-p-16--xxl {
    padding: 8rem;
  }

  .space-px-16--xxl {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xxl {
    padding-top: 8rem;
  }

  .space-pr-16--xxl {
    padding-right: 8rem;
  }

  .space-pb-16--xxl {
    padding-bottom: 8rem;
  }

  .space-pl-16--xxl {
    padding-left: 8rem;
  }

  .space-m-22--xxl {
    margin: 11rem;
  }

  .space-mx-22--xxl {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xxl {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xxl {
    margin-top: 11rem;
  }

  .space-mr-22--xxl {
    margin-right: 11rem;
  }

  .space-mb-22--xxl {
    margin-bottom: 11rem;
  }

  .space-ml-22--xxl {
    margin-left: 11rem;
  }

  .space-p-22--xxl {
    padding: 11rem;
  }

  .space-px-22--xxl {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xxl {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xxl {
    padding-top: 11rem;
  }

  .space-pr-22--xxl {
    padding-right: 11rem;
  }

  .space-pb-22--xxl {
    padding-bottom: 11rem;
  }

  .space-pl-22--xxl {
    padding-left: 11rem;
  }

  .space-m-27--xxl {
    margin: 13.5rem;
  }

  .space-mx-27--xxl {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xxl {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xxl {
    margin-top: 13.5rem;
  }

  .space-mr-27--xxl {
    margin-right: 13.5rem;
  }

  .space-mb-27--xxl {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xxl {
    margin-left: 13.5rem;
  }

  .space-p-27--xxl {
    padding: 13.5rem;
  }

  .space-px-27--xxl {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xxl {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xxl {
    padding-top: 13.5rem;
  }

  .space-pr-27--xxl {
    padding-right: 13.5rem;
  }

  .space-pb-27--xxl {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xxl {
    padding-left: 13.5rem;
  }

  .space-m-30--xxl {
    margin: 15rem;
  }

  .space-mx-30--xxl {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xxl {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xxl {
    margin-top: 15rem;
  }

  .space-mr-30--xxl {
    margin-right: 15rem;
  }

  .space-mb-30--xxl {
    margin-bottom: 15rem;
  }

  .space-ml-30--xxl {
    margin-left: 15rem;
  }

  .space-p-30--xxl {
    padding: 15rem;
  }

  .space-px-30--xxl {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xxl {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xxl {
    padding-top: 15rem;
  }

  .space-pr-30--xxl {
    padding-right: 15rem;
  }

  .space-pb-30--xxl {
    padding-bottom: 15rem;
  }

  .space-pl-30--xxl {
    padding-left: 15rem;
  }

  .space-m-46--xxl {
    margin: 23rem;
  }

  .space-mx-46--xxl {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xxl {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xxl {
    margin-top: 23rem;
  }

  .space-mr-46--xxl {
    margin-right: 23rem;
  }

  .space-mb-46--xxl {
    margin-bottom: 23rem;
  }

  .space-ml-46--xxl {
    margin-left: 23rem;
  }

  .space-p-46--xxl {
    padding: 23rem;
  }

  .space-px-46--xxl {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xxl {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xxl {
    padding-top: 23rem;
  }

  .space-pr-46--xxl {
    padding-right: 23rem;
  }

  .space-pb-46--xxl {
    padding-bottom: 23rem;
  }

  .space-pl-46--xxl {
    padding-left: 23rem;
  }

  .space-m-60--xxl {
    margin: 30rem;
  }

  .space-mx-60--xxl {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xxl {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xxl {
    margin-top: 30rem;
  }

  .space-mr-60--xxl {
    margin-right: 30rem;
  }

  .space-mb-60--xxl {
    margin-bottom: 30rem;
  }

  .space-ml-60--xxl {
    margin-left: 30rem;
  }

  .space-p-60--xxl {
    padding: 30rem;
  }

  .space-px-60--xxl {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xxl {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xxl {
    padding-top: 30rem;
  }

  .space-pr-60--xxl {
    padding-right: 30rem;
  }

  .space-pb-60--xxl {
    padding-bottom: 30rem;
  }

  .space-pl-60--xxl {
    padding-left: 30rem;
  }
}
/*============================================================================*\
   Displays helpers
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/** @type {List} List of display values to use */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * A mixin to hide elements but keep them accessible
 *
 * @author Facebook Accessibility Team
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-hidden-accessible {
  position: absolute;
  width: 1px;
  height: 1px;
  white-space: nowrap;
  pointer-events: none;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/*==========================================================================*\
   Visibility breakpoints modifiers
\*==========================================================================*/
@media (min-width: 0em) {
  .display-none--xxs {
    display: none;
  }

  .display-block--xxs {
    display: block;
  }

  .display-inline-block--xxs {
    display: inline-block;
  }

  .display-hidden-accessible--xxs {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
@media (min-width: 30em) {
  .display-none--xs {
    display: none;
  }

  .display-block--xs {
    display: block;
  }

  .display-inline-block--xs {
    display: inline-block;
  }

  .display-hidden-accessible--xs {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
@media (min-width: 48em) {
  .display-none--s {
    display: none;
  }

  .display-block--s {
    display: block;
  }

  .display-inline-block--s {
    display: inline-block;
  }

  .display-hidden-accessible--s {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
@media (min-width: 64em) {
  .display-none--m {
    display: none;
  }

  .display-block--m {
    display: block;
  }

  .display-inline-block--m {
    display: inline-block;
  }

  .display-hidden-accessible--m {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
@media (min-width: 80em) {
  .display-none--l {
    display: none;
  }

  .display-block--l {
    display: block;
  }

  .display-inline-block--l {
    display: inline-block;
  }

  .display-hidden-accessible--l {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
@media (min-width: 90em) {
  .display-none--xl {
    display: none;
  }

  .display-block--xl {
    display: block;
  }

  .display-inline-block--xl {
    display: inline-block;
  }

  .display-hidden-accessible--xl {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
@media (min-width: 120em) {
  .display-none--xxl {
    display: none;
  }

  .display-block--xxl {
    display: block;
  }

  .display-inline-block--xxl {
    display: inline-block;
  }

  .display-hidden-accessible--xxl {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
}
/*============================================================================*\
   Main typography styles
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/*============================================================================*\
   Generic mixins
\*============================================================================*/
/**
 * Responsive typograhy
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 *
 * @param  {integer} $min-width The minimum breakpoint
 * @param  {integer} $max-width The maximum breakpoint
 * @param  {integer} $min-size  The minimum font-size
 * @param  {integer} $max-size  The maximum font-size
 */
/**
 * Antialiasing for better font rendering
 */
.type-antialiased,
.type-antialiased input,
.type-antialiased button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*============================================================================*\
   Type sizes definitions
\*============================================================================*/
/**
 * A map to define all type-sizes and their corresponding line-heights, the
 * first value is the font-size, the seconde the line-height.
 *
 * @type {Map}
 */
/**
 * A mixin to get both font-size and line-height given a named font-size
 *
 * @param  {string} $type-size The font-size name
 * @param  {string} $unit      The unit for the font-size value
 * @return {string}            The `font-size` and `line-height` declarations
 */
/**
 * Alias for the `font-size($type-size, $unit)` mixin defined above
 */
/* Class helpers
\*============================================================================*/
.type-display-1 {
  font-size: 5em;
  font-weight: 900;
  line-height: 1.125;
}

.type-rem-display-1 {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1.125;
}

.type-display-1-mobile {
  font-size: 1.625em;
  font-weight: 900;
  line-height: 1.3076923077;
}

.type-rem-display-1-mobile {
  font-size: 1.625rem;
  font-weight: 900;
  line-height: 1.3076923077;
}

.type-display-2 {
  font-size: 3.375em;
  font-weight: 900;
  line-height: 1.3333333333;
}

.type-rem-display-2 {
  font-size: 3.375rem;
  font-weight: 900;
  line-height: 1.3333333333;
}

.type-display-3 {
  font-size: 2.375em;
  font-weight: 900;
  line-height: 1.3684210526;
}

.type-rem-display-3 {
  font-size: 2.375rem;
  font-weight: 900;
  line-height: 1.3684210526;
}

.type-subtitle {
  font-size: 1.5em;
  font-weight: 500;
  line-height: 1.4166666667;
}

.type-rem-subtitle {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4166666667;
}

.type-subtitle-small {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.5;
}

.type-rem-subtitle-small {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
}

.type-body {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1.6666666667;
}

.type-rem-body {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6666666667;
}

.type-body-small {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.625;
}

.type-rem-body-small {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
}

.type-small {
  font-size: 1em;
  font-weight: 700;
}

.type-rem-small {
  font-size: 1rem;
  font-weight: 700;
}

.type-tiny {
  font-size: 0.875em;
  font-weight: 700;
}

.type-rem-tiny {
  font-size: 0.875rem;
  font-weight: 700;
}

.type-button {
  font-size: 1em;
  font-weight: 500;
}

.type-rem-button {
  font-size: 1rem;
  font-weight: 500;
}

.type-button-small {
  font-size: 0.875em;
  font-weight: 500;
}

.type-rem-button-small {
  font-size: 0.875rem;
  font-weight: 500;
}

@media (min-width: 0em) {
  .type-display-1--xxs {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--xxs {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--xxs {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--xxs {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--xxs {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--xxs {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--xxs {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--xxs {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--xxs {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--xxs {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--xxs {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--xxs {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--xxs {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--xxs {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--xxs {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--xxs {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--xxs {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--xxs {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--xxs {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--xxs {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--xxs {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--xxs {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--xxs {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--xxs {
    font-size: 0.875em;
    font-weight: 500;
  }
}
@media (min-width: 30em) {
  .type-display-1--xs {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--xs {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--xs {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--xs {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--xs {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--xs {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--xs {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--xs {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--xs {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--xs {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--xs {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--xs {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--xs {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--xs {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--xs {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--xs {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--xs {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--xs {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--xs {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--xs {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--xs {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--xs {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--xs {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--xs {
    font-size: 0.875em;
    font-weight: 500;
  }
}
@media (min-width: 48em) {
  .type-display-1--s {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--s {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--s {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--s {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--s {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--s {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--s {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--s {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--s {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--s {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--s {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--s {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--s {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--s {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--s {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--s {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--s {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--s {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--s {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--s {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--s {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--s {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--s {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--s {
    font-size: 0.875em;
    font-weight: 500;
  }
}
@media (min-width: 64em) {
  .type-display-1--m {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--m {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--m {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--m {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--m {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--m {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--m {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--m {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--m {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--m {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--m {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--m {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--m {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--m {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--m {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--m {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--m {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--m {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--m {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--m {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--m {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--m {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--m {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--m {
    font-size: 0.875em;
    font-weight: 500;
  }
}
@media (min-width: 80em) {
  .type-display-1--l {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--l {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--l {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--l {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--l {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--l {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--l {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--l {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--l {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--l {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--l {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--l {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--l {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--l {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--l {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--l {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--l {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--l {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--l {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--l {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--l {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--l {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--l {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--l {
    font-size: 0.875em;
    font-weight: 500;
  }
}
@media (min-width: 90em) {
  .type-display-1--xl {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--xl {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--xl {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--xl {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--xl {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--xl {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--xl {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--xl {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--xl {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--xl {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--xl {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--xl {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--xl {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--xl {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--xl {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--xl {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--xl {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--xl {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--xl {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--xl {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--xl {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--xl {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--xl {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--xl {
    font-size: 0.875em;
    font-weight: 500;
  }
}
@media (min-width: 120em) {
  .type-display-1--xxl {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-rem-display-1--xxl {
    font-size: 5em;
    font-weight: 900;
    line-height: 1.125;
  }

  .type-display-1-mobile--xxl {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-rem-display-1-mobile--xxl {
    font-size: 1.625em;
    font-weight: 900;
    line-height: 1.3076923077;
  }

  .type-display-2--xxl {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-rem-display-2--xxl {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }

  .type-display-3--xxl {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-rem-display-3--xxl {
    font-size: 2.375em;
    font-weight: 900;
    line-height: 1.3684210526;
  }

  .type-subtitle--xxl {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-rem-subtitle--xxl {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }

  .type-subtitle-small--xxl {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-rem-subtitle-small--xxl {
    font-size: 1.25em;
    font-weight: 500;
    line-height: 1.5;
  }

  .type-body--xxl {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-rem-body--xxl {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }

  .type-body-small--xxl {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-rem-body-small--xxl {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.625;
  }

  .type-small--xxl {
    font-size: 1em;
    font-weight: 700;
  }

  .type-rem-small--xxl {
    font-size: 1em;
    font-weight: 700;
  }

  .type-tiny--xxl {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-rem-tiny--xxl {
    font-size: 0.875em;
    font-weight: 700;
  }

  .type-button--xxl {
    font-size: 1em;
    font-weight: 500;
  }

  .type-rem-button--xxl {
    font-size: 1em;
    font-weight: 500;
  }

  .type-button-small--xxl {
    font-size: 0.875em;
    font-weight: 500;
  }

  .type-rem-button-small--xxl {
    font-size: 0.875em;
    font-weight: 500;
  }
}
/*============================================================================*\
   Type font families definitions
\*============================================================================*/
/** @type {String} The path to the webfonts directory */
/** @type {String} The value for all `font-display` properties */
/**
 * A map to define all font-families specifications which we might refer to by a
 * named identifier. The map is formatted as follow:
 *
 * (
 *   <identifier>: (
 *     name: <font-family-name>,
 *     stack: <font-family-stack>,
 *     webfonts: (
 *       (
 *         filename: <webfont-filename>,
 *         weight: <webfont-weight>,
 *         style: <webfont-style>,
 *       ),
 *     ),
 *   ),
 * )
 *
 * @type {Map}
 */
/**
 * A function to get a font-family stack by its identifier
 *
 * @param  {string} $type-font The font-family identifier
 * @return {string}            The font-family defined stack
 */
/**
 * Alias for the `font-family($type-font)` function defined above
 *
 * @param  {string} $type-font The font-family identifier
 * @return {string}            The font-family defined stack
 */
/* Class helpers
\*============================================================================*/
.type-sans {
  font-family: neue-haas-grotesk-text, sans-serif;
}

.type-serif {
  font-family: starling, serif;
}

/*============================================================================*\
   Type alignement definition
\*============================================================================*/
/** @type {List} List of alignment values to use */
/* Class helpers
\*============================================================================*/
.type-align-left {
  text-align: left;
}

.type-align-center {
  text-align: center;
}

.type-align-right {
  text-align: right;
}

@media (min-width: 0em) {
  .type-align-left--xxs {
    text-align: left;
  }

  .type-align-center--xxs {
    text-align: center;
  }

  .type-align-right--xxs {
    text-align: right;
  }
}
@media (min-width: 30em) {
  .type-align-left--xs {
    text-align: left;
  }

  .type-align-center--xs {
    text-align: center;
  }

  .type-align-right--xs {
    text-align: right;
  }
}
@media (min-width: 48em) {
  .type-align-left--s {
    text-align: left;
  }

  .type-align-center--s {
    text-align: center;
  }

  .type-align-right--s {
    text-align: right;
  }
}
@media (min-width: 64em) {
  .type-align-left--m {
    text-align: left;
  }

  .type-align-center--m {
    text-align: center;
  }

  .type-align-right--m {
    text-align: right;
  }
}
@media (min-width: 80em) {
  .type-align-left--l {
    text-align: left;
  }

  .type-align-center--l {
    text-align: center;
  }

  .type-align-right--l {
    text-align: right;
  }
}
@media (min-width: 90em) {
  .type-align-left--xl {
    text-align: left;
  }

  .type-align-center--xl {
    text-align: center;
  }

  .type-align-right--xl {
    text-align: right;
  }
}
@media (min-width: 120em) {
  .type-align-left--xxl {
    text-align: left;
  }

  .type-align-center--xxl {
    text-align: center;
  }

  .type-align-right--xxl {
    text-align: right;
  }
}
/*============================================================================*\
   Type font weight helpers
\*============================================================================*/
/** @type {List} List of font weights values to use */
/* Class helpers
\*============================================================================*/
.type-weight-400 {
  font-weight: 400;
}

.type-weight-500 {
  font-weight: 500;
}

.type-weight-700 {
  font-weight: 700;
}

.type-weight-800 {
  font-weight: 800;
}

.type-weight-900 {
  font-weight: 900;
}

/**
 * Generate helpers for each defined breakpoints
 */
@media (min-width: 0em) {
  .type-weight-400--xxs {
    font-weight: 400;
  }

  .type-weight-500--xxs {
    font-weight: 500;
  }

  .type-weight-700--xxs {
    font-weight: 700;
  }

  .type-weight-800--xxs {
    font-weight: 800;
  }

  .type-weight-900--xxs {
    font-weight: 900;
  }
}
@media (min-width: 30em) {
  .type-weight-400--xs {
    font-weight: 400;
  }

  .type-weight-500--xs {
    font-weight: 500;
  }

  .type-weight-700--xs {
    font-weight: 700;
  }

  .type-weight-800--xs {
    font-weight: 800;
  }

  .type-weight-900--xs {
    font-weight: 900;
  }
}
@media (min-width: 48em) {
  .type-weight-400--s {
    font-weight: 400;
  }

  .type-weight-500--s {
    font-weight: 500;
  }

  .type-weight-700--s {
    font-weight: 700;
  }

  .type-weight-800--s {
    font-weight: 800;
  }

  .type-weight-900--s {
    font-weight: 900;
  }
}
@media (min-width: 64em) {
  .type-weight-400--m {
    font-weight: 400;
  }

  .type-weight-500--m {
    font-weight: 500;
  }

  .type-weight-700--m {
    font-weight: 700;
  }

  .type-weight-800--m {
    font-weight: 800;
  }

  .type-weight-900--m {
    font-weight: 900;
  }
}
@media (min-width: 80em) {
  .type-weight-400--l {
    font-weight: 400;
  }

  .type-weight-500--l {
    font-weight: 500;
  }

  .type-weight-700--l {
    font-weight: 700;
  }

  .type-weight-800--l {
    font-weight: 800;
  }

  .type-weight-900--l {
    font-weight: 900;
  }
}
@media (min-width: 90em) {
  .type-weight-400--xl {
    font-weight: 400;
  }

  .type-weight-500--xl {
    font-weight: 500;
  }

  .type-weight-700--xl {
    font-weight: 700;
  }

  .type-weight-800--xl {
    font-weight: 800;
  }

  .type-weight-900--xl {
    font-weight: 900;
  }
}
@media (min-width: 120em) {
  .type-weight-400--xxl {
    font-weight: 400;
  }

  .type-weight-500--xxl {
    font-weight: 500;
  }

  .type-weight-700--xxl {
    font-weight: 700;
  }

  .type-weight-800--xxl {
    font-weight: 800;
  }

  .type-weight-900--xxl {
    font-weight: 900;
  }
}
/*============================================================================*\
   Type spacings definitions
\*============================================================================*/
/** @type {List} List of letter spacings values to use */
/* Class helpers
\*============================================================================*/
.type-spacing-25 {
  letter-spacing: 0.025em;
}

.type-spacing-50 {
  letter-spacing: 0.05em;
}

.type-spacing-100 {
  letter-spacing: 0.1em;
}

.type-spacing-200 {
  letter-spacing: 0.2em;
}

/**
 * Generate helpers for each defined breakpoints
 */
@media (min-width: 0em) {
  .type-spacing-25--xxs {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--xxs {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--xxs {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--xxs {
    letter-spacing: 0.2em;
  }
}
@media (min-width: 30em) {
  .type-spacing-25--xs {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--xs {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--xs {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--xs {
    letter-spacing: 0.2em;
  }
}
@media (min-width: 48em) {
  .type-spacing-25--s {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--s {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--s {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--s {
    letter-spacing: 0.2em;
  }
}
@media (min-width: 64em) {
  .type-spacing-25--m {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--m {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--m {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--m {
    letter-spacing: 0.2em;
  }
}
@media (min-width: 80em) {
  .type-spacing-25--l {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--l {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--l {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--l {
    letter-spacing: 0.2em;
  }
}
@media (min-width: 90em) {
  .type-spacing-25--xl {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--xl {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--xl {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--xl {
    letter-spacing: 0.2em;
  }
}
@media (min-width: 120em) {
  .type-spacing-25--xxl {
    letter-spacing: 0.025em;
  }

  .type-spacing-50--xxl {
    letter-spacing: 0.05em;
  }

  .type-spacing-100--xxl {
    letter-spacing: 0.1em;
  }

  .type-spacing-200--xxl {
    letter-spacing: 0.2em;
  }
}
/*============================================================================*\
   Type transforms definitions
\*============================================================================*/
/** @type {List} List of text transform values to use */
/* Class helpers
\*============================================================================*/
.type-transform-uppercase {
  text-transform: uppercase;
}

.type-transform-lowercase {
  text-transform: lowercase;
}

.type-transform-capitalize {
  text-transform: capitalize;
}

/*============================================================================*\
   Type decorations definitions
\*============================================================================*/
/** @type {List} List of text decoration values to use */
/* Class helpers
\*============================================================================*/
.type-decoration-none {
  text-decoration: none;
}

.type-decoration-underline {
  text-decoration: underline;
}

/*============================================================================*\
   Flex helpers
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/*============================================================================*\
   Flex display
\*============================================================================*/
.flex {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

@media (min-width: 0em) {
  .flex--xxs {
    display: flex;
  }

  .flex-inline--xxs {
    display: inline-flex;
  }
}
@media (min-width: 30em) {
  .flex--xs {
    display: flex;
  }

  .flex-inline--xs {
    display: inline-flex;
  }
}
@media (min-width: 48em) {
  .flex--s {
    display: flex;
  }

  .flex-inline--s {
    display: inline-flex;
  }
}
@media (min-width: 64em) {
  .flex--m {
    display: flex;
  }

  .flex-inline--m {
    display: inline-flex;
  }
}
@media (min-width: 80em) {
  .flex--l {
    display: flex;
  }

  .flex-inline--l {
    display: inline-flex;
  }
}
@media (min-width: 90em) {
  .flex--xl {
    display: flex;
  }

  .flex-inline--xl {
    display: inline-flex;
  }
}
@media (min-width: 120em) {
  .flex--xxl {
    display: flex;
  }

  .flex-inline--xxl {
    display: inline-flex;
  }
}
/*============================================================================*\
   Align (items / self / content)
\*============================================================================*/
.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.align-start-self {
  align-self: flex-start;
}

.align-center-self {
  align-self: center;
}

.align-end-self {
  align-self: flex-end;
}

.align-stretch-self {
  align-self: stretch;
}

.align-start-content {
  align-content: flex-start;
}

.align-center-content {
  align-content: center;
}

.align-end-content {
  align-content: flex-end;
}

.align-stretch-content {
  align-content: stretch;
}

.align-around-content {
  align-content: space-around;
}

.align-between-content {
  align-content: space-between;
}

@media (min-width: 0em) {
  .align-start--xxs {
    align-items: flex-start;
  }

  .align-center--xxs {
    align-items: center;
  }

  .align-end--xxs {
    align-items: flex-end;
  }

  .align-stretch--xxs {
    align-items: stretch;
  }

  .align-start-self--xxs {
    align-self: flex-start;
  }

  .align-center-self--xxs {
    align-self: center;
  }

  .align-end-self--xxs {
    align-self: flex-end;
  }

  .align-stretch-self--xxs {
    align-self: stretch;
  }

  .align-start-content--xxs {
    align-content: flex-start;
  }

  .align-center-content--xxs {
    align-content: center;
  }

  .align-end-content--xxs {
    align-content: flex-end;
  }

  .align-stretch-content--xxs {
    align-content: stretch;
  }

  .align-around-content--xxs {
    align-content: space-around;
  }

  .align-between-content--xxs {
    align-content: space-between;
  }
}
@media (min-width: 30em) {
  .align-start--xs {
    align-items: flex-start;
  }

  .align-center--xs {
    align-items: center;
  }

  .align-end--xs {
    align-items: flex-end;
  }

  .align-stretch--xs {
    align-items: stretch;
  }

  .align-start-self--xs {
    align-self: flex-start;
  }

  .align-center-self--xs {
    align-self: center;
  }

  .align-end-self--xs {
    align-self: flex-end;
  }

  .align-stretch-self--xs {
    align-self: stretch;
  }

  .align-start-content--xs {
    align-content: flex-start;
  }

  .align-center-content--xs {
    align-content: center;
  }

  .align-end-content--xs {
    align-content: flex-end;
  }

  .align-stretch-content--xs {
    align-content: stretch;
  }

  .align-around-content--xs {
    align-content: space-around;
  }

  .align-between-content--xs {
    align-content: space-between;
  }
}
@media (min-width: 48em) {
  .align-start--s {
    align-items: flex-start;
  }

  .align-center--s {
    align-items: center;
  }

  .align-end--s {
    align-items: flex-end;
  }

  .align-stretch--s {
    align-items: stretch;
  }

  .align-start-self--s {
    align-self: flex-start;
  }

  .align-center-self--s {
    align-self: center;
  }

  .align-end-self--s {
    align-self: flex-end;
  }

  .align-stretch-self--s {
    align-self: stretch;
  }

  .align-start-content--s {
    align-content: flex-start;
  }

  .align-center-content--s {
    align-content: center;
  }

  .align-end-content--s {
    align-content: flex-end;
  }

  .align-stretch-content--s {
    align-content: stretch;
  }

  .align-around-content--s {
    align-content: space-around;
  }

  .align-between-content--s {
    align-content: space-between;
  }
}
@media (min-width: 64em) {
  .align-start--m {
    align-items: flex-start;
  }

  .align-center--m {
    align-items: center;
  }

  .align-end--m {
    align-items: flex-end;
  }

  .align-stretch--m {
    align-items: stretch;
  }

  .align-start-self--m {
    align-self: flex-start;
  }

  .align-center-self--m {
    align-self: center;
  }

  .align-end-self--m {
    align-self: flex-end;
  }

  .align-stretch-self--m {
    align-self: stretch;
  }

  .align-start-content--m {
    align-content: flex-start;
  }

  .align-center-content--m {
    align-content: center;
  }

  .align-end-content--m {
    align-content: flex-end;
  }

  .align-stretch-content--m {
    align-content: stretch;
  }

  .align-around-content--m {
    align-content: space-around;
  }

  .align-between-content--m {
    align-content: space-between;
  }
}
@media (min-width: 80em) {
  .align-start--l {
    align-items: flex-start;
  }

  .align-center--l {
    align-items: center;
  }

  .align-end--l {
    align-items: flex-end;
  }

  .align-stretch--l {
    align-items: stretch;
  }

  .align-start-self--l {
    align-self: flex-start;
  }

  .align-center-self--l {
    align-self: center;
  }

  .align-end-self--l {
    align-self: flex-end;
  }

  .align-stretch-self--l {
    align-self: stretch;
  }

  .align-start-content--l {
    align-content: flex-start;
  }

  .align-center-content--l {
    align-content: center;
  }

  .align-end-content--l {
    align-content: flex-end;
  }

  .align-stretch-content--l {
    align-content: stretch;
  }

  .align-around-content--l {
    align-content: space-around;
  }

  .align-between-content--l {
    align-content: space-between;
  }
}
@media (min-width: 90em) {
  .align-start--xl {
    align-items: flex-start;
  }

  .align-center--xl {
    align-items: center;
  }

  .align-end--xl {
    align-items: flex-end;
  }

  .align-stretch--xl {
    align-items: stretch;
  }

  .align-start-self--xl {
    align-self: flex-start;
  }

  .align-center-self--xl {
    align-self: center;
  }

  .align-end-self--xl {
    align-self: flex-end;
  }

  .align-stretch-self--xl {
    align-self: stretch;
  }

  .align-start-content--xl {
    align-content: flex-start;
  }

  .align-center-content--xl {
    align-content: center;
  }

  .align-end-content--xl {
    align-content: flex-end;
  }

  .align-stretch-content--xl {
    align-content: stretch;
  }

  .align-around-content--xl {
    align-content: space-around;
  }

  .align-between-content--xl {
    align-content: space-between;
  }
}
@media (min-width: 120em) {
  .align-start--xxl {
    align-items: flex-start;
  }

  .align-center--xxl {
    align-items: center;
  }

  .align-end--xxl {
    align-items: flex-end;
  }

  .align-stretch--xxl {
    align-items: stretch;
  }

  .align-start-self--xxl {
    align-self: flex-start;
  }

  .align-center-self--xxl {
    align-self: center;
  }

  .align-end-self--xxl {
    align-self: flex-end;
  }

  .align-stretch-self--xxl {
    align-self: stretch;
  }

  .align-start-content--xxl {
    align-content: flex-start;
  }

  .align-center-content--xxl {
    align-content: center;
  }

  .align-end-content--xxl {
    align-content: flex-end;
  }

  .align-stretch-content--xxl {
    align-content: stretch;
  }

  .align-around-content--xxl {
    align-content: space-around;
  }

  .align-between-content--xxl {
    align-content: space-between;
  }
}
/*============================================================================*\
   Justify (content / self)
\*============================================================================*/
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-start-self {
  justify-self: flex-start;
}

.justify-center-self {
  justify-self: center;
}

.justify-end-self {
  justify-self: flex-end;
}

.justify-around-self {
  justify-self: space-around;
}

.justify-between-self {
  justify-self: space-between;
}

.justify-evenly-self {
  justify-self: space-evenly;
}

@media (min-width: 0em) {
  .justify-start--xxs {
    justify-content: flex-start;
  }

  .justify-center--xxs {
    justify-content: center;
  }

  .justify-end--xxs {
    justify-content: flex-end;
  }

  .justify-around--xxs {
    justify-content: space-around;
  }

  .justify-between--xxs {
    justify-content: space-between;
  }

  .justify-evenly--xxs {
    justify-content: space-evenly;
  }

  .justify-start-self--xxs {
    justify-self: flex-start;
  }

  .justify-center-self--xxs {
    justify-self: center;
  }

  .justify-end-self--xxs {
    justify-self: flex-end;
  }

  .justify-around-self--xxs {
    justify-self: space-around;
  }

  .justify-between-self--xxs {
    justify-self: space-between;
  }

  .justify-evenly-self--xxs {
    justify-self: space-evenly;
  }
}
@media (min-width: 30em) {
  .justify-start--xs {
    justify-content: flex-start;
  }

  .justify-center--xs {
    justify-content: center;
  }

  .justify-end--xs {
    justify-content: flex-end;
  }

  .justify-around--xs {
    justify-content: space-around;
  }

  .justify-between--xs {
    justify-content: space-between;
  }

  .justify-evenly--xs {
    justify-content: space-evenly;
  }

  .justify-start-self--xs {
    justify-self: flex-start;
  }

  .justify-center-self--xs {
    justify-self: center;
  }

  .justify-end-self--xs {
    justify-self: flex-end;
  }

  .justify-around-self--xs {
    justify-self: space-around;
  }

  .justify-between-self--xs {
    justify-self: space-between;
  }

  .justify-evenly-self--xs {
    justify-self: space-evenly;
  }
}
@media (min-width: 48em) {
  .justify-start--s {
    justify-content: flex-start;
  }

  .justify-center--s {
    justify-content: center;
  }

  .justify-end--s {
    justify-content: flex-end;
  }

  .justify-around--s {
    justify-content: space-around;
  }

  .justify-between--s {
    justify-content: space-between;
  }

  .justify-evenly--s {
    justify-content: space-evenly;
  }

  .justify-start-self--s {
    justify-self: flex-start;
  }

  .justify-center-self--s {
    justify-self: center;
  }

  .justify-end-self--s {
    justify-self: flex-end;
  }

  .justify-around-self--s {
    justify-self: space-around;
  }

  .justify-between-self--s {
    justify-self: space-between;
  }

  .justify-evenly-self--s {
    justify-self: space-evenly;
  }
}
@media (min-width: 64em) {
  .justify-start--m {
    justify-content: flex-start;
  }

  .justify-center--m {
    justify-content: center;
  }

  .justify-end--m {
    justify-content: flex-end;
  }

  .justify-around--m {
    justify-content: space-around;
  }

  .justify-between--m {
    justify-content: space-between;
  }

  .justify-evenly--m {
    justify-content: space-evenly;
  }

  .justify-start-self--m {
    justify-self: flex-start;
  }

  .justify-center-self--m {
    justify-self: center;
  }

  .justify-end-self--m {
    justify-self: flex-end;
  }

  .justify-around-self--m {
    justify-self: space-around;
  }

  .justify-between-self--m {
    justify-self: space-between;
  }

  .justify-evenly-self--m {
    justify-self: space-evenly;
  }
}
@media (min-width: 80em) {
  .justify-start--l {
    justify-content: flex-start;
  }

  .justify-center--l {
    justify-content: center;
  }

  .justify-end--l {
    justify-content: flex-end;
  }

  .justify-around--l {
    justify-content: space-around;
  }

  .justify-between--l {
    justify-content: space-between;
  }

  .justify-evenly--l {
    justify-content: space-evenly;
  }

  .justify-start-self--l {
    justify-self: flex-start;
  }

  .justify-center-self--l {
    justify-self: center;
  }

  .justify-end-self--l {
    justify-self: flex-end;
  }

  .justify-around-self--l {
    justify-self: space-around;
  }

  .justify-between-self--l {
    justify-self: space-between;
  }

  .justify-evenly-self--l {
    justify-self: space-evenly;
  }
}
@media (min-width: 90em) {
  .justify-start--xl {
    justify-content: flex-start;
  }

  .justify-center--xl {
    justify-content: center;
  }

  .justify-end--xl {
    justify-content: flex-end;
  }

  .justify-around--xl {
    justify-content: space-around;
  }

  .justify-between--xl {
    justify-content: space-between;
  }

  .justify-evenly--xl {
    justify-content: space-evenly;
  }

  .justify-start-self--xl {
    justify-self: flex-start;
  }

  .justify-center-self--xl {
    justify-self: center;
  }

  .justify-end-self--xl {
    justify-self: flex-end;
  }

  .justify-around-self--xl {
    justify-self: space-around;
  }

  .justify-between-self--xl {
    justify-self: space-between;
  }

  .justify-evenly-self--xl {
    justify-self: space-evenly;
  }
}
@media (min-width: 120em) {
  .justify-start--xxl {
    justify-content: flex-start;
  }

  .justify-center--xxl {
    justify-content: center;
  }

  .justify-end--xxl {
    justify-content: flex-end;
  }

  .justify-around--xxl {
    justify-content: space-around;
  }

  .justify-between--xxl {
    justify-content: space-between;
  }

  .justify-evenly--xxl {
    justify-content: space-evenly;
  }

  .justify-start-self--xxl {
    justify-self: flex-start;
  }

  .justify-center-self--xxl {
    justify-self: center;
  }

  .justify-end-self--xxl {
    justify-self: flex-end;
  }

  .justify-around-self--xxl {
    justify-self: space-around;
  }

  .justify-between-self--xxl {
    justify-self: space-between;
  }

  .justify-evenly-self--xxl {
    justify-self: space-evenly;
  }
}
/*============================================================================*\
   Direction
\*============================================================================*/
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 0em) {
  .flex-row--xxs {
    flex-direction: row;
  }

  .flex-row-reverse--xxs {
    flex-direction: row-reverse;
  }

  .flex-column--xxs {
    flex-direction: column;
  }

  .flex-column-reverse--xxs {
    flex-direction: column-reverse;
  }
}
@media (min-width: 30em) {
  .flex-row--xs {
    flex-direction: row;
  }

  .flex-row-reverse--xs {
    flex-direction: row-reverse;
  }

  .flex-column--xs {
    flex-direction: column;
  }

  .flex-column-reverse--xs {
    flex-direction: column-reverse;
  }
}
@media (min-width: 48em) {
  .flex-row--s {
    flex-direction: row;
  }

  .flex-row-reverse--s {
    flex-direction: row-reverse;
  }

  .flex-column--s {
    flex-direction: column;
  }

  .flex-column-reverse--s {
    flex-direction: column-reverse;
  }
}
@media (min-width: 64em) {
  .flex-row--m {
    flex-direction: row;
  }

  .flex-row-reverse--m {
    flex-direction: row-reverse;
  }

  .flex-column--m {
    flex-direction: column;
  }

  .flex-column-reverse--m {
    flex-direction: column-reverse;
  }
}
@media (min-width: 80em) {
  .flex-row--l {
    flex-direction: row;
  }

  .flex-row-reverse--l {
    flex-direction: row-reverse;
  }

  .flex-column--l {
    flex-direction: column;
  }

  .flex-column-reverse--l {
    flex-direction: column-reverse;
  }
}
@media (min-width: 90em) {
  .flex-row--xl {
    flex-direction: row;
  }

  .flex-row-reverse--xl {
    flex-direction: row-reverse;
  }

  .flex-column--xl {
    flex-direction: column;
  }

  .flex-column-reverse--xl {
    flex-direction: column-reverse;
  }
}
@media (min-width: 120em) {
  .flex-row--xxl {
    flex-direction: row;
  }

  .flex-row-reverse--xxl {
    flex-direction: row-reverse;
  }

  .flex-column--xxl {
    flex-direction: column;
  }

  .flex-column-reverse--xxl {
    flex-direction: column-reverse;
  }
}
/*============================================================================*\
   Wrap
\*============================================================================*/
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

@media (min-width: 0em) {
  .flex-wrap--xxs {
    flex-wrap: wrap;
  }

  .flex-nowrap--xxs {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--xxs {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 30em) {
  .flex-wrap--xs {
    flex-wrap: wrap;
  }

  .flex-nowrap--xs {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--xs {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 48em) {
  .flex-wrap--s {
    flex-wrap: wrap;
  }

  .flex-nowrap--s {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--s {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 64em) {
  .flex-wrap--m {
    flex-wrap: wrap;
  }

  .flex-nowrap--m {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--m {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 80em) {
  .flex-wrap--l {
    flex-wrap: wrap;
  }

  .flex-nowrap--l {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--l {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 90em) {
  .flex-wrap--xl {
    flex-wrap: wrap;
  }

  .flex-nowrap--xl {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--xl {
    flex-wrap: wrap-reverse;
  }
}
@media (min-width: 120em) {
  .flex-wrap--xxl {
    flex-wrap: wrap;
  }

  .flex-nowrap--xxl {
    flex-wrap: nowrap;
  }

  .flex-wrap-reverse--xxl {
    flex-wrap: wrap-reverse;
  }
}
/*============================================================================*\
   Grow / Shrink
\*============================================================================*/
/** @type {List} List of flex-grow values to use */
/** @type {List} List of flex-shrink values to use */
/** @type {List} List of flex values to use */
/* Class helpers
\*============================================================================*/
.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-0 {
  flex: 0;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

@media (min-width: 0em) {
  .flex-grow-0--xxs {
    flex-grow: 0;
  }

  .flex-grow-1--xxs {
    flex-grow: 1;
  }

  .flex-shrink-0--xxs {
    flex-grow: 0;
  }

  .flex-shrink-1--xxs {
    flex-grow: 1;
  }

  .flex-0--xxs {
    flex: 0;
  }

  .flex-auto--xxs {
    flex: auto;
  }

  .flex-none--xxs {
    flex: none;
  }
}
@media (min-width: 30em) {
  .flex-grow-0--xs {
    flex-grow: 0;
  }

  .flex-grow-1--xs {
    flex-grow: 1;
  }

  .flex-shrink-0--xs {
    flex-grow: 0;
  }

  .flex-shrink-1--xs {
    flex-grow: 1;
  }

  .flex-0--xs {
    flex: 0;
  }

  .flex-auto--xs {
    flex: auto;
  }

  .flex-none--xs {
    flex: none;
  }
}
@media (min-width: 48em) {
  .flex-grow-0--s {
    flex-grow: 0;
  }

  .flex-grow-1--s {
    flex-grow: 1;
  }

  .flex-shrink-0--s {
    flex-grow: 0;
  }

  .flex-shrink-1--s {
    flex-grow: 1;
  }

  .flex-0--s {
    flex: 0;
  }

  .flex-auto--s {
    flex: auto;
  }

  .flex-none--s {
    flex: none;
  }
}
@media (min-width: 64em) {
  .flex-grow-0--m {
    flex-grow: 0;
  }

  .flex-grow-1--m {
    flex-grow: 1;
  }

  .flex-shrink-0--m {
    flex-grow: 0;
  }

  .flex-shrink-1--m {
    flex-grow: 1;
  }

  .flex-0--m {
    flex: 0;
  }

  .flex-auto--m {
    flex: auto;
  }

  .flex-none--m {
    flex: none;
  }
}
@media (min-width: 80em) {
  .flex-grow-0--l {
    flex-grow: 0;
  }

  .flex-grow-1--l {
    flex-grow: 1;
  }

  .flex-shrink-0--l {
    flex-grow: 0;
  }

  .flex-shrink-1--l {
    flex-grow: 1;
  }

  .flex-0--l {
    flex: 0;
  }

  .flex-auto--l {
    flex: auto;
  }

  .flex-none--l {
    flex: none;
  }
}
@media (min-width: 90em) {
  .flex-grow-0--xl {
    flex-grow: 0;
  }

  .flex-grow-1--xl {
    flex-grow: 1;
  }

  .flex-shrink-0--xl {
    flex-grow: 0;
  }

  .flex-shrink-1--xl {
    flex-grow: 1;
  }

  .flex-0--xl {
    flex: 0;
  }

  .flex-auto--xl {
    flex: auto;
  }

  .flex-none--xl {
    flex: none;
  }
}
@media (min-width: 120em) {
  .flex-grow-0--xxl {
    flex-grow: 0;
  }

  .flex-grow-1--xxl {
    flex-grow: 1;
  }

  .flex-shrink-0--xxl {
    flex-grow: 0;
  }

  .flex-shrink-1--xxl {
    flex-grow: 1;
  }

  .flex-0--xxl {
    flex: 0;
  }

  .flex-auto--xxl {
    flex: auto;
  }

  .flex-none--xxl {
    flex: none;
  }
}
/*============================================================================*\
   Opacities
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/** @type {List} List of opacity values to use */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to simplify the usage of opacities
 *
 * @param  {string} $opacity  The 100 based opacity value
 * @return {number}           The 1 based computed opacity
 */
/**
 * Alias for the `opacity($opacity)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

@media (min-width: 0em) {
  .opacity-0--xxs {
    opacity: 0;
  }

  .opacity-25--xxs {
    opacity: 0.25;
  }

  .opacity-50--xxs {
    opacity: 0.5;
  }

  .opacity-75--xxs {
    opacity: 0.75;
  }

  .opacity-100--xxs {
    opacity: 1;
  }
}
@media (min-width: 30em) {
  .opacity-0--xs {
    opacity: 0;
  }

  .opacity-25--xs {
    opacity: 0.25;
  }

  .opacity-50--xs {
    opacity: 0.5;
  }

  .opacity-75--xs {
    opacity: 0.75;
  }

  .opacity-100--xs {
    opacity: 1;
  }
}
@media (min-width: 48em) {
  .opacity-0--s {
    opacity: 0;
  }

  .opacity-25--s {
    opacity: 0.25;
  }

  .opacity-50--s {
    opacity: 0.5;
  }

  .opacity-75--s {
    opacity: 0.75;
  }

  .opacity-100--s {
    opacity: 1;
  }
}
@media (min-width: 64em) {
  .opacity-0--m {
    opacity: 0;
  }

  .opacity-25--m {
    opacity: 0.25;
  }

  .opacity-50--m {
    opacity: 0.5;
  }

  .opacity-75--m {
    opacity: 0.75;
  }

  .opacity-100--m {
    opacity: 1;
  }
}
@media (min-width: 80em) {
  .opacity-0--l {
    opacity: 0;
  }

  .opacity-25--l {
    opacity: 0.25;
  }

  .opacity-50--l {
    opacity: 0.5;
  }

  .opacity-75--l {
    opacity: 0.75;
  }

  .opacity-100--l {
    opacity: 1;
  }
}
@media (min-width: 90em) {
  .opacity-0--xl {
    opacity: 0;
  }

  .opacity-25--xl {
    opacity: 0.25;
  }

  .opacity-50--xl {
    opacity: 0.5;
  }

  .opacity-75--xl {
    opacity: 0.75;
  }

  .opacity-100--xl {
    opacity: 1;
  }
}
@media (min-width: 120em) {
  .opacity-0--xxl {
    opacity: 0;
  }

  .opacity-25--xxl {
    opacity: 0.25;
  }

  .opacity-50--xxl {
    opacity: 0.5;
  }

  .opacity-75--xxl {
    opacity: 0.75;
  }

  .opacity-100--xxl {
    opacity: 1;
  }
}
/*============================================================================*\
   Positions
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Boolean} Do we need the `--force` modifiers? */
/** @type {Boolean} Do we need the `--<breakpoint>` modifiers? */
/** @type {List} List of position values to use */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to simplify the usage of positions
 *
 * @param  {string} $position  The position value
 * @return {number}            The position value
 */
/**
 * Alias for the `position($position)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Class helpers
\*============================================================================*/
.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*============================================================================*\
   Grid System
\*============================================================================*/
/**
 * Usage:
 *
 * ```html
 * <div class="grid">
 *  <div class="grid__row">
 *    <div class="grid__col-12--xxs grid__col-6--s grid__col-4--lg">
 *      ...
 *    </div>
 *    <div class="grid__col-12--xxs grid__col-6--s grid__col-4--lg">
 *      ...
 *    </div>
 *  </div>
 *  ```
 *
 * When nesting grid, you'll need to add the `.grid--nested` modifier to the
 * block element.
 */
/*============================================================================*\
   Breakpoints definition
\*============================================================================*/
/**
 * Map of width based breakpoints names and values in pixels
 *
 * @type {Map}
 */
/**
 * Map of height based breakpoints names and values in pixels
 *
 * @type {Map}
 */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * Create variables for the media queries
 * @param  {string} $breakpoint The wanted breakpoint
 * @param  {string} $type            Type of media query (min or max)
 * @param  {string} $unit            The unit for the media queries (em or px)
 * @param  {string} $orientation     The orientation of the media query (width or height)
 * @return {string}                  A media query expression
 */
/**
 * Alias of the `media($breakpoint, $type, $unit, $orientation)` function above
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract the loop over each breakpoints
 *
 * @param  {ArgList} $excludes The breakpoints to exclude
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Spaces variables
\*============================================================================*/
/** @type {Boolean} Do we need classes? */
/** @type {Number} The base value of all spacings */
/** @type {List} List of all space factors */
/*============================================================================*\
   Functions
\*============================================================================*/
/**
 * A function helper to facilitate the usage of the different
 * spaces accross the whole projet.
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/**
 * Alias for the `space($space)` function above
 *
 * @param  {string} $size The name of the wanted size in the $spaces map
 * @return {string}       The corresponding size
 */
/*============================================================================*\
   Mixins
\*============================================================================*/
/**
 * Abstract loop over all spaces with the possibility to exclude some
 *
 * @param  {ArgList} $excludes The colors to exclude from the loop
 *
 * @author Titouan Mathis <titouan@studiometa.fr>
 * @since  1.2.0
 */
/*============================================================================*\
   Spaces class helpers
\*============================================================================*/
/**
 * Generate helper classes for each space defined in the above map. The naming
 * convention is as follow:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}
 * ```
 *
 * Helper classes are also generated for each breakpoint defined in the grid
 * of the style guide. The breakpoint specific classes are defined as modifiers:
 *
 * ```css
 * .space-{{ type }}-{{ factor }}--{{ breakpoint }}
 * ```
 *
 * For example, to set a global bottom double margin you can use the
 * `.space-mb-2` class. And to set a x-axis quadruple padding for the `xxl`
 * breakpoint you can use the `.space-px-4--xxl` class.
 *
 */
.space-m-0 {
  margin: 0rem;
}

.space-mx-0 {
  margin-right: 0rem;
  margin-left: 0rem;
}

.space-my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.space-mt-0 {
  margin-top: 0rem;
}

.space-mr-0 {
  margin-right: 0rem;
}

.space-mb-0 {
  margin-bottom: 0rem;
}

.space-ml-0 {
  margin-left: 0rem;
}

.space-p-0 {
  padding: 0rem;
}

.space-px-0 {
  padding-right: 0rem;
  padding-left: 0rem;
}

.space-py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.space-pt-0 {
  padding-top: 0rem;
}

.space-pr-0 {
  padding-right: 0rem;
}

.space-pb-0 {
  padding-bottom: 0rem;
}

.space-pl-0 {
  padding-left: 0rem;
}

.space-m-1 {
  margin: 0.5rem;
}

.space-mx-1 {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.space-my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.space-mt-1 {
  margin-top: 0.5rem;
}

.space-mr-1 {
  margin-right: 0.5rem;
}

.space-mb-1 {
  margin-bottom: 0.5rem;
}

.space-ml-1 {
  margin-left: 0.5rem;
}

.space-p-1 {
  padding: 0.5rem;
}

.space-px-1 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.space-py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.space-pt-1 {
  padding-top: 0.5rem;
}

.space-pr-1 {
  padding-right: 0.5rem;
}

.space-pb-1 {
  padding-bottom: 0.5rem;
}

.space-pl-1 {
  padding-left: 0.5rem;
}

.space-m-2 {
  margin: 1rem;
}

.space-mx-2 {
  margin-right: 1rem;
  margin-left: 1rem;
}

.space-my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.space-mt-2 {
  margin-top: 1rem;
}

.space-mr-2 {
  margin-right: 1rem;
}

.space-mb-2 {
  margin-bottom: 1rem;
}

.space-ml-2 {
  margin-left: 1rem;
}

.space-p-2 {
  padding: 1rem;
}

.space-px-2 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.space-py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.space-pt-2 {
  padding-top: 1rem;
}

.space-pr-2 {
  padding-right: 1rem;
}

.space-pb-2 {
  padding-bottom: 1rem;
}

.space-pl-2 {
  padding-left: 1rem;
}

.space-m-3 {
  margin: 1.5rem;
}

.space-mx-3 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}

.space-my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.space-mt-3 {
  margin-top: 1.5rem;
}

.space-mr-3 {
  margin-right: 1.5rem;
}

.space-mb-3 {
  margin-bottom: 1.5rem;
}

.space-ml-3 {
  margin-left: 1.5rem;
}

.space-p-3 {
  padding: 1.5rem;
}

.space-px-3 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.space-py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.space-pt-3 {
  padding-top: 1.5rem;
}

.space-pr-3 {
  padding-right: 1.5rem;
}

.space-pb-3 {
  padding-bottom: 1.5rem;
}

.space-pl-3 {
  padding-left: 1.5rem;
}

.space-m-4 {
  margin: 2rem;
}

.space-mx-4 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.space-my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.space-mt-4 {
  margin-top: 2rem;
}

.space-mr-4 {
  margin-right: 2rem;
}

.space-mb-4 {
  margin-bottom: 2rem;
}

.space-ml-4 {
  margin-left: 2rem;
}

.space-p-4 {
  padding: 2rem;
}

.space-px-4 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.space-py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.space-pt-4 {
  padding-top: 2rem;
}

.space-pr-4 {
  padding-right: 2rem;
}

.space-pb-4 {
  padding-bottom: 2rem;
}

.space-pl-4 {
  padding-left: 2rem;
}

.space-m-5 {
  margin: 2.5rem;
}

.space-mx-5 {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.space-my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.space-mt-5 {
  margin-top: 2.5rem;
}

.space-mr-5 {
  margin-right: 2.5rem;
}

.space-mb-5 {
  margin-bottom: 2.5rem;
}

.space-ml-5 {
  margin-left: 2.5rem;
}

.space-p-5 {
  padding: 2.5rem;
}

.space-px-5 {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.space-py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.space-pt-5 {
  padding-top: 2.5rem;
}

.space-pr-5 {
  padding-right: 2.5rem;
}

.space-pb-5 {
  padding-bottom: 2.5rem;
}

.space-pl-5 {
  padding-left: 2.5rem;
}

.space-m-6 {
  margin: 3rem;
}

.space-mx-6 {
  margin-right: 3rem;
  margin-left: 3rem;
}

.space-my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.space-mt-6 {
  margin-top: 3rem;
}

.space-mr-6 {
  margin-right: 3rem;
}

.space-mb-6 {
  margin-bottom: 3rem;
}

.space-ml-6 {
  margin-left: 3rem;
}

.space-p-6 {
  padding: 3rem;
}

.space-px-6 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.space-py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.space-pt-6 {
  padding-top: 3rem;
}

.space-pr-6 {
  padding-right: 3rem;
}

.space-pb-6 {
  padding-bottom: 3rem;
}

.space-pl-6 {
  padding-left: 3rem;
}

.space-m-7 {
  margin: 3.5rem;
}

.space-mx-7 {
  margin-right: 3.5rem;
  margin-left: 3.5rem;
}

.space-my-7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.space-mt-7 {
  margin-top: 3.5rem;
}

.space-mr-7 {
  margin-right: 3.5rem;
}

.space-mb-7 {
  margin-bottom: 3.5rem;
}

.space-ml-7 {
  margin-left: 3.5rem;
}

.space-p-7 {
  padding: 3.5rem;
}

.space-px-7 {
  padding-right: 3.5rem;
  padding-left: 3.5rem;
}

.space-py-7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.space-pt-7 {
  padding-top: 3.5rem;
}

.space-pr-7 {
  padding-right: 3.5rem;
}

.space-pb-7 {
  padding-bottom: 3.5rem;
}

.space-pl-7 {
  padding-left: 3.5rem;
}

.space-m-8 {
  margin: 4rem;
}

.space-mx-8 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.space-my-8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.space-mt-8 {
  margin-top: 4rem;
}

.space-mr-8 {
  margin-right: 4rem;
}

.space-mb-8 {
  margin-bottom: 4rem;
}

.space-ml-8 {
  margin-left: 4rem;
}

.space-p-8 {
  padding: 4rem;
}

.space-px-8 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.space-py-8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.space-pt-8 {
  padding-top: 4rem;
}

.space-pr-8 {
  padding-right: 4rem;
}

.space-pb-8 {
  padding-bottom: 4rem;
}

.space-pl-8 {
  padding-left: 4rem;
}

.space-m-9 {
  margin: 4.5rem;
}

.space-mx-9 {
  margin-right: 4.5rem;
  margin-left: 4.5rem;
}

.space-my-9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.space-mt-9 {
  margin-top: 4.5rem;
}

.space-mr-9 {
  margin-right: 4.5rem;
}

.space-mb-9 {
  margin-bottom: 4.5rem;
}

.space-ml-9 {
  margin-left: 4.5rem;
}

.space-p-9 {
  padding: 4.5rem;
}

.space-px-9 {
  padding-right: 4.5rem;
  padding-left: 4.5rem;
}

.space-py-9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.space-pt-9 {
  padding-top: 4.5rem;
}

.space-pr-9 {
  padding-right: 4.5rem;
}

.space-pb-9 {
  padding-bottom: 4.5rem;
}

.space-pl-9 {
  padding-left: 4.5rem;
}

.space-m-12 {
  margin: 6rem;
}

.space-mx-12 {
  margin-right: 6rem;
  margin-left: 6rem;
}

.space-my-12 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.space-mt-12 {
  margin-top: 6rem;
}

.space-mr-12 {
  margin-right: 6rem;
}

.space-mb-12 {
  margin-bottom: 6rem;
}

.space-ml-12 {
  margin-left: 6rem;
}

.space-p-12 {
  padding: 6rem;
}

.space-px-12 {
  padding-right: 6rem;
  padding-left: 6rem;
}

.space-py-12 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.space-pt-12 {
  padding-top: 6rem;
}

.space-pr-12 {
  padding-right: 6rem;
}

.space-pb-12 {
  padding-bottom: 6rem;
}

.space-pl-12 {
  padding-left: 6rem;
}

.space-m-16 {
  margin: 8rem;
}

.space-mx-16 {
  margin-right: 8rem;
  margin-left: 8rem;
}

.space-my-16 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.space-mt-16 {
  margin-top: 8rem;
}

.space-mr-16 {
  margin-right: 8rem;
}

.space-mb-16 {
  margin-bottom: 8rem;
}

.space-ml-16 {
  margin-left: 8rem;
}

.space-p-16 {
  padding: 8rem;
}

.space-px-16 {
  padding-right: 8rem;
  padding-left: 8rem;
}

.space-py-16 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.space-pt-16 {
  padding-top: 8rem;
}

.space-pr-16 {
  padding-right: 8rem;
}

.space-pb-16 {
  padding-bottom: 8rem;
}

.space-pl-16 {
  padding-left: 8rem;
}

.space-m-22 {
  margin: 11rem;
}

.space-mx-22 {
  margin-right: 11rem;
  margin-left: 11rem;
}

.space-my-22 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.space-mt-22 {
  margin-top: 11rem;
}

.space-mr-22 {
  margin-right: 11rem;
}

.space-mb-22 {
  margin-bottom: 11rem;
}

.space-ml-22 {
  margin-left: 11rem;
}

.space-p-22 {
  padding: 11rem;
}

.space-px-22 {
  padding-right: 11rem;
  padding-left: 11rem;
}

.space-py-22 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.space-pt-22 {
  padding-top: 11rem;
}

.space-pr-22 {
  padding-right: 11rem;
}

.space-pb-22 {
  padding-bottom: 11rem;
}

.space-pl-22 {
  padding-left: 11rem;
}

.space-m-27 {
  margin: 13.5rem;
}

.space-mx-27 {
  margin-right: 13.5rem;
  margin-left: 13.5rem;
}

.space-my-27 {
  margin-top: 13.5rem;
  margin-bottom: 13.5rem;
}

.space-mt-27 {
  margin-top: 13.5rem;
}

.space-mr-27 {
  margin-right: 13.5rem;
}

.space-mb-27 {
  margin-bottom: 13.5rem;
}

.space-ml-27 {
  margin-left: 13.5rem;
}

.space-p-27 {
  padding: 13.5rem;
}

.space-px-27 {
  padding-right: 13.5rem;
  padding-left: 13.5rem;
}

.space-py-27 {
  padding-top: 13.5rem;
  padding-bottom: 13.5rem;
}

.space-pt-27 {
  padding-top: 13.5rem;
}

.space-pr-27 {
  padding-right: 13.5rem;
}

.space-pb-27 {
  padding-bottom: 13.5rem;
}

.space-pl-27 {
  padding-left: 13.5rem;
}

.space-m-30 {
  margin: 15rem;
}

.space-mx-30 {
  margin-right: 15rem;
  margin-left: 15rem;
}

.space-my-30 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.space-mt-30 {
  margin-top: 15rem;
}

.space-mr-30 {
  margin-right: 15rem;
}

.space-mb-30 {
  margin-bottom: 15rem;
}

.space-ml-30 {
  margin-left: 15rem;
}

.space-p-30 {
  padding: 15rem;
}

.space-px-30 {
  padding-right: 15rem;
  padding-left: 15rem;
}

.space-py-30 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.space-pt-30 {
  padding-top: 15rem;
}

.space-pr-30 {
  padding-right: 15rem;
}

.space-pb-30 {
  padding-bottom: 15rem;
}

.space-pl-30 {
  padding-left: 15rem;
}

.space-m-46 {
  margin: 23rem;
}

.space-mx-46 {
  margin-right: 23rem;
  margin-left: 23rem;
}

.space-my-46 {
  margin-top: 23rem;
  margin-bottom: 23rem;
}

.space-mt-46 {
  margin-top: 23rem;
}

.space-mr-46 {
  margin-right: 23rem;
}

.space-mb-46 {
  margin-bottom: 23rem;
}

.space-ml-46 {
  margin-left: 23rem;
}

.space-p-46 {
  padding: 23rem;
}

.space-px-46 {
  padding-right: 23rem;
  padding-left: 23rem;
}

.space-py-46 {
  padding-top: 23rem;
  padding-bottom: 23rem;
}

.space-pt-46 {
  padding-top: 23rem;
}

.space-pr-46 {
  padding-right: 23rem;
}

.space-pb-46 {
  padding-bottom: 23rem;
}

.space-pl-46 {
  padding-left: 23rem;
}

.space-m-60 {
  margin: 30rem;
}

.space-mx-60 {
  margin-right: 30rem;
  margin-left: 30rem;
}

.space-my-60 {
  margin-top: 30rem;
  margin-bottom: 30rem;
}

.space-mt-60 {
  margin-top: 30rem;
}

.space-mr-60 {
  margin-right: 30rem;
}

.space-mb-60 {
  margin-bottom: 30rem;
}

.space-ml-60 {
  margin-left: 30rem;
}

.space-p-60 {
  padding: 30rem;
}

.space-px-60 {
  padding-right: 30rem;
  padding-left: 30rem;
}

.space-py-60 {
  padding-top: 30rem;
  padding-bottom: 30rem;
}

.space-pt-60 {
  padding-top: 30rem;
}

.space-pr-60 {
  padding-right: 30rem;
}

.space-pb-60 {
  padding-bottom: 30rem;
}

.space-pl-60 {
  padding-left: 30rem;
}

@media (min-width: 0em) {
  .space-m-0--xxs {
    margin: 0rem;
  }

  .space-mx-0--xxs {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xxs {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xxs {
    margin-top: 0rem;
  }

  .space-mr-0--xxs {
    margin-right: 0rem;
  }

  .space-mb-0--xxs {
    margin-bottom: 0rem;
  }

  .space-ml-0--xxs {
    margin-left: 0rem;
  }

  .space-p-0--xxs {
    padding: 0rem;
  }

  .space-px-0--xxs {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xxs {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xxs {
    padding-top: 0rem;
  }

  .space-pr-0--xxs {
    padding-right: 0rem;
  }

  .space-pb-0--xxs {
    padding-bottom: 0rem;
  }

  .space-pl-0--xxs {
    padding-left: 0rem;
  }

  .space-m-1--xxs {
    margin: 0.5rem;
  }

  .space-mx-1--xxs {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xxs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xxs {
    margin-top: 0.5rem;
  }

  .space-mr-1--xxs {
    margin-right: 0.5rem;
  }

  .space-mb-1--xxs {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xxs {
    margin-left: 0.5rem;
  }

  .space-p-1--xxs {
    padding: 0.5rem;
  }

  .space-px-1--xxs {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xxs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xxs {
    padding-top: 0.5rem;
  }

  .space-pr-1--xxs {
    padding-right: 0.5rem;
  }

  .space-pb-1--xxs {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xxs {
    padding-left: 0.5rem;
  }

  .space-m-2--xxs {
    margin: 1rem;
  }

  .space-mx-2--xxs {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xxs {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xxs {
    margin-top: 1rem;
  }

  .space-mr-2--xxs {
    margin-right: 1rem;
  }

  .space-mb-2--xxs {
    margin-bottom: 1rem;
  }

  .space-ml-2--xxs {
    margin-left: 1rem;
  }

  .space-p-2--xxs {
    padding: 1rem;
  }

  .space-px-2--xxs {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xxs {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xxs {
    padding-top: 1rem;
  }

  .space-pr-2--xxs {
    padding-right: 1rem;
  }

  .space-pb-2--xxs {
    padding-bottom: 1rem;
  }

  .space-pl-2--xxs {
    padding-left: 1rem;
  }

  .space-m-3--xxs {
    margin: 1.5rem;
  }

  .space-mx-3--xxs {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xxs {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xxs {
    margin-top: 1.5rem;
  }

  .space-mr-3--xxs {
    margin-right: 1.5rem;
  }

  .space-mb-3--xxs {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xxs {
    margin-left: 1.5rem;
  }

  .space-p-3--xxs {
    padding: 1.5rem;
  }

  .space-px-3--xxs {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xxs {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xxs {
    padding-top: 1.5rem;
  }

  .space-pr-3--xxs {
    padding-right: 1.5rem;
  }

  .space-pb-3--xxs {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xxs {
    padding-left: 1.5rem;
  }

  .space-m-4--xxs {
    margin: 2rem;
  }

  .space-mx-4--xxs {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xxs {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xxs {
    margin-top: 2rem;
  }

  .space-mr-4--xxs {
    margin-right: 2rem;
  }

  .space-mb-4--xxs {
    margin-bottom: 2rem;
  }

  .space-ml-4--xxs {
    margin-left: 2rem;
  }

  .space-p-4--xxs {
    padding: 2rem;
  }

  .space-px-4--xxs {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xxs {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xxs {
    padding-top: 2rem;
  }

  .space-pr-4--xxs {
    padding-right: 2rem;
  }

  .space-pb-4--xxs {
    padding-bottom: 2rem;
  }

  .space-pl-4--xxs {
    padding-left: 2rem;
  }

  .space-m-5--xxs {
    margin: 2.5rem;
  }

  .space-mx-5--xxs {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xxs {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xxs {
    margin-top: 2.5rem;
  }

  .space-mr-5--xxs {
    margin-right: 2.5rem;
  }

  .space-mb-5--xxs {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xxs {
    margin-left: 2.5rem;
  }

  .space-p-5--xxs {
    padding: 2.5rem;
  }

  .space-px-5--xxs {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xxs {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xxs {
    padding-top: 2.5rem;
  }

  .space-pr-5--xxs {
    padding-right: 2.5rem;
  }

  .space-pb-5--xxs {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xxs {
    padding-left: 2.5rem;
  }

  .space-m-6--xxs {
    margin: 3rem;
  }

  .space-mx-6--xxs {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xxs {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xxs {
    margin-top: 3rem;
  }

  .space-mr-6--xxs {
    margin-right: 3rem;
  }

  .space-mb-6--xxs {
    margin-bottom: 3rem;
  }

  .space-ml-6--xxs {
    margin-left: 3rem;
  }

  .space-p-6--xxs {
    padding: 3rem;
  }

  .space-px-6--xxs {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xxs {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xxs {
    padding-top: 3rem;
  }

  .space-pr-6--xxs {
    padding-right: 3rem;
  }

  .space-pb-6--xxs {
    padding-bottom: 3rem;
  }

  .space-pl-6--xxs {
    padding-left: 3rem;
  }

  .space-m-7--xxs {
    margin: 3.5rem;
  }

  .space-mx-7--xxs {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xxs {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xxs {
    margin-top: 3.5rem;
  }

  .space-mr-7--xxs {
    margin-right: 3.5rem;
  }

  .space-mb-7--xxs {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xxs {
    margin-left: 3.5rem;
  }

  .space-p-7--xxs {
    padding: 3.5rem;
  }

  .space-px-7--xxs {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xxs {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xxs {
    padding-top: 3.5rem;
  }

  .space-pr-7--xxs {
    padding-right: 3.5rem;
  }

  .space-pb-7--xxs {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xxs {
    padding-left: 3.5rem;
  }

  .space-m-8--xxs {
    margin: 4rem;
  }

  .space-mx-8--xxs {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xxs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xxs {
    margin-top: 4rem;
  }

  .space-mr-8--xxs {
    margin-right: 4rem;
  }

  .space-mb-8--xxs {
    margin-bottom: 4rem;
  }

  .space-ml-8--xxs {
    margin-left: 4rem;
  }

  .space-p-8--xxs {
    padding: 4rem;
  }

  .space-px-8--xxs {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xxs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xxs {
    padding-top: 4rem;
  }

  .space-pr-8--xxs {
    padding-right: 4rem;
  }

  .space-pb-8--xxs {
    padding-bottom: 4rem;
  }

  .space-pl-8--xxs {
    padding-left: 4rem;
  }

  .space-m-9--xxs {
    margin: 4.5rem;
  }

  .space-mx-9--xxs {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xxs {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xxs {
    margin-top: 4.5rem;
  }

  .space-mr-9--xxs {
    margin-right: 4.5rem;
  }

  .space-mb-9--xxs {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xxs {
    margin-left: 4.5rem;
  }

  .space-p-9--xxs {
    padding: 4.5rem;
  }

  .space-px-9--xxs {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xxs {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xxs {
    padding-top: 4.5rem;
  }

  .space-pr-9--xxs {
    padding-right: 4.5rem;
  }

  .space-pb-9--xxs {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xxs {
    padding-left: 4.5rem;
  }

  .space-m-12--xxs {
    margin: 6rem;
  }

  .space-mx-12--xxs {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xxs {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xxs {
    margin-top: 6rem;
  }

  .space-mr-12--xxs {
    margin-right: 6rem;
  }

  .space-mb-12--xxs {
    margin-bottom: 6rem;
  }

  .space-ml-12--xxs {
    margin-left: 6rem;
  }

  .space-p-12--xxs {
    padding: 6rem;
  }

  .space-px-12--xxs {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xxs {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xxs {
    padding-top: 6rem;
  }

  .space-pr-12--xxs {
    padding-right: 6rem;
  }

  .space-pb-12--xxs {
    padding-bottom: 6rem;
  }

  .space-pl-12--xxs {
    padding-left: 6rem;
  }

  .space-m-16--xxs {
    margin: 8rem;
  }

  .space-mx-16--xxs {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xxs {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xxs {
    margin-top: 8rem;
  }

  .space-mr-16--xxs {
    margin-right: 8rem;
  }

  .space-mb-16--xxs {
    margin-bottom: 8rem;
  }

  .space-ml-16--xxs {
    margin-left: 8rem;
  }

  .space-p-16--xxs {
    padding: 8rem;
  }

  .space-px-16--xxs {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xxs {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xxs {
    padding-top: 8rem;
  }

  .space-pr-16--xxs {
    padding-right: 8rem;
  }

  .space-pb-16--xxs {
    padding-bottom: 8rem;
  }

  .space-pl-16--xxs {
    padding-left: 8rem;
  }

  .space-m-22--xxs {
    margin: 11rem;
  }

  .space-mx-22--xxs {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xxs {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xxs {
    margin-top: 11rem;
  }

  .space-mr-22--xxs {
    margin-right: 11rem;
  }

  .space-mb-22--xxs {
    margin-bottom: 11rem;
  }

  .space-ml-22--xxs {
    margin-left: 11rem;
  }

  .space-p-22--xxs {
    padding: 11rem;
  }

  .space-px-22--xxs {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xxs {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xxs {
    padding-top: 11rem;
  }

  .space-pr-22--xxs {
    padding-right: 11rem;
  }

  .space-pb-22--xxs {
    padding-bottom: 11rem;
  }

  .space-pl-22--xxs {
    padding-left: 11rem;
  }

  .space-m-27--xxs {
    margin: 13.5rem;
  }

  .space-mx-27--xxs {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xxs {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xxs {
    margin-top: 13.5rem;
  }

  .space-mr-27--xxs {
    margin-right: 13.5rem;
  }

  .space-mb-27--xxs {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xxs {
    margin-left: 13.5rem;
  }

  .space-p-27--xxs {
    padding: 13.5rem;
  }

  .space-px-27--xxs {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xxs {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xxs {
    padding-top: 13.5rem;
  }

  .space-pr-27--xxs {
    padding-right: 13.5rem;
  }

  .space-pb-27--xxs {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xxs {
    padding-left: 13.5rem;
  }

  .space-m-30--xxs {
    margin: 15rem;
  }

  .space-mx-30--xxs {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xxs {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xxs {
    margin-top: 15rem;
  }

  .space-mr-30--xxs {
    margin-right: 15rem;
  }

  .space-mb-30--xxs {
    margin-bottom: 15rem;
  }

  .space-ml-30--xxs {
    margin-left: 15rem;
  }

  .space-p-30--xxs {
    padding: 15rem;
  }

  .space-px-30--xxs {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xxs {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xxs {
    padding-top: 15rem;
  }

  .space-pr-30--xxs {
    padding-right: 15rem;
  }

  .space-pb-30--xxs {
    padding-bottom: 15rem;
  }

  .space-pl-30--xxs {
    padding-left: 15rem;
  }

  .space-m-46--xxs {
    margin: 23rem;
  }

  .space-mx-46--xxs {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xxs {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xxs {
    margin-top: 23rem;
  }

  .space-mr-46--xxs {
    margin-right: 23rem;
  }

  .space-mb-46--xxs {
    margin-bottom: 23rem;
  }

  .space-ml-46--xxs {
    margin-left: 23rem;
  }

  .space-p-46--xxs {
    padding: 23rem;
  }

  .space-px-46--xxs {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xxs {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xxs {
    padding-top: 23rem;
  }

  .space-pr-46--xxs {
    padding-right: 23rem;
  }

  .space-pb-46--xxs {
    padding-bottom: 23rem;
  }

  .space-pl-46--xxs {
    padding-left: 23rem;
  }

  .space-m-60--xxs {
    margin: 30rem;
  }

  .space-mx-60--xxs {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xxs {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xxs {
    margin-top: 30rem;
  }

  .space-mr-60--xxs {
    margin-right: 30rem;
  }

  .space-mb-60--xxs {
    margin-bottom: 30rem;
  }

  .space-ml-60--xxs {
    margin-left: 30rem;
  }

  .space-p-60--xxs {
    padding: 30rem;
  }

  .space-px-60--xxs {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xxs {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xxs {
    padding-top: 30rem;
  }

  .space-pr-60--xxs {
    padding-right: 30rem;
  }

  .space-pb-60--xxs {
    padding-bottom: 30rem;
  }

  .space-pl-60--xxs {
    padding-left: 30rem;
  }
}
@media (min-width: 30em) {
  .space-m-0--xs {
    margin: 0rem;
  }

  .space-mx-0--xs {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xs {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xs {
    margin-top: 0rem;
  }

  .space-mr-0--xs {
    margin-right: 0rem;
  }

  .space-mb-0--xs {
    margin-bottom: 0rem;
  }

  .space-ml-0--xs {
    margin-left: 0rem;
  }

  .space-p-0--xs {
    padding: 0rem;
  }

  .space-px-0--xs {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xs {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xs {
    padding-top: 0rem;
  }

  .space-pr-0--xs {
    padding-right: 0rem;
  }

  .space-pb-0--xs {
    padding-bottom: 0rem;
  }

  .space-pl-0--xs {
    padding-left: 0rem;
  }

  .space-m-1--xs {
    margin: 0.5rem;
  }

  .space-mx-1--xs {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xs {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xs {
    margin-top: 0.5rem;
  }

  .space-mr-1--xs {
    margin-right: 0.5rem;
  }

  .space-mb-1--xs {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xs {
    margin-left: 0.5rem;
  }

  .space-p-1--xs {
    padding: 0.5rem;
  }

  .space-px-1--xs {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xs {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xs {
    padding-top: 0.5rem;
  }

  .space-pr-1--xs {
    padding-right: 0.5rem;
  }

  .space-pb-1--xs {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xs {
    padding-left: 0.5rem;
  }

  .space-m-2--xs {
    margin: 1rem;
  }

  .space-mx-2--xs {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xs {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xs {
    margin-top: 1rem;
  }

  .space-mr-2--xs {
    margin-right: 1rem;
  }

  .space-mb-2--xs {
    margin-bottom: 1rem;
  }

  .space-ml-2--xs {
    margin-left: 1rem;
  }

  .space-p-2--xs {
    padding: 1rem;
  }

  .space-px-2--xs {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xs {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xs {
    padding-top: 1rem;
  }

  .space-pr-2--xs {
    padding-right: 1rem;
  }

  .space-pb-2--xs {
    padding-bottom: 1rem;
  }

  .space-pl-2--xs {
    padding-left: 1rem;
  }

  .space-m-3--xs {
    margin: 1.5rem;
  }

  .space-mx-3--xs {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xs {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xs {
    margin-top: 1.5rem;
  }

  .space-mr-3--xs {
    margin-right: 1.5rem;
  }

  .space-mb-3--xs {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xs {
    margin-left: 1.5rem;
  }

  .space-p-3--xs {
    padding: 1.5rem;
  }

  .space-px-3--xs {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xs {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xs {
    padding-top: 1.5rem;
  }

  .space-pr-3--xs {
    padding-right: 1.5rem;
  }

  .space-pb-3--xs {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xs {
    padding-left: 1.5rem;
  }

  .space-m-4--xs {
    margin: 2rem;
  }

  .space-mx-4--xs {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xs {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xs {
    margin-top: 2rem;
  }

  .space-mr-4--xs {
    margin-right: 2rem;
  }

  .space-mb-4--xs {
    margin-bottom: 2rem;
  }

  .space-ml-4--xs {
    margin-left: 2rem;
  }

  .space-p-4--xs {
    padding: 2rem;
  }

  .space-px-4--xs {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xs {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xs {
    padding-top: 2rem;
  }

  .space-pr-4--xs {
    padding-right: 2rem;
  }

  .space-pb-4--xs {
    padding-bottom: 2rem;
  }

  .space-pl-4--xs {
    padding-left: 2rem;
  }

  .space-m-5--xs {
    margin: 2.5rem;
  }

  .space-mx-5--xs {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xs {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xs {
    margin-top: 2.5rem;
  }

  .space-mr-5--xs {
    margin-right: 2.5rem;
  }

  .space-mb-5--xs {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xs {
    margin-left: 2.5rem;
  }

  .space-p-5--xs {
    padding: 2.5rem;
  }

  .space-px-5--xs {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xs {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xs {
    padding-top: 2.5rem;
  }

  .space-pr-5--xs {
    padding-right: 2.5rem;
  }

  .space-pb-5--xs {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xs {
    padding-left: 2.5rem;
  }

  .space-m-6--xs {
    margin: 3rem;
  }

  .space-mx-6--xs {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xs {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xs {
    margin-top: 3rem;
  }

  .space-mr-6--xs {
    margin-right: 3rem;
  }

  .space-mb-6--xs {
    margin-bottom: 3rem;
  }

  .space-ml-6--xs {
    margin-left: 3rem;
  }

  .space-p-6--xs {
    padding: 3rem;
  }

  .space-px-6--xs {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xs {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xs {
    padding-top: 3rem;
  }

  .space-pr-6--xs {
    padding-right: 3rem;
  }

  .space-pb-6--xs {
    padding-bottom: 3rem;
  }

  .space-pl-6--xs {
    padding-left: 3rem;
  }

  .space-m-7--xs {
    margin: 3.5rem;
  }

  .space-mx-7--xs {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xs {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xs {
    margin-top: 3.5rem;
  }

  .space-mr-7--xs {
    margin-right: 3.5rem;
  }

  .space-mb-7--xs {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xs {
    margin-left: 3.5rem;
  }

  .space-p-7--xs {
    padding: 3.5rem;
  }

  .space-px-7--xs {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xs {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xs {
    padding-top: 3.5rem;
  }

  .space-pr-7--xs {
    padding-right: 3.5rem;
  }

  .space-pb-7--xs {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xs {
    padding-left: 3.5rem;
  }

  .space-m-8--xs {
    margin: 4rem;
  }

  .space-mx-8--xs {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xs {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xs {
    margin-top: 4rem;
  }

  .space-mr-8--xs {
    margin-right: 4rem;
  }

  .space-mb-8--xs {
    margin-bottom: 4rem;
  }

  .space-ml-8--xs {
    margin-left: 4rem;
  }

  .space-p-8--xs {
    padding: 4rem;
  }

  .space-px-8--xs {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xs {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xs {
    padding-top: 4rem;
  }

  .space-pr-8--xs {
    padding-right: 4rem;
  }

  .space-pb-8--xs {
    padding-bottom: 4rem;
  }

  .space-pl-8--xs {
    padding-left: 4rem;
  }

  .space-m-9--xs {
    margin: 4.5rem;
  }

  .space-mx-9--xs {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xs {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xs {
    margin-top: 4.5rem;
  }

  .space-mr-9--xs {
    margin-right: 4.5rem;
  }

  .space-mb-9--xs {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xs {
    margin-left: 4.5rem;
  }

  .space-p-9--xs {
    padding: 4.5rem;
  }

  .space-px-9--xs {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xs {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xs {
    padding-top: 4.5rem;
  }

  .space-pr-9--xs {
    padding-right: 4.5rem;
  }

  .space-pb-9--xs {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xs {
    padding-left: 4.5rem;
  }

  .space-m-12--xs {
    margin: 6rem;
  }

  .space-mx-12--xs {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xs {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xs {
    margin-top: 6rem;
  }

  .space-mr-12--xs {
    margin-right: 6rem;
  }

  .space-mb-12--xs {
    margin-bottom: 6rem;
  }

  .space-ml-12--xs {
    margin-left: 6rem;
  }

  .space-p-12--xs {
    padding: 6rem;
  }

  .space-px-12--xs {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xs {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xs {
    padding-top: 6rem;
  }

  .space-pr-12--xs {
    padding-right: 6rem;
  }

  .space-pb-12--xs {
    padding-bottom: 6rem;
  }

  .space-pl-12--xs {
    padding-left: 6rem;
  }

  .space-m-16--xs {
    margin: 8rem;
  }

  .space-mx-16--xs {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xs {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xs {
    margin-top: 8rem;
  }

  .space-mr-16--xs {
    margin-right: 8rem;
  }

  .space-mb-16--xs {
    margin-bottom: 8rem;
  }

  .space-ml-16--xs {
    margin-left: 8rem;
  }

  .space-p-16--xs {
    padding: 8rem;
  }

  .space-px-16--xs {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xs {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xs {
    padding-top: 8rem;
  }

  .space-pr-16--xs {
    padding-right: 8rem;
  }

  .space-pb-16--xs {
    padding-bottom: 8rem;
  }

  .space-pl-16--xs {
    padding-left: 8rem;
  }

  .space-m-22--xs {
    margin: 11rem;
  }

  .space-mx-22--xs {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xs {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xs {
    margin-top: 11rem;
  }

  .space-mr-22--xs {
    margin-right: 11rem;
  }

  .space-mb-22--xs {
    margin-bottom: 11rem;
  }

  .space-ml-22--xs {
    margin-left: 11rem;
  }

  .space-p-22--xs {
    padding: 11rem;
  }

  .space-px-22--xs {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xs {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xs {
    padding-top: 11rem;
  }

  .space-pr-22--xs {
    padding-right: 11rem;
  }

  .space-pb-22--xs {
    padding-bottom: 11rem;
  }

  .space-pl-22--xs {
    padding-left: 11rem;
  }

  .space-m-27--xs {
    margin: 13.5rem;
  }

  .space-mx-27--xs {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xs {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xs {
    margin-top: 13.5rem;
  }

  .space-mr-27--xs {
    margin-right: 13.5rem;
  }

  .space-mb-27--xs {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xs {
    margin-left: 13.5rem;
  }

  .space-p-27--xs {
    padding: 13.5rem;
  }

  .space-px-27--xs {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xs {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xs {
    padding-top: 13.5rem;
  }

  .space-pr-27--xs {
    padding-right: 13.5rem;
  }

  .space-pb-27--xs {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xs {
    padding-left: 13.5rem;
  }

  .space-m-30--xs {
    margin: 15rem;
  }

  .space-mx-30--xs {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xs {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xs {
    margin-top: 15rem;
  }

  .space-mr-30--xs {
    margin-right: 15rem;
  }

  .space-mb-30--xs {
    margin-bottom: 15rem;
  }

  .space-ml-30--xs {
    margin-left: 15rem;
  }

  .space-p-30--xs {
    padding: 15rem;
  }

  .space-px-30--xs {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xs {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xs {
    padding-top: 15rem;
  }

  .space-pr-30--xs {
    padding-right: 15rem;
  }

  .space-pb-30--xs {
    padding-bottom: 15rem;
  }

  .space-pl-30--xs {
    padding-left: 15rem;
  }

  .space-m-46--xs {
    margin: 23rem;
  }

  .space-mx-46--xs {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xs {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xs {
    margin-top: 23rem;
  }

  .space-mr-46--xs {
    margin-right: 23rem;
  }

  .space-mb-46--xs {
    margin-bottom: 23rem;
  }

  .space-ml-46--xs {
    margin-left: 23rem;
  }

  .space-p-46--xs {
    padding: 23rem;
  }

  .space-px-46--xs {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xs {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xs {
    padding-top: 23rem;
  }

  .space-pr-46--xs {
    padding-right: 23rem;
  }

  .space-pb-46--xs {
    padding-bottom: 23rem;
  }

  .space-pl-46--xs {
    padding-left: 23rem;
  }

  .space-m-60--xs {
    margin: 30rem;
  }

  .space-mx-60--xs {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xs {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xs {
    margin-top: 30rem;
  }

  .space-mr-60--xs {
    margin-right: 30rem;
  }

  .space-mb-60--xs {
    margin-bottom: 30rem;
  }

  .space-ml-60--xs {
    margin-left: 30rem;
  }

  .space-p-60--xs {
    padding: 30rem;
  }

  .space-px-60--xs {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xs {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xs {
    padding-top: 30rem;
  }

  .space-pr-60--xs {
    padding-right: 30rem;
  }

  .space-pb-60--xs {
    padding-bottom: 30rem;
  }

  .space-pl-60--xs {
    padding-left: 30rem;
  }
}
@media (min-width: 48em) {
  .space-m-0--s {
    margin: 0rem;
  }

  .space-mx-0--s {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--s {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--s {
    margin-top: 0rem;
  }

  .space-mr-0--s {
    margin-right: 0rem;
  }

  .space-mb-0--s {
    margin-bottom: 0rem;
  }

  .space-ml-0--s {
    margin-left: 0rem;
  }

  .space-p-0--s {
    padding: 0rem;
  }

  .space-px-0--s {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--s {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--s {
    padding-top: 0rem;
  }

  .space-pr-0--s {
    padding-right: 0rem;
  }

  .space-pb-0--s {
    padding-bottom: 0rem;
  }

  .space-pl-0--s {
    padding-left: 0rem;
  }

  .space-m-1--s {
    margin: 0.5rem;
  }

  .space-mx-1--s {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--s {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--s {
    margin-top: 0.5rem;
  }

  .space-mr-1--s {
    margin-right: 0.5rem;
  }

  .space-mb-1--s {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--s {
    margin-left: 0.5rem;
  }

  .space-p-1--s {
    padding: 0.5rem;
  }

  .space-px-1--s {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--s {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--s {
    padding-top: 0.5rem;
  }

  .space-pr-1--s {
    padding-right: 0.5rem;
  }

  .space-pb-1--s {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--s {
    padding-left: 0.5rem;
  }

  .space-m-2--s {
    margin: 1rem;
  }

  .space-mx-2--s {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--s {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--s {
    margin-top: 1rem;
  }

  .space-mr-2--s {
    margin-right: 1rem;
  }

  .space-mb-2--s {
    margin-bottom: 1rem;
  }

  .space-ml-2--s {
    margin-left: 1rem;
  }

  .space-p-2--s {
    padding: 1rem;
  }

  .space-px-2--s {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--s {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--s {
    padding-top: 1rem;
  }

  .space-pr-2--s {
    padding-right: 1rem;
  }

  .space-pb-2--s {
    padding-bottom: 1rem;
  }

  .space-pl-2--s {
    padding-left: 1rem;
  }

  .space-m-3--s {
    margin: 1.5rem;
  }

  .space-mx-3--s {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--s {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--s {
    margin-top: 1.5rem;
  }

  .space-mr-3--s {
    margin-right: 1.5rem;
  }

  .space-mb-3--s {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--s {
    margin-left: 1.5rem;
  }

  .space-p-3--s {
    padding: 1.5rem;
  }

  .space-px-3--s {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--s {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--s {
    padding-top: 1.5rem;
  }

  .space-pr-3--s {
    padding-right: 1.5rem;
  }

  .space-pb-3--s {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--s {
    padding-left: 1.5rem;
  }

  .space-m-4--s {
    margin: 2rem;
  }

  .space-mx-4--s {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--s {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--s {
    margin-top: 2rem;
  }

  .space-mr-4--s {
    margin-right: 2rem;
  }

  .space-mb-4--s {
    margin-bottom: 2rem;
  }

  .space-ml-4--s {
    margin-left: 2rem;
  }

  .space-p-4--s {
    padding: 2rem;
  }

  .space-px-4--s {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--s {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--s {
    padding-top: 2rem;
  }

  .space-pr-4--s {
    padding-right: 2rem;
  }

  .space-pb-4--s {
    padding-bottom: 2rem;
  }

  .space-pl-4--s {
    padding-left: 2rem;
  }

  .space-m-5--s {
    margin: 2.5rem;
  }

  .space-mx-5--s {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--s {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--s {
    margin-top: 2.5rem;
  }

  .space-mr-5--s {
    margin-right: 2.5rem;
  }

  .space-mb-5--s {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--s {
    margin-left: 2.5rem;
  }

  .space-p-5--s {
    padding: 2.5rem;
  }

  .space-px-5--s {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--s {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--s {
    padding-top: 2.5rem;
  }

  .space-pr-5--s {
    padding-right: 2.5rem;
  }

  .space-pb-5--s {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--s {
    padding-left: 2.5rem;
  }

  .space-m-6--s {
    margin: 3rem;
  }

  .space-mx-6--s {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--s {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--s {
    margin-top: 3rem;
  }

  .space-mr-6--s {
    margin-right: 3rem;
  }

  .space-mb-6--s {
    margin-bottom: 3rem;
  }

  .space-ml-6--s {
    margin-left: 3rem;
  }

  .space-p-6--s {
    padding: 3rem;
  }

  .space-px-6--s {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--s {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--s {
    padding-top: 3rem;
  }

  .space-pr-6--s {
    padding-right: 3rem;
  }

  .space-pb-6--s {
    padding-bottom: 3rem;
  }

  .space-pl-6--s {
    padding-left: 3rem;
  }

  .space-m-7--s {
    margin: 3.5rem;
  }

  .space-mx-7--s {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--s {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--s {
    margin-top: 3.5rem;
  }

  .space-mr-7--s {
    margin-right: 3.5rem;
  }

  .space-mb-7--s {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--s {
    margin-left: 3.5rem;
  }

  .space-p-7--s {
    padding: 3.5rem;
  }

  .space-px-7--s {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--s {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--s {
    padding-top: 3.5rem;
  }

  .space-pr-7--s {
    padding-right: 3.5rem;
  }

  .space-pb-7--s {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--s {
    padding-left: 3.5rem;
  }

  .space-m-8--s {
    margin: 4rem;
  }

  .space-mx-8--s {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--s {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--s {
    margin-top: 4rem;
  }

  .space-mr-8--s {
    margin-right: 4rem;
  }

  .space-mb-8--s {
    margin-bottom: 4rem;
  }

  .space-ml-8--s {
    margin-left: 4rem;
  }

  .space-p-8--s {
    padding: 4rem;
  }

  .space-px-8--s {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--s {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--s {
    padding-top: 4rem;
  }

  .space-pr-8--s {
    padding-right: 4rem;
  }

  .space-pb-8--s {
    padding-bottom: 4rem;
  }

  .space-pl-8--s {
    padding-left: 4rem;
  }

  .space-m-9--s {
    margin: 4.5rem;
  }

  .space-mx-9--s {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--s {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--s {
    margin-top: 4.5rem;
  }

  .space-mr-9--s {
    margin-right: 4.5rem;
  }

  .space-mb-9--s {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--s {
    margin-left: 4.5rem;
  }

  .space-p-9--s {
    padding: 4.5rem;
  }

  .space-px-9--s {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--s {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--s {
    padding-top: 4.5rem;
  }

  .space-pr-9--s {
    padding-right: 4.5rem;
  }

  .space-pb-9--s {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--s {
    padding-left: 4.5rem;
  }

  .space-m-12--s {
    margin: 6rem;
  }

  .space-mx-12--s {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--s {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--s {
    margin-top: 6rem;
  }

  .space-mr-12--s {
    margin-right: 6rem;
  }

  .space-mb-12--s {
    margin-bottom: 6rem;
  }

  .space-ml-12--s {
    margin-left: 6rem;
  }

  .space-p-12--s {
    padding: 6rem;
  }

  .space-px-12--s {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--s {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--s {
    padding-top: 6rem;
  }

  .space-pr-12--s {
    padding-right: 6rem;
  }

  .space-pb-12--s {
    padding-bottom: 6rem;
  }

  .space-pl-12--s {
    padding-left: 6rem;
  }

  .space-m-16--s {
    margin: 8rem;
  }

  .space-mx-16--s {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--s {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--s {
    margin-top: 8rem;
  }

  .space-mr-16--s {
    margin-right: 8rem;
  }

  .space-mb-16--s {
    margin-bottom: 8rem;
  }

  .space-ml-16--s {
    margin-left: 8rem;
  }

  .space-p-16--s {
    padding: 8rem;
  }

  .space-px-16--s {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--s {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--s {
    padding-top: 8rem;
  }

  .space-pr-16--s {
    padding-right: 8rem;
  }

  .space-pb-16--s {
    padding-bottom: 8rem;
  }

  .space-pl-16--s {
    padding-left: 8rem;
  }

  .space-m-22--s {
    margin: 11rem;
  }

  .space-mx-22--s {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--s {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--s {
    margin-top: 11rem;
  }

  .space-mr-22--s {
    margin-right: 11rem;
  }

  .space-mb-22--s {
    margin-bottom: 11rem;
  }

  .space-ml-22--s {
    margin-left: 11rem;
  }

  .space-p-22--s {
    padding: 11rem;
  }

  .space-px-22--s {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--s {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--s {
    padding-top: 11rem;
  }

  .space-pr-22--s {
    padding-right: 11rem;
  }

  .space-pb-22--s {
    padding-bottom: 11rem;
  }

  .space-pl-22--s {
    padding-left: 11rem;
  }

  .space-m-27--s {
    margin: 13.5rem;
  }

  .space-mx-27--s {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--s {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--s {
    margin-top: 13.5rem;
  }

  .space-mr-27--s {
    margin-right: 13.5rem;
  }

  .space-mb-27--s {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--s {
    margin-left: 13.5rem;
  }

  .space-p-27--s {
    padding: 13.5rem;
  }

  .space-px-27--s {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--s {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--s {
    padding-top: 13.5rem;
  }

  .space-pr-27--s {
    padding-right: 13.5rem;
  }

  .space-pb-27--s {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--s {
    padding-left: 13.5rem;
  }

  .space-m-30--s {
    margin: 15rem;
  }

  .space-mx-30--s {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--s {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--s {
    margin-top: 15rem;
  }

  .space-mr-30--s {
    margin-right: 15rem;
  }

  .space-mb-30--s {
    margin-bottom: 15rem;
  }

  .space-ml-30--s {
    margin-left: 15rem;
  }

  .space-p-30--s {
    padding: 15rem;
  }

  .space-px-30--s {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--s {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--s {
    padding-top: 15rem;
  }

  .space-pr-30--s {
    padding-right: 15rem;
  }

  .space-pb-30--s {
    padding-bottom: 15rem;
  }

  .space-pl-30--s {
    padding-left: 15rem;
  }

  .space-m-46--s {
    margin: 23rem;
  }

  .space-mx-46--s {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--s {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--s {
    margin-top: 23rem;
  }

  .space-mr-46--s {
    margin-right: 23rem;
  }

  .space-mb-46--s {
    margin-bottom: 23rem;
  }

  .space-ml-46--s {
    margin-left: 23rem;
  }

  .space-p-46--s {
    padding: 23rem;
  }

  .space-px-46--s {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--s {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--s {
    padding-top: 23rem;
  }

  .space-pr-46--s {
    padding-right: 23rem;
  }

  .space-pb-46--s {
    padding-bottom: 23rem;
  }

  .space-pl-46--s {
    padding-left: 23rem;
  }

  .space-m-60--s {
    margin: 30rem;
  }

  .space-mx-60--s {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--s {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--s {
    margin-top: 30rem;
  }

  .space-mr-60--s {
    margin-right: 30rem;
  }

  .space-mb-60--s {
    margin-bottom: 30rem;
  }

  .space-ml-60--s {
    margin-left: 30rem;
  }

  .space-p-60--s {
    padding: 30rem;
  }

  .space-px-60--s {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--s {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--s {
    padding-top: 30rem;
  }

  .space-pr-60--s {
    padding-right: 30rem;
  }

  .space-pb-60--s {
    padding-bottom: 30rem;
  }

  .space-pl-60--s {
    padding-left: 30rem;
  }
}
@media (min-width: 64em) {
  .space-m-0--m {
    margin: 0rem;
  }

  .space-mx-0--m {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--m {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--m {
    margin-top: 0rem;
  }

  .space-mr-0--m {
    margin-right: 0rem;
  }

  .space-mb-0--m {
    margin-bottom: 0rem;
  }

  .space-ml-0--m {
    margin-left: 0rem;
  }

  .space-p-0--m {
    padding: 0rem;
  }

  .space-px-0--m {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--m {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--m {
    padding-top: 0rem;
  }

  .space-pr-0--m {
    padding-right: 0rem;
  }

  .space-pb-0--m {
    padding-bottom: 0rem;
  }

  .space-pl-0--m {
    padding-left: 0rem;
  }

  .space-m-1--m {
    margin: 0.5rem;
  }

  .space-mx-1--m {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--m {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--m {
    margin-top: 0.5rem;
  }

  .space-mr-1--m {
    margin-right: 0.5rem;
  }

  .space-mb-1--m {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--m {
    margin-left: 0.5rem;
  }

  .space-p-1--m {
    padding: 0.5rem;
  }

  .space-px-1--m {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--m {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--m {
    padding-top: 0.5rem;
  }

  .space-pr-1--m {
    padding-right: 0.5rem;
  }

  .space-pb-1--m {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--m {
    padding-left: 0.5rem;
  }

  .space-m-2--m {
    margin: 1rem;
  }

  .space-mx-2--m {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--m {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--m {
    margin-top: 1rem;
  }

  .space-mr-2--m {
    margin-right: 1rem;
  }

  .space-mb-2--m {
    margin-bottom: 1rem;
  }

  .space-ml-2--m {
    margin-left: 1rem;
  }

  .space-p-2--m {
    padding: 1rem;
  }

  .space-px-2--m {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--m {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--m {
    padding-top: 1rem;
  }

  .space-pr-2--m {
    padding-right: 1rem;
  }

  .space-pb-2--m {
    padding-bottom: 1rem;
  }

  .space-pl-2--m {
    padding-left: 1rem;
  }

  .space-m-3--m {
    margin: 1.5rem;
  }

  .space-mx-3--m {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--m {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--m {
    margin-top: 1.5rem;
  }

  .space-mr-3--m {
    margin-right: 1.5rem;
  }

  .space-mb-3--m {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--m {
    margin-left: 1.5rem;
  }

  .space-p-3--m {
    padding: 1.5rem;
  }

  .space-px-3--m {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--m {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--m {
    padding-top: 1.5rem;
  }

  .space-pr-3--m {
    padding-right: 1.5rem;
  }

  .space-pb-3--m {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--m {
    padding-left: 1.5rem;
  }

  .space-m-4--m {
    margin: 2rem;
  }

  .space-mx-4--m {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--m {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--m {
    margin-top: 2rem;
  }

  .space-mr-4--m {
    margin-right: 2rem;
  }

  .space-mb-4--m {
    margin-bottom: 2rem;
  }

  .space-ml-4--m {
    margin-left: 2rem;
  }

  .space-p-4--m {
    padding: 2rem;
  }

  .space-px-4--m {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--m {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--m {
    padding-top: 2rem;
  }

  .space-pr-4--m {
    padding-right: 2rem;
  }

  .space-pb-4--m {
    padding-bottom: 2rem;
  }

  .space-pl-4--m {
    padding-left: 2rem;
  }

  .space-m-5--m {
    margin: 2.5rem;
  }

  .space-mx-5--m {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--m {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--m {
    margin-top: 2.5rem;
  }

  .space-mr-5--m {
    margin-right: 2.5rem;
  }

  .space-mb-5--m {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--m {
    margin-left: 2.5rem;
  }

  .space-p-5--m {
    padding: 2.5rem;
  }

  .space-px-5--m {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--m {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--m {
    padding-top: 2.5rem;
  }

  .space-pr-5--m {
    padding-right: 2.5rem;
  }

  .space-pb-5--m {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--m {
    padding-left: 2.5rem;
  }

  .space-m-6--m {
    margin: 3rem;
  }

  .space-mx-6--m {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--m {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--m {
    margin-top: 3rem;
  }

  .space-mr-6--m {
    margin-right: 3rem;
  }

  .space-mb-6--m {
    margin-bottom: 3rem;
  }

  .space-ml-6--m {
    margin-left: 3rem;
  }

  .space-p-6--m {
    padding: 3rem;
  }

  .space-px-6--m {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--m {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--m {
    padding-top: 3rem;
  }

  .space-pr-6--m {
    padding-right: 3rem;
  }

  .space-pb-6--m {
    padding-bottom: 3rem;
  }

  .space-pl-6--m {
    padding-left: 3rem;
  }

  .space-m-7--m {
    margin: 3.5rem;
  }

  .space-mx-7--m {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--m {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--m {
    margin-top: 3.5rem;
  }

  .space-mr-7--m {
    margin-right: 3.5rem;
  }

  .space-mb-7--m {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--m {
    margin-left: 3.5rem;
  }

  .space-p-7--m {
    padding: 3.5rem;
  }

  .space-px-7--m {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--m {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--m {
    padding-top: 3.5rem;
  }

  .space-pr-7--m {
    padding-right: 3.5rem;
  }

  .space-pb-7--m {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--m {
    padding-left: 3.5rem;
  }

  .space-m-8--m {
    margin: 4rem;
  }

  .space-mx-8--m {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--m {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--m {
    margin-top: 4rem;
  }

  .space-mr-8--m {
    margin-right: 4rem;
  }

  .space-mb-8--m {
    margin-bottom: 4rem;
  }

  .space-ml-8--m {
    margin-left: 4rem;
  }

  .space-p-8--m {
    padding: 4rem;
  }

  .space-px-8--m {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--m {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--m {
    padding-top: 4rem;
  }

  .space-pr-8--m {
    padding-right: 4rem;
  }

  .space-pb-8--m {
    padding-bottom: 4rem;
  }

  .space-pl-8--m {
    padding-left: 4rem;
  }

  .space-m-9--m {
    margin: 4.5rem;
  }

  .space-mx-9--m {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--m {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--m {
    margin-top: 4.5rem;
  }

  .space-mr-9--m {
    margin-right: 4.5rem;
  }

  .space-mb-9--m {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--m {
    margin-left: 4.5rem;
  }

  .space-p-9--m {
    padding: 4.5rem;
  }

  .space-px-9--m {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--m {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--m {
    padding-top: 4.5rem;
  }

  .space-pr-9--m {
    padding-right: 4.5rem;
  }

  .space-pb-9--m {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--m {
    padding-left: 4.5rem;
  }

  .space-m-12--m {
    margin: 6rem;
  }

  .space-mx-12--m {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--m {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--m {
    margin-top: 6rem;
  }

  .space-mr-12--m {
    margin-right: 6rem;
  }

  .space-mb-12--m {
    margin-bottom: 6rem;
  }

  .space-ml-12--m {
    margin-left: 6rem;
  }

  .space-p-12--m {
    padding: 6rem;
  }

  .space-px-12--m {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--m {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--m {
    padding-top: 6rem;
  }

  .space-pr-12--m {
    padding-right: 6rem;
  }

  .space-pb-12--m {
    padding-bottom: 6rem;
  }

  .space-pl-12--m {
    padding-left: 6rem;
  }

  .space-m-16--m {
    margin: 8rem;
  }

  .space-mx-16--m {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--m {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--m {
    margin-top: 8rem;
  }

  .space-mr-16--m {
    margin-right: 8rem;
  }

  .space-mb-16--m {
    margin-bottom: 8rem;
  }

  .space-ml-16--m {
    margin-left: 8rem;
  }

  .space-p-16--m {
    padding: 8rem;
  }

  .space-px-16--m {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--m {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--m {
    padding-top: 8rem;
  }

  .space-pr-16--m {
    padding-right: 8rem;
  }

  .space-pb-16--m {
    padding-bottom: 8rem;
  }

  .space-pl-16--m {
    padding-left: 8rem;
  }

  .space-m-22--m {
    margin: 11rem;
  }

  .space-mx-22--m {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--m {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--m {
    margin-top: 11rem;
  }

  .space-mr-22--m {
    margin-right: 11rem;
  }

  .space-mb-22--m {
    margin-bottom: 11rem;
  }

  .space-ml-22--m {
    margin-left: 11rem;
  }

  .space-p-22--m {
    padding: 11rem;
  }

  .space-px-22--m {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--m {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--m {
    padding-top: 11rem;
  }

  .space-pr-22--m {
    padding-right: 11rem;
  }

  .space-pb-22--m {
    padding-bottom: 11rem;
  }

  .space-pl-22--m {
    padding-left: 11rem;
  }

  .space-m-27--m {
    margin: 13.5rem;
  }

  .space-mx-27--m {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--m {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--m {
    margin-top: 13.5rem;
  }

  .space-mr-27--m {
    margin-right: 13.5rem;
  }

  .space-mb-27--m {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--m {
    margin-left: 13.5rem;
  }

  .space-p-27--m {
    padding: 13.5rem;
  }

  .space-px-27--m {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--m {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--m {
    padding-top: 13.5rem;
  }

  .space-pr-27--m {
    padding-right: 13.5rem;
  }

  .space-pb-27--m {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--m {
    padding-left: 13.5rem;
  }

  .space-m-30--m {
    margin: 15rem;
  }

  .space-mx-30--m {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--m {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--m {
    margin-top: 15rem;
  }

  .space-mr-30--m {
    margin-right: 15rem;
  }

  .space-mb-30--m {
    margin-bottom: 15rem;
  }

  .space-ml-30--m {
    margin-left: 15rem;
  }

  .space-p-30--m {
    padding: 15rem;
  }

  .space-px-30--m {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--m {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--m {
    padding-top: 15rem;
  }

  .space-pr-30--m {
    padding-right: 15rem;
  }

  .space-pb-30--m {
    padding-bottom: 15rem;
  }

  .space-pl-30--m {
    padding-left: 15rem;
  }

  .space-m-46--m {
    margin: 23rem;
  }

  .space-mx-46--m {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--m {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--m {
    margin-top: 23rem;
  }

  .space-mr-46--m {
    margin-right: 23rem;
  }

  .space-mb-46--m {
    margin-bottom: 23rem;
  }

  .space-ml-46--m {
    margin-left: 23rem;
  }

  .space-p-46--m {
    padding: 23rem;
  }

  .space-px-46--m {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--m {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--m {
    padding-top: 23rem;
  }

  .space-pr-46--m {
    padding-right: 23rem;
  }

  .space-pb-46--m {
    padding-bottom: 23rem;
  }

  .space-pl-46--m {
    padding-left: 23rem;
  }

  .space-m-60--m {
    margin: 30rem;
  }

  .space-mx-60--m {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--m {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--m {
    margin-top: 30rem;
  }

  .space-mr-60--m {
    margin-right: 30rem;
  }

  .space-mb-60--m {
    margin-bottom: 30rem;
  }

  .space-ml-60--m {
    margin-left: 30rem;
  }

  .space-p-60--m {
    padding: 30rem;
  }

  .space-px-60--m {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--m {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--m {
    padding-top: 30rem;
  }

  .space-pr-60--m {
    padding-right: 30rem;
  }

  .space-pb-60--m {
    padding-bottom: 30rem;
  }

  .space-pl-60--m {
    padding-left: 30rem;
  }
}
@media (min-width: 80em) {
  .space-m-0--l {
    margin: 0rem;
  }

  .space-mx-0--l {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--l {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--l {
    margin-top: 0rem;
  }

  .space-mr-0--l {
    margin-right: 0rem;
  }

  .space-mb-0--l {
    margin-bottom: 0rem;
  }

  .space-ml-0--l {
    margin-left: 0rem;
  }

  .space-p-0--l {
    padding: 0rem;
  }

  .space-px-0--l {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--l {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--l {
    padding-top: 0rem;
  }

  .space-pr-0--l {
    padding-right: 0rem;
  }

  .space-pb-0--l {
    padding-bottom: 0rem;
  }

  .space-pl-0--l {
    padding-left: 0rem;
  }

  .space-m-1--l {
    margin: 0.5rem;
  }

  .space-mx-1--l {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--l {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--l {
    margin-top: 0.5rem;
  }

  .space-mr-1--l {
    margin-right: 0.5rem;
  }

  .space-mb-1--l {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--l {
    margin-left: 0.5rem;
  }

  .space-p-1--l {
    padding: 0.5rem;
  }

  .space-px-1--l {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--l {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--l {
    padding-top: 0.5rem;
  }

  .space-pr-1--l {
    padding-right: 0.5rem;
  }

  .space-pb-1--l {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--l {
    padding-left: 0.5rem;
  }

  .space-m-2--l {
    margin: 1rem;
  }

  .space-mx-2--l {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--l {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--l {
    margin-top: 1rem;
  }

  .space-mr-2--l {
    margin-right: 1rem;
  }

  .space-mb-2--l {
    margin-bottom: 1rem;
  }

  .space-ml-2--l {
    margin-left: 1rem;
  }

  .space-p-2--l {
    padding: 1rem;
  }

  .space-px-2--l {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--l {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--l {
    padding-top: 1rem;
  }

  .space-pr-2--l {
    padding-right: 1rem;
  }

  .space-pb-2--l {
    padding-bottom: 1rem;
  }

  .space-pl-2--l {
    padding-left: 1rem;
  }

  .space-m-3--l {
    margin: 1.5rem;
  }

  .space-mx-3--l {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--l {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--l {
    margin-top: 1.5rem;
  }

  .space-mr-3--l {
    margin-right: 1.5rem;
  }

  .space-mb-3--l {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--l {
    margin-left: 1.5rem;
  }

  .space-p-3--l {
    padding: 1.5rem;
  }

  .space-px-3--l {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--l {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--l {
    padding-top: 1.5rem;
  }

  .space-pr-3--l {
    padding-right: 1.5rem;
  }

  .space-pb-3--l {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--l {
    padding-left: 1.5rem;
  }

  .space-m-4--l {
    margin: 2rem;
  }

  .space-mx-4--l {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--l {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--l {
    margin-top: 2rem;
  }

  .space-mr-4--l {
    margin-right: 2rem;
  }

  .space-mb-4--l {
    margin-bottom: 2rem;
  }

  .space-ml-4--l {
    margin-left: 2rem;
  }

  .space-p-4--l {
    padding: 2rem;
  }

  .space-px-4--l {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--l {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--l {
    padding-top: 2rem;
  }

  .space-pr-4--l {
    padding-right: 2rem;
  }

  .space-pb-4--l {
    padding-bottom: 2rem;
  }

  .space-pl-4--l {
    padding-left: 2rem;
  }

  .space-m-5--l {
    margin: 2.5rem;
  }

  .space-mx-5--l {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--l {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--l {
    margin-top: 2.5rem;
  }

  .space-mr-5--l {
    margin-right: 2.5rem;
  }

  .space-mb-5--l {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--l {
    margin-left: 2.5rem;
  }

  .space-p-5--l {
    padding: 2.5rem;
  }

  .space-px-5--l {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--l {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--l {
    padding-top: 2.5rem;
  }

  .space-pr-5--l {
    padding-right: 2.5rem;
  }

  .space-pb-5--l {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--l {
    padding-left: 2.5rem;
  }

  .space-m-6--l {
    margin: 3rem;
  }

  .space-mx-6--l {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--l {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--l {
    margin-top: 3rem;
  }

  .space-mr-6--l {
    margin-right: 3rem;
  }

  .space-mb-6--l {
    margin-bottom: 3rem;
  }

  .space-ml-6--l {
    margin-left: 3rem;
  }

  .space-p-6--l {
    padding: 3rem;
  }

  .space-px-6--l {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--l {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--l {
    padding-top: 3rem;
  }

  .space-pr-6--l {
    padding-right: 3rem;
  }

  .space-pb-6--l {
    padding-bottom: 3rem;
  }

  .space-pl-6--l {
    padding-left: 3rem;
  }

  .space-m-7--l {
    margin: 3.5rem;
  }

  .space-mx-7--l {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--l {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--l {
    margin-top: 3.5rem;
  }

  .space-mr-7--l {
    margin-right: 3.5rem;
  }

  .space-mb-7--l {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--l {
    margin-left: 3.5rem;
  }

  .space-p-7--l {
    padding: 3.5rem;
  }

  .space-px-7--l {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--l {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--l {
    padding-top: 3.5rem;
  }

  .space-pr-7--l {
    padding-right: 3.5rem;
  }

  .space-pb-7--l {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--l {
    padding-left: 3.5rem;
  }

  .space-m-8--l {
    margin: 4rem;
  }

  .space-mx-8--l {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--l {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--l {
    margin-top: 4rem;
  }

  .space-mr-8--l {
    margin-right: 4rem;
  }

  .space-mb-8--l {
    margin-bottom: 4rem;
  }

  .space-ml-8--l {
    margin-left: 4rem;
  }

  .space-p-8--l {
    padding: 4rem;
  }

  .space-px-8--l {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--l {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--l {
    padding-top: 4rem;
  }

  .space-pr-8--l {
    padding-right: 4rem;
  }

  .space-pb-8--l {
    padding-bottom: 4rem;
  }

  .space-pl-8--l {
    padding-left: 4rem;
  }

  .space-m-9--l {
    margin: 4.5rem;
  }

  .space-mx-9--l {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--l {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--l {
    margin-top: 4.5rem;
  }

  .space-mr-9--l {
    margin-right: 4.5rem;
  }

  .space-mb-9--l {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--l {
    margin-left: 4.5rem;
  }

  .space-p-9--l {
    padding: 4.5rem;
  }

  .space-px-9--l {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--l {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--l {
    padding-top: 4.5rem;
  }

  .space-pr-9--l {
    padding-right: 4.5rem;
  }

  .space-pb-9--l {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--l {
    padding-left: 4.5rem;
  }

  .space-m-12--l {
    margin: 6rem;
  }

  .space-mx-12--l {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--l {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--l {
    margin-top: 6rem;
  }

  .space-mr-12--l {
    margin-right: 6rem;
  }

  .space-mb-12--l {
    margin-bottom: 6rem;
  }

  .space-ml-12--l {
    margin-left: 6rem;
  }

  .space-p-12--l {
    padding: 6rem;
  }

  .space-px-12--l {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--l {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--l {
    padding-top: 6rem;
  }

  .space-pr-12--l {
    padding-right: 6rem;
  }

  .space-pb-12--l {
    padding-bottom: 6rem;
  }

  .space-pl-12--l {
    padding-left: 6rem;
  }

  .space-m-16--l {
    margin: 8rem;
  }

  .space-mx-16--l {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--l {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--l {
    margin-top: 8rem;
  }

  .space-mr-16--l {
    margin-right: 8rem;
  }

  .space-mb-16--l {
    margin-bottom: 8rem;
  }

  .space-ml-16--l {
    margin-left: 8rem;
  }

  .space-p-16--l {
    padding: 8rem;
  }

  .space-px-16--l {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--l {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--l {
    padding-top: 8rem;
  }

  .space-pr-16--l {
    padding-right: 8rem;
  }

  .space-pb-16--l {
    padding-bottom: 8rem;
  }

  .space-pl-16--l {
    padding-left: 8rem;
  }

  .space-m-22--l {
    margin: 11rem;
  }

  .space-mx-22--l {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--l {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--l {
    margin-top: 11rem;
  }

  .space-mr-22--l {
    margin-right: 11rem;
  }

  .space-mb-22--l {
    margin-bottom: 11rem;
  }

  .space-ml-22--l {
    margin-left: 11rem;
  }

  .space-p-22--l {
    padding: 11rem;
  }

  .space-px-22--l {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--l {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--l {
    padding-top: 11rem;
  }

  .space-pr-22--l {
    padding-right: 11rem;
  }

  .space-pb-22--l {
    padding-bottom: 11rem;
  }

  .space-pl-22--l {
    padding-left: 11rem;
  }

  .space-m-27--l {
    margin: 13.5rem;
  }

  .space-mx-27--l {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--l {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--l {
    margin-top: 13.5rem;
  }

  .space-mr-27--l {
    margin-right: 13.5rem;
  }

  .space-mb-27--l {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--l {
    margin-left: 13.5rem;
  }

  .space-p-27--l {
    padding: 13.5rem;
  }

  .space-px-27--l {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--l {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--l {
    padding-top: 13.5rem;
  }

  .space-pr-27--l {
    padding-right: 13.5rem;
  }

  .space-pb-27--l {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--l {
    padding-left: 13.5rem;
  }

  .space-m-30--l {
    margin: 15rem;
  }

  .space-mx-30--l {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--l {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--l {
    margin-top: 15rem;
  }

  .space-mr-30--l {
    margin-right: 15rem;
  }

  .space-mb-30--l {
    margin-bottom: 15rem;
  }

  .space-ml-30--l {
    margin-left: 15rem;
  }

  .space-p-30--l {
    padding: 15rem;
  }

  .space-px-30--l {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--l {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--l {
    padding-top: 15rem;
  }

  .space-pr-30--l {
    padding-right: 15rem;
  }

  .space-pb-30--l {
    padding-bottom: 15rem;
  }

  .space-pl-30--l {
    padding-left: 15rem;
  }

  .space-m-46--l {
    margin: 23rem;
  }

  .space-mx-46--l {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--l {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--l {
    margin-top: 23rem;
  }

  .space-mr-46--l {
    margin-right: 23rem;
  }

  .space-mb-46--l {
    margin-bottom: 23rem;
  }

  .space-ml-46--l {
    margin-left: 23rem;
  }

  .space-p-46--l {
    padding: 23rem;
  }

  .space-px-46--l {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--l {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--l {
    padding-top: 23rem;
  }

  .space-pr-46--l {
    padding-right: 23rem;
  }

  .space-pb-46--l {
    padding-bottom: 23rem;
  }

  .space-pl-46--l {
    padding-left: 23rem;
  }

  .space-m-60--l {
    margin: 30rem;
  }

  .space-mx-60--l {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--l {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--l {
    margin-top: 30rem;
  }

  .space-mr-60--l {
    margin-right: 30rem;
  }

  .space-mb-60--l {
    margin-bottom: 30rem;
  }

  .space-ml-60--l {
    margin-left: 30rem;
  }

  .space-p-60--l {
    padding: 30rem;
  }

  .space-px-60--l {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--l {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--l {
    padding-top: 30rem;
  }

  .space-pr-60--l {
    padding-right: 30rem;
  }

  .space-pb-60--l {
    padding-bottom: 30rem;
  }

  .space-pl-60--l {
    padding-left: 30rem;
  }
}
@media (min-width: 90em) {
  .space-m-0--xl {
    margin: 0rem;
  }

  .space-mx-0--xl {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xl {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xl {
    margin-top: 0rem;
  }

  .space-mr-0--xl {
    margin-right: 0rem;
  }

  .space-mb-0--xl {
    margin-bottom: 0rem;
  }

  .space-ml-0--xl {
    margin-left: 0rem;
  }

  .space-p-0--xl {
    padding: 0rem;
  }

  .space-px-0--xl {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xl {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xl {
    padding-top: 0rem;
  }

  .space-pr-0--xl {
    padding-right: 0rem;
  }

  .space-pb-0--xl {
    padding-bottom: 0rem;
  }

  .space-pl-0--xl {
    padding-left: 0rem;
  }

  .space-m-1--xl {
    margin: 0.5rem;
  }

  .space-mx-1--xl {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xl {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xl {
    margin-top: 0.5rem;
  }

  .space-mr-1--xl {
    margin-right: 0.5rem;
  }

  .space-mb-1--xl {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xl {
    margin-left: 0.5rem;
  }

  .space-p-1--xl {
    padding: 0.5rem;
  }

  .space-px-1--xl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xl {
    padding-top: 0.5rem;
  }

  .space-pr-1--xl {
    padding-right: 0.5rem;
  }

  .space-pb-1--xl {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xl {
    padding-left: 0.5rem;
  }

  .space-m-2--xl {
    margin: 1rem;
  }

  .space-mx-2--xl {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xl {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xl {
    margin-top: 1rem;
  }

  .space-mr-2--xl {
    margin-right: 1rem;
  }

  .space-mb-2--xl {
    margin-bottom: 1rem;
  }

  .space-ml-2--xl {
    margin-left: 1rem;
  }

  .space-p-2--xl {
    padding: 1rem;
  }

  .space-px-2--xl {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xl {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xl {
    padding-top: 1rem;
  }

  .space-pr-2--xl {
    padding-right: 1rem;
  }

  .space-pb-2--xl {
    padding-bottom: 1rem;
  }

  .space-pl-2--xl {
    padding-left: 1rem;
  }

  .space-m-3--xl {
    margin: 1.5rem;
  }

  .space-mx-3--xl {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xl {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xl {
    margin-top: 1.5rem;
  }

  .space-mr-3--xl {
    margin-right: 1.5rem;
  }

  .space-mb-3--xl {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xl {
    margin-left: 1.5rem;
  }

  .space-p-3--xl {
    padding: 1.5rem;
  }

  .space-px-3--xl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xl {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xl {
    padding-top: 1.5rem;
  }

  .space-pr-3--xl {
    padding-right: 1.5rem;
  }

  .space-pb-3--xl {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xl {
    padding-left: 1.5rem;
  }

  .space-m-4--xl {
    margin: 2rem;
  }

  .space-mx-4--xl {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xl {
    margin-top: 2rem;
  }

  .space-mr-4--xl {
    margin-right: 2rem;
  }

  .space-mb-4--xl {
    margin-bottom: 2rem;
  }

  .space-ml-4--xl {
    margin-left: 2rem;
  }

  .space-p-4--xl {
    padding: 2rem;
  }

  .space-px-4--xl {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xl {
    padding-top: 2rem;
  }

  .space-pr-4--xl {
    padding-right: 2rem;
  }

  .space-pb-4--xl {
    padding-bottom: 2rem;
  }

  .space-pl-4--xl {
    padding-left: 2rem;
  }

  .space-m-5--xl {
    margin: 2.5rem;
  }

  .space-mx-5--xl {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xl {
    margin-top: 2.5rem;
  }

  .space-mr-5--xl {
    margin-right: 2.5rem;
  }

  .space-mb-5--xl {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xl {
    margin-left: 2.5rem;
  }

  .space-p-5--xl {
    padding: 2.5rem;
  }

  .space-px-5--xl {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xl {
    padding-top: 2.5rem;
  }

  .space-pr-5--xl {
    padding-right: 2.5rem;
  }

  .space-pb-5--xl {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xl {
    padding-left: 2.5rem;
  }

  .space-m-6--xl {
    margin: 3rem;
  }

  .space-mx-6--xl {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xl {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xl {
    margin-top: 3rem;
  }

  .space-mr-6--xl {
    margin-right: 3rem;
  }

  .space-mb-6--xl {
    margin-bottom: 3rem;
  }

  .space-ml-6--xl {
    margin-left: 3rem;
  }

  .space-p-6--xl {
    padding: 3rem;
  }

  .space-px-6--xl {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xl {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xl {
    padding-top: 3rem;
  }

  .space-pr-6--xl {
    padding-right: 3rem;
  }

  .space-pb-6--xl {
    padding-bottom: 3rem;
  }

  .space-pl-6--xl {
    padding-left: 3rem;
  }

  .space-m-7--xl {
    margin: 3.5rem;
  }

  .space-mx-7--xl {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xl {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xl {
    margin-top: 3.5rem;
  }

  .space-mr-7--xl {
    margin-right: 3.5rem;
  }

  .space-mb-7--xl {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xl {
    margin-left: 3.5rem;
  }

  .space-p-7--xl {
    padding: 3.5rem;
  }

  .space-px-7--xl {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xl {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xl {
    padding-top: 3.5rem;
  }

  .space-pr-7--xl {
    padding-right: 3.5rem;
  }

  .space-pb-7--xl {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xl {
    padding-left: 3.5rem;
  }

  .space-m-8--xl {
    margin: 4rem;
  }

  .space-mx-8--xl {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xl {
    margin-top: 4rem;
  }

  .space-mr-8--xl {
    margin-right: 4rem;
  }

  .space-mb-8--xl {
    margin-bottom: 4rem;
  }

  .space-ml-8--xl {
    margin-left: 4rem;
  }

  .space-p-8--xl {
    padding: 4rem;
  }

  .space-px-8--xl {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xl {
    padding-top: 4rem;
  }

  .space-pr-8--xl {
    padding-right: 4rem;
  }

  .space-pb-8--xl {
    padding-bottom: 4rem;
  }

  .space-pl-8--xl {
    padding-left: 4rem;
  }

  .space-m-9--xl {
    margin: 4.5rem;
  }

  .space-mx-9--xl {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xl {
    margin-top: 4.5rem;
  }

  .space-mr-9--xl {
    margin-right: 4.5rem;
  }

  .space-mb-9--xl {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xl {
    margin-left: 4.5rem;
  }

  .space-p-9--xl {
    padding: 4.5rem;
  }

  .space-px-9--xl {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xl {
    padding-top: 4.5rem;
  }

  .space-pr-9--xl {
    padding-right: 4.5rem;
  }

  .space-pb-9--xl {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xl {
    padding-left: 4.5rem;
  }

  .space-m-12--xl {
    margin: 6rem;
  }

  .space-mx-12--xl {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xl {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xl {
    margin-top: 6rem;
  }

  .space-mr-12--xl {
    margin-right: 6rem;
  }

  .space-mb-12--xl {
    margin-bottom: 6rem;
  }

  .space-ml-12--xl {
    margin-left: 6rem;
  }

  .space-p-12--xl {
    padding: 6rem;
  }

  .space-px-12--xl {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xl {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xl {
    padding-top: 6rem;
  }

  .space-pr-12--xl {
    padding-right: 6rem;
  }

  .space-pb-12--xl {
    padding-bottom: 6rem;
  }

  .space-pl-12--xl {
    padding-left: 6rem;
  }

  .space-m-16--xl {
    margin: 8rem;
  }

  .space-mx-16--xl {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xl {
    margin-top: 8rem;
  }

  .space-mr-16--xl {
    margin-right: 8rem;
  }

  .space-mb-16--xl {
    margin-bottom: 8rem;
  }

  .space-ml-16--xl {
    margin-left: 8rem;
  }

  .space-p-16--xl {
    padding: 8rem;
  }

  .space-px-16--xl {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xl {
    padding-top: 8rem;
  }

  .space-pr-16--xl {
    padding-right: 8rem;
  }

  .space-pb-16--xl {
    padding-bottom: 8rem;
  }

  .space-pl-16--xl {
    padding-left: 8rem;
  }

  .space-m-22--xl {
    margin: 11rem;
  }

  .space-mx-22--xl {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xl {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xl {
    margin-top: 11rem;
  }

  .space-mr-22--xl {
    margin-right: 11rem;
  }

  .space-mb-22--xl {
    margin-bottom: 11rem;
  }

  .space-ml-22--xl {
    margin-left: 11rem;
  }

  .space-p-22--xl {
    padding: 11rem;
  }

  .space-px-22--xl {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xl {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xl {
    padding-top: 11rem;
  }

  .space-pr-22--xl {
    padding-right: 11rem;
  }

  .space-pb-22--xl {
    padding-bottom: 11rem;
  }

  .space-pl-22--xl {
    padding-left: 11rem;
  }

  .space-m-27--xl {
    margin: 13.5rem;
  }

  .space-mx-27--xl {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xl {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xl {
    margin-top: 13.5rem;
  }

  .space-mr-27--xl {
    margin-right: 13.5rem;
  }

  .space-mb-27--xl {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xl {
    margin-left: 13.5rem;
  }

  .space-p-27--xl {
    padding: 13.5rem;
  }

  .space-px-27--xl {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xl {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xl {
    padding-top: 13.5rem;
  }

  .space-pr-27--xl {
    padding-right: 13.5rem;
  }

  .space-pb-27--xl {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xl {
    padding-left: 13.5rem;
  }

  .space-m-30--xl {
    margin: 15rem;
  }

  .space-mx-30--xl {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xl {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xl {
    margin-top: 15rem;
  }

  .space-mr-30--xl {
    margin-right: 15rem;
  }

  .space-mb-30--xl {
    margin-bottom: 15rem;
  }

  .space-ml-30--xl {
    margin-left: 15rem;
  }

  .space-p-30--xl {
    padding: 15rem;
  }

  .space-px-30--xl {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xl {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xl {
    padding-top: 15rem;
  }

  .space-pr-30--xl {
    padding-right: 15rem;
  }

  .space-pb-30--xl {
    padding-bottom: 15rem;
  }

  .space-pl-30--xl {
    padding-left: 15rem;
  }

  .space-m-46--xl {
    margin: 23rem;
  }

  .space-mx-46--xl {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xl {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xl {
    margin-top: 23rem;
  }

  .space-mr-46--xl {
    margin-right: 23rem;
  }

  .space-mb-46--xl {
    margin-bottom: 23rem;
  }

  .space-ml-46--xl {
    margin-left: 23rem;
  }

  .space-p-46--xl {
    padding: 23rem;
  }

  .space-px-46--xl {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xl {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xl {
    padding-top: 23rem;
  }

  .space-pr-46--xl {
    padding-right: 23rem;
  }

  .space-pb-46--xl {
    padding-bottom: 23rem;
  }

  .space-pl-46--xl {
    padding-left: 23rem;
  }

  .space-m-60--xl {
    margin: 30rem;
  }

  .space-mx-60--xl {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xl {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xl {
    margin-top: 30rem;
  }

  .space-mr-60--xl {
    margin-right: 30rem;
  }

  .space-mb-60--xl {
    margin-bottom: 30rem;
  }

  .space-ml-60--xl {
    margin-left: 30rem;
  }

  .space-p-60--xl {
    padding: 30rem;
  }

  .space-px-60--xl {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xl {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xl {
    padding-top: 30rem;
  }

  .space-pr-60--xl {
    padding-right: 30rem;
  }

  .space-pb-60--xl {
    padding-bottom: 30rem;
  }

  .space-pl-60--xl {
    padding-left: 30rem;
  }
}
@media (min-width: 120em) {
  .space-m-0--xxl {
    margin: 0rem;
  }

  .space-mx-0--xxl {
    margin-right: 0rem;
    margin-left: 0rem;
  }

  .space-my-0--xxl {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  .space-mt-0--xxl {
    margin-top: 0rem;
  }

  .space-mr-0--xxl {
    margin-right: 0rem;
  }

  .space-mb-0--xxl {
    margin-bottom: 0rem;
  }

  .space-ml-0--xxl {
    margin-left: 0rem;
  }

  .space-p-0--xxl {
    padding: 0rem;
  }

  .space-px-0--xxl {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .space-py-0--xxl {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .space-pt-0--xxl {
    padding-top: 0rem;
  }

  .space-pr-0--xxl {
    padding-right: 0rem;
  }

  .space-pb-0--xxl {
    padding-bottom: 0rem;
  }

  .space-pl-0--xxl {
    padding-left: 0rem;
  }

  .space-m-1--xxl {
    margin: 0.5rem;
  }

  .space-mx-1--xxl {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .space-my-1--xxl {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .space-mt-1--xxl {
    margin-top: 0.5rem;
  }

  .space-mr-1--xxl {
    margin-right: 0.5rem;
  }

  .space-mb-1--xxl {
    margin-bottom: 0.5rem;
  }

  .space-ml-1--xxl {
    margin-left: 0.5rem;
  }

  .space-p-1--xxl {
    padding: 0.5rem;
  }

  .space-px-1--xxl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .space-py-1--xxl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .space-pt-1--xxl {
    padding-top: 0.5rem;
  }

  .space-pr-1--xxl {
    padding-right: 0.5rem;
  }

  .space-pb-1--xxl {
    padding-bottom: 0.5rem;
  }

  .space-pl-1--xxl {
    padding-left: 0.5rem;
  }

  .space-m-2--xxl {
    margin: 1rem;
  }

  .space-mx-2--xxl {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .space-my-2--xxl {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .space-mt-2--xxl {
    margin-top: 1rem;
  }

  .space-mr-2--xxl {
    margin-right: 1rem;
  }

  .space-mb-2--xxl {
    margin-bottom: 1rem;
  }

  .space-ml-2--xxl {
    margin-left: 1rem;
  }

  .space-p-2--xxl {
    padding: 1rem;
  }

  .space-px-2--xxl {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .space-py-2--xxl {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .space-pt-2--xxl {
    padding-top: 1rem;
  }

  .space-pr-2--xxl {
    padding-right: 1rem;
  }

  .space-pb-2--xxl {
    padding-bottom: 1rem;
  }

  .space-pl-2--xxl {
    padding-left: 1rem;
  }

  .space-m-3--xxl {
    margin: 1.5rem;
  }

  .space-mx-3--xxl {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }

  .space-my-3--xxl {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .space-mt-3--xxl {
    margin-top: 1.5rem;
  }

  .space-mr-3--xxl {
    margin-right: 1.5rem;
  }

  .space-mb-3--xxl {
    margin-bottom: 1.5rem;
  }

  .space-ml-3--xxl {
    margin-left: 1.5rem;
  }

  .space-p-3--xxl {
    padding: 1.5rem;
  }

  .space-px-3--xxl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .space-py-3--xxl {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .space-pt-3--xxl {
    padding-top: 1.5rem;
  }

  .space-pr-3--xxl {
    padding-right: 1.5rem;
  }

  .space-pb-3--xxl {
    padding-bottom: 1.5rem;
  }

  .space-pl-3--xxl {
    padding-left: 1.5rem;
  }

  .space-m-4--xxl {
    margin: 2rem;
  }

  .space-mx-4--xxl {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .space-my-4--xxl {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .space-mt-4--xxl {
    margin-top: 2rem;
  }

  .space-mr-4--xxl {
    margin-right: 2rem;
  }

  .space-mb-4--xxl {
    margin-bottom: 2rem;
  }

  .space-ml-4--xxl {
    margin-left: 2rem;
  }

  .space-p-4--xxl {
    padding: 2rem;
  }

  .space-px-4--xxl {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .space-py-4--xxl {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .space-pt-4--xxl {
    padding-top: 2rem;
  }

  .space-pr-4--xxl {
    padding-right: 2rem;
  }

  .space-pb-4--xxl {
    padding-bottom: 2rem;
  }

  .space-pl-4--xxl {
    padding-left: 2rem;
  }

  .space-m-5--xxl {
    margin: 2.5rem;
  }

  .space-mx-5--xxl {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .space-my-5--xxl {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .space-mt-5--xxl {
    margin-top: 2.5rem;
  }

  .space-mr-5--xxl {
    margin-right: 2.5rem;
  }

  .space-mb-5--xxl {
    margin-bottom: 2.5rem;
  }

  .space-ml-5--xxl {
    margin-left: 2.5rem;
  }

  .space-p-5--xxl {
    padding: 2.5rem;
  }

  .space-px-5--xxl {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }

  .space-py-5--xxl {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .space-pt-5--xxl {
    padding-top: 2.5rem;
  }

  .space-pr-5--xxl {
    padding-right: 2.5rem;
  }

  .space-pb-5--xxl {
    padding-bottom: 2.5rem;
  }

  .space-pl-5--xxl {
    padding-left: 2.5rem;
  }

  .space-m-6--xxl {
    margin: 3rem;
  }

  .space-mx-6--xxl {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .space-my-6--xxl {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .space-mt-6--xxl {
    margin-top: 3rem;
  }

  .space-mr-6--xxl {
    margin-right: 3rem;
  }

  .space-mb-6--xxl {
    margin-bottom: 3rem;
  }

  .space-ml-6--xxl {
    margin-left: 3rem;
  }

  .space-p-6--xxl {
    padding: 3rem;
  }

  .space-px-6--xxl {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .space-py-6--xxl {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .space-pt-6--xxl {
    padding-top: 3rem;
  }

  .space-pr-6--xxl {
    padding-right: 3rem;
  }

  .space-pb-6--xxl {
    padding-bottom: 3rem;
  }

  .space-pl-6--xxl {
    padding-left: 3rem;
  }

  .space-m-7--xxl {
    margin: 3.5rem;
  }

  .space-mx-7--xxl {
    margin-right: 3.5rem;
    margin-left: 3.5rem;
  }

  .space-my-7--xxl {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .space-mt-7--xxl {
    margin-top: 3.5rem;
  }

  .space-mr-7--xxl {
    margin-right: 3.5rem;
  }

  .space-mb-7--xxl {
    margin-bottom: 3.5rem;
  }

  .space-ml-7--xxl {
    margin-left: 3.5rem;
  }

  .space-p-7--xxl {
    padding: 3.5rem;
  }

  .space-px-7--xxl {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }

  .space-py-7--xxl {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .space-pt-7--xxl {
    padding-top: 3.5rem;
  }

  .space-pr-7--xxl {
    padding-right: 3.5rem;
  }

  .space-pb-7--xxl {
    padding-bottom: 3.5rem;
  }

  .space-pl-7--xxl {
    padding-left: 3.5rem;
  }

  .space-m-8--xxl {
    margin: 4rem;
  }

  .space-mx-8--xxl {
    margin-right: 4rem;
    margin-left: 4rem;
  }

  .space-my-8--xxl {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .space-mt-8--xxl {
    margin-top: 4rem;
  }

  .space-mr-8--xxl {
    margin-right: 4rem;
  }

  .space-mb-8--xxl {
    margin-bottom: 4rem;
  }

  .space-ml-8--xxl {
    margin-left: 4rem;
  }

  .space-p-8--xxl {
    padding: 4rem;
  }

  .space-px-8--xxl {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .space-py-8--xxl {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .space-pt-8--xxl {
    padding-top: 4rem;
  }

  .space-pr-8--xxl {
    padding-right: 4rem;
  }

  .space-pb-8--xxl {
    padding-bottom: 4rem;
  }

  .space-pl-8--xxl {
    padding-left: 4rem;
  }

  .space-m-9--xxl {
    margin: 4.5rem;
  }

  .space-mx-9--xxl {
    margin-right: 4.5rem;
    margin-left: 4.5rem;
  }

  .space-my-9--xxl {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }

  .space-mt-9--xxl {
    margin-top: 4.5rem;
  }

  .space-mr-9--xxl {
    margin-right: 4.5rem;
  }

  .space-mb-9--xxl {
    margin-bottom: 4.5rem;
  }

  .space-ml-9--xxl {
    margin-left: 4.5rem;
  }

  .space-p-9--xxl {
    padding: 4.5rem;
  }

  .space-px-9--xxl {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  .space-py-9--xxl {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }

  .space-pt-9--xxl {
    padding-top: 4.5rem;
  }

  .space-pr-9--xxl {
    padding-right: 4.5rem;
  }

  .space-pb-9--xxl {
    padding-bottom: 4.5rem;
  }

  .space-pl-9--xxl {
    padding-left: 4.5rem;
  }

  .space-m-12--xxl {
    margin: 6rem;
  }

  .space-mx-12--xxl {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .space-my-12--xxl {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .space-mt-12--xxl {
    margin-top: 6rem;
  }

  .space-mr-12--xxl {
    margin-right: 6rem;
  }

  .space-mb-12--xxl {
    margin-bottom: 6rem;
  }

  .space-ml-12--xxl {
    margin-left: 6rem;
  }

  .space-p-12--xxl {
    padding: 6rem;
  }

  .space-px-12--xxl {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .space-py-12--xxl {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .space-pt-12--xxl {
    padding-top: 6rem;
  }

  .space-pr-12--xxl {
    padding-right: 6rem;
  }

  .space-pb-12--xxl {
    padding-bottom: 6rem;
  }

  .space-pl-12--xxl {
    padding-left: 6rem;
  }

  .space-m-16--xxl {
    margin: 8rem;
  }

  .space-mx-16--xxl {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .space-my-16--xxl {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .space-mt-16--xxl {
    margin-top: 8rem;
  }

  .space-mr-16--xxl {
    margin-right: 8rem;
  }

  .space-mb-16--xxl {
    margin-bottom: 8rem;
  }

  .space-ml-16--xxl {
    margin-left: 8rem;
  }

  .space-p-16--xxl {
    padding: 8rem;
  }

  .space-px-16--xxl {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  .space-py-16--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .space-pt-16--xxl {
    padding-top: 8rem;
  }

  .space-pr-16--xxl {
    padding-right: 8rem;
  }

  .space-pb-16--xxl {
    padding-bottom: 8rem;
  }

  .space-pl-16--xxl {
    padding-left: 8rem;
  }

  .space-m-22--xxl {
    margin: 11rem;
  }

  .space-mx-22--xxl {
    margin-right: 11rem;
    margin-left: 11rem;
  }

  .space-my-22--xxl {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .space-mt-22--xxl {
    margin-top: 11rem;
  }

  .space-mr-22--xxl {
    margin-right: 11rem;
  }

  .space-mb-22--xxl {
    margin-bottom: 11rem;
  }

  .space-ml-22--xxl {
    margin-left: 11rem;
  }

  .space-p-22--xxl {
    padding: 11rem;
  }

  .space-px-22--xxl {
    padding-right: 11rem;
    padding-left: 11rem;
  }

  .space-py-22--xxl {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .space-pt-22--xxl {
    padding-top: 11rem;
  }

  .space-pr-22--xxl {
    padding-right: 11rem;
  }

  .space-pb-22--xxl {
    padding-bottom: 11rem;
  }

  .space-pl-22--xxl {
    padding-left: 11rem;
  }

  .space-m-27--xxl {
    margin: 13.5rem;
  }

  .space-mx-27--xxl {
    margin-right: 13.5rem;
    margin-left: 13.5rem;
  }

  .space-my-27--xxl {
    margin-top: 13.5rem;
    margin-bottom: 13.5rem;
  }

  .space-mt-27--xxl {
    margin-top: 13.5rem;
  }

  .space-mr-27--xxl {
    margin-right: 13.5rem;
  }

  .space-mb-27--xxl {
    margin-bottom: 13.5rem;
  }

  .space-ml-27--xxl {
    margin-left: 13.5rem;
  }

  .space-p-27--xxl {
    padding: 13.5rem;
  }

  .space-px-27--xxl {
    padding-right: 13.5rem;
    padding-left: 13.5rem;
  }

  .space-py-27--xxl {
    padding-top: 13.5rem;
    padding-bottom: 13.5rem;
  }

  .space-pt-27--xxl {
    padding-top: 13.5rem;
  }

  .space-pr-27--xxl {
    padding-right: 13.5rem;
  }

  .space-pb-27--xxl {
    padding-bottom: 13.5rem;
  }

  .space-pl-27--xxl {
    padding-left: 13.5rem;
  }

  .space-m-30--xxl {
    margin: 15rem;
  }

  .space-mx-30--xxl {
    margin-right: 15rem;
    margin-left: 15rem;
  }

  .space-my-30--xxl {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .space-mt-30--xxl {
    margin-top: 15rem;
  }

  .space-mr-30--xxl {
    margin-right: 15rem;
  }

  .space-mb-30--xxl {
    margin-bottom: 15rem;
  }

  .space-ml-30--xxl {
    margin-left: 15rem;
  }

  .space-p-30--xxl {
    padding: 15rem;
  }

  .space-px-30--xxl {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .space-py-30--xxl {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .space-pt-30--xxl {
    padding-top: 15rem;
  }

  .space-pr-30--xxl {
    padding-right: 15rem;
  }

  .space-pb-30--xxl {
    padding-bottom: 15rem;
  }

  .space-pl-30--xxl {
    padding-left: 15rem;
  }

  .space-m-46--xxl {
    margin: 23rem;
  }

  .space-mx-46--xxl {
    margin-right: 23rem;
    margin-left: 23rem;
  }

  .space-my-46--xxl {
    margin-top: 23rem;
    margin-bottom: 23rem;
  }

  .space-mt-46--xxl {
    margin-top: 23rem;
  }

  .space-mr-46--xxl {
    margin-right: 23rem;
  }

  .space-mb-46--xxl {
    margin-bottom: 23rem;
  }

  .space-ml-46--xxl {
    margin-left: 23rem;
  }

  .space-p-46--xxl {
    padding: 23rem;
  }

  .space-px-46--xxl {
    padding-right: 23rem;
    padding-left: 23rem;
  }

  .space-py-46--xxl {
    padding-top: 23rem;
    padding-bottom: 23rem;
  }

  .space-pt-46--xxl {
    padding-top: 23rem;
  }

  .space-pr-46--xxl {
    padding-right: 23rem;
  }

  .space-pb-46--xxl {
    padding-bottom: 23rem;
  }

  .space-pl-46--xxl {
    padding-left: 23rem;
  }

  .space-m-60--xxl {
    margin: 30rem;
  }

  .space-mx-60--xxl {
    margin-right: 30rem;
    margin-left: 30rem;
  }

  .space-my-60--xxl {
    margin-top: 30rem;
    margin-bottom: 30rem;
  }

  .space-mt-60--xxl {
    margin-top: 30rem;
  }

  .space-mr-60--xxl {
    margin-right: 30rem;
  }

  .space-mb-60--xxl {
    margin-bottom: 30rem;
  }

  .space-ml-60--xxl {
    margin-left: 30rem;
  }

  .space-p-60--xxl {
    padding: 30rem;
  }

  .space-px-60--xxl {
    padding-right: 30rem;
    padding-left: 30rem;
  }

  .space-py-60--xxl {
    padding-top: 30rem;
    padding-bottom: 30rem;
  }

  .space-pt-60--xxl {
    padding-top: 30rem;
  }

  .space-pr-60--xxl {
    padding-right: 30rem;
  }

  .space-pb-60--xxl {
    padding-bottom: 30rem;
  }

  .space-pl-60--xxl {
    padding-left: 30rem;
  }
}
/**
 * A function helper to avoid having to type `map-get($grid-gutters, ...)`
 * Based on http://css-tricks.com/handling-z-index/
 *
 * @param  {string} $breakpoint The name of the breakpoint
 * @return {number}        The value corresponding to the breakpoint's name
 */
/*============================================================================*\
   Grid block
\*============================================================================*/
.grid {
  min-height: 1px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 0em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 30em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 48em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 64em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 80em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 90em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 120em) {
  .grid {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/* No gutter modifier
\*============================================================================*/
.grid--no-gutter {
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 0em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 30em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 48em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 64em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 80em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 90em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 120em) {
  .grid--no-gutter {
    padding-right: 0;
    padding-left: 0;
  }
}

/* Nested grid modifier
\*============================================================================*/
/**
 * Nested grid must not have left and right paddings to avoid weird alignment
 * when nesting multiple times. The nested grid is different from the
 * "no-gutter" one by only resetting the padding on the grid block, and not on
 * its row or columns.
 */
.grid--nested {
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 0em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 30em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 48em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 64em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 80em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 90em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 120em) {
  .grid--nested {
    padding-right: 0;
    padding-left: 0;
  }
}

/*============================================================================*\
   Row element
\*============================================================================*/
.grid__row {
  position: relative;
}
.grid__row::after {
  content: "";
  clear: both;
  display: block;
}
@media (min-width: 0em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 30em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 48em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 64em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 80em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 90em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 120em) {
  .grid__row {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .grid--no-gutter > .grid__row {
    margin-right: 0;
    margin-left: 0;
  }
}

/* Row flex modifiers
\*============================================================================*/
.grid__row--end,
.grid__row--center,
.grid__row--stretch {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 0em) {
  .grid__row--end .grid__col-left--xxs,
.grid__row--center .grid__col-left--xxs,
.grid__row--stretch .grid__col-left--xxs {
    order: 0;
  }
  .grid__row--end .grid__col-right--xxs,
.grid__row--center .grid__col-right--xxs,
.grid__row--stretch .grid__col-right--xxs {
    order: 1;
  }
}
@media (min-width: 30em) {
  .grid__row--end .grid__col-left--xs,
.grid__row--center .grid__col-left--xs,
.grid__row--stretch .grid__col-left--xs {
    order: 0;
  }
  .grid__row--end .grid__col-right--xs,
.grid__row--center .grid__col-right--xs,
.grid__row--stretch .grid__col-right--xs {
    order: 1;
  }
}
@media (min-width: 48em) {
  .grid__row--end .grid__col-left--s,
.grid__row--center .grid__col-left--s,
.grid__row--stretch .grid__col-left--s {
    order: 0;
  }
  .grid__row--end .grid__col-right--s,
.grid__row--center .grid__col-right--s,
.grid__row--stretch .grid__col-right--s {
    order: 1;
  }
}
@media (min-width: 64em) {
  .grid__row--end .grid__col-left--m,
.grid__row--center .grid__col-left--m,
.grid__row--stretch .grid__col-left--m {
    order: 0;
  }
  .grid__row--end .grid__col-right--m,
.grid__row--center .grid__col-right--m,
.grid__row--stretch .grid__col-right--m {
    order: 1;
  }
}
@media (min-width: 80em) {
  .grid__row--end .grid__col-left--l,
.grid__row--center .grid__col-left--l,
.grid__row--stretch .grid__col-left--l {
    order: 0;
  }
  .grid__row--end .grid__col-right--l,
.grid__row--center .grid__col-right--l,
.grid__row--stretch .grid__col-right--l {
    order: 1;
  }
}
@media (min-width: 90em) {
  .grid__row--end .grid__col-left--xl,
.grid__row--center .grid__col-left--xl,
.grid__row--stretch .grid__col-left--xl {
    order: 0;
  }
  .grid__row--end .grid__col-right--xl,
.grid__row--center .grid__col-right--xl,
.grid__row--stretch .grid__col-right--xl {
    order: 1;
  }
}
@media (min-width: 120em) {
  .grid__row--end .grid__col-left--xxl,
.grid__row--center .grid__col-left--xxl,
.grid__row--stretch .grid__col-left--xxl {
    order: 0;
  }
  .grid__row--end .grid__col-right--xxl,
.grid__row--center .grid__col-right--xxl,
.grid__row--stretch .grid__col-right--xxl {
    order: 1;
  }
}

.grid__row--stretch {
  align-items: stretch;
}

.grid__row--center {
  align-items: center;
}

.grid__row--end {
  align-items: flex-end;
}

/*============================================================================*\
   Columns elements
\*============================================================================*/
[class*=grid__col-] {
  min-height: 1px;
}
.grid__row--stretch > [class*=grid__col-] {
  display: flex;
  align-items: stretch;
}
@media (min-width: 0em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 30em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 48em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 64em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 80em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 90em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 120em) {
  [class*=grid__col-] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .grid--no-gutter > .grid__row > [class*=grid__col-] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 0em) {
  [class*=grid__col-][class*="--xxs"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--xxs {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--xxs {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--xxs {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--xxs {
    float: left;
  }

  [class*=grid__col-].grid__col-right--xxs {
    float: right;
  }

  .grid__col-0--xxs {
    display: none;
  }

  .grid__pull-0--xxs,
.grid__push-0--xxs {
    margin-left: 0;
  }

  .grid__col-1--xxs {
    width: 8.3333333333%;
  }

  .grid__pull-1--xxs {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--xxs {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--xxs {
    width: 16.6666666667%;
  }

  .grid__pull-2--xxs {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--xxs {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--xxs {
    width: 25%;
  }

  .grid__pull-3--xxs {
    margin-left: -25%;
  }

  .grid__push-3--xxs {
    margin-left: 25%;
  }

  .grid__col-4--xxs {
    width: 33.3333333333%;
  }

  .grid__pull-4--xxs {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--xxs {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--xxs {
    width: 41.6666666667%;
  }

  .grid__pull-5--xxs {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--xxs {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--xxs {
    width: 50%;
  }

  .grid__pull-6--xxs {
    margin-left: -50%;
  }

  .grid__push-6--xxs {
    margin-left: 50%;
  }

  .grid__col-7--xxs {
    width: 58.3333333333%;
  }

  .grid__pull-7--xxs {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--xxs {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--xxs {
    width: 66.6666666667%;
  }

  .grid__pull-8--xxs {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--xxs {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--xxs {
    width: 75%;
  }

  .grid__pull-9--xxs {
    margin-left: -75%;
  }

  .grid__push-9--xxs {
    margin-left: 75%;
  }

  .grid__col-10--xxs {
    width: 83.3333333333%;
  }

  .grid__pull-10--xxs {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--xxs {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--xxs {
    width: 91.6666666667%;
  }

  .grid__pull-11--xxs {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--xxs {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--xxs {
    width: 100%;
  }

  .grid__pull-12--xxs {
    margin-left: -100%;
  }

  .grid__push-12--xxs {
    margin-left: 100%;
  }
}
@media (min-width: 30em) {
  [class*=grid__col-][class*="--xs"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--xs {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--xs {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--xs {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--xs {
    float: left;
  }

  [class*=grid__col-].grid__col-right--xs {
    float: right;
  }

  .grid__col-0--xs {
    display: none;
  }

  .grid__pull-0--xs,
.grid__push-0--xs {
    margin-left: 0;
  }

  .grid__col-1--xs {
    width: 8.3333333333%;
  }

  .grid__pull-1--xs {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--xs {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--xs {
    width: 16.6666666667%;
  }

  .grid__pull-2--xs {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--xs {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--xs {
    width: 25%;
  }

  .grid__pull-3--xs {
    margin-left: -25%;
  }

  .grid__push-3--xs {
    margin-left: 25%;
  }

  .grid__col-4--xs {
    width: 33.3333333333%;
  }

  .grid__pull-4--xs {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--xs {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--xs {
    width: 41.6666666667%;
  }

  .grid__pull-5--xs {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--xs {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--xs {
    width: 50%;
  }

  .grid__pull-6--xs {
    margin-left: -50%;
  }

  .grid__push-6--xs {
    margin-left: 50%;
  }

  .grid__col-7--xs {
    width: 58.3333333333%;
  }

  .grid__pull-7--xs {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--xs {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--xs {
    width: 66.6666666667%;
  }

  .grid__pull-8--xs {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--xs {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--xs {
    width: 75%;
  }

  .grid__pull-9--xs {
    margin-left: -75%;
  }

  .grid__push-9--xs {
    margin-left: 75%;
  }

  .grid__col-10--xs {
    width: 83.3333333333%;
  }

  .grid__pull-10--xs {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--xs {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--xs {
    width: 91.6666666667%;
  }

  .grid__pull-11--xs {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--xs {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--xs {
    width: 100%;
  }

  .grid__pull-12--xs {
    margin-left: -100%;
  }

  .grid__push-12--xs {
    margin-left: 100%;
  }
}
@media (min-width: 48em) {
  [class*=grid__col-][class*="--s"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--s {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--s {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--s {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--s {
    float: left;
  }

  [class*=grid__col-].grid__col-right--s {
    float: right;
  }

  .grid__col-0--s {
    display: none;
  }

  .grid__pull-0--s,
.grid__push-0--s {
    margin-left: 0;
  }

  .grid__col-1--s {
    width: 8.3333333333%;
  }

  .grid__pull-1--s {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--s {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--s {
    width: 16.6666666667%;
  }

  .grid__pull-2--s {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--s {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--s {
    width: 25%;
  }

  .grid__pull-3--s {
    margin-left: -25%;
  }

  .grid__push-3--s {
    margin-left: 25%;
  }

  .grid__col-4--s {
    width: 33.3333333333%;
  }

  .grid__pull-4--s {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--s {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--s {
    width: 41.6666666667%;
  }

  .grid__pull-5--s {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--s {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--s {
    width: 50%;
  }

  .grid__pull-6--s {
    margin-left: -50%;
  }

  .grid__push-6--s {
    margin-left: 50%;
  }

  .grid__col-7--s {
    width: 58.3333333333%;
  }

  .grid__pull-7--s {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--s {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--s {
    width: 66.6666666667%;
  }

  .grid__pull-8--s {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--s {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--s {
    width: 75%;
  }

  .grid__pull-9--s {
    margin-left: -75%;
  }

  .grid__push-9--s {
    margin-left: 75%;
  }

  .grid__col-10--s {
    width: 83.3333333333%;
  }

  .grid__pull-10--s {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--s {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--s {
    width: 91.6666666667%;
  }

  .grid__pull-11--s {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--s {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--s {
    width: 100%;
  }

  .grid__pull-12--s {
    margin-left: -100%;
  }

  .grid__push-12--s {
    margin-left: 100%;
  }
}
@media (min-width: 64em) {
  [class*=grid__col-][class*="--m"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--m {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--m {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--m {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--m {
    float: left;
  }

  [class*=grid__col-].grid__col-right--m {
    float: right;
  }

  .grid__col-0--m {
    display: none;
  }

  .grid__pull-0--m,
.grid__push-0--m {
    margin-left: 0;
  }

  .grid__col-1--m {
    width: 8.3333333333%;
  }

  .grid__pull-1--m {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--m {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--m {
    width: 16.6666666667%;
  }

  .grid__pull-2--m {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--m {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--m {
    width: 25%;
  }

  .grid__pull-3--m {
    margin-left: -25%;
  }

  .grid__push-3--m {
    margin-left: 25%;
  }

  .grid__col-4--m {
    width: 33.3333333333%;
  }

  .grid__pull-4--m {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--m {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--m {
    width: 41.6666666667%;
  }

  .grid__pull-5--m {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--m {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--m {
    width: 50%;
  }

  .grid__pull-6--m {
    margin-left: -50%;
  }

  .grid__push-6--m {
    margin-left: 50%;
  }

  .grid__col-7--m {
    width: 58.3333333333%;
  }

  .grid__pull-7--m {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--m {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--m {
    width: 66.6666666667%;
  }

  .grid__pull-8--m {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--m {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--m {
    width: 75%;
  }

  .grid__pull-9--m {
    margin-left: -75%;
  }

  .grid__push-9--m {
    margin-left: 75%;
  }

  .grid__col-10--m {
    width: 83.3333333333%;
  }

  .grid__pull-10--m {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--m {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--m {
    width: 91.6666666667%;
  }

  .grid__pull-11--m {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--m {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--m {
    width: 100%;
  }

  .grid__pull-12--m {
    margin-left: -100%;
  }

  .grid__push-12--m {
    margin-left: 100%;
  }
}
@media (min-width: 80em) {
  [class*=grid__col-][class*="--l"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--l {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--l {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--l {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--l {
    float: left;
  }

  [class*=grid__col-].grid__col-right--l {
    float: right;
  }

  .grid__col-0--l {
    display: none;
  }

  .grid__pull-0--l,
.grid__push-0--l {
    margin-left: 0;
  }

  .grid__col-1--l {
    width: 8.3333333333%;
  }

  .grid__pull-1--l {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--l {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--l {
    width: 16.6666666667%;
  }

  .grid__pull-2--l {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--l {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--l {
    width: 25%;
  }

  .grid__pull-3--l {
    margin-left: -25%;
  }

  .grid__push-3--l {
    margin-left: 25%;
  }

  .grid__col-4--l {
    width: 33.3333333333%;
  }

  .grid__pull-4--l {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--l {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--l {
    width: 41.6666666667%;
  }

  .grid__pull-5--l {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--l {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--l {
    width: 50%;
  }

  .grid__pull-6--l {
    margin-left: -50%;
  }

  .grid__push-6--l {
    margin-left: 50%;
  }

  .grid__col-7--l {
    width: 58.3333333333%;
  }

  .grid__pull-7--l {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--l {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--l {
    width: 66.6666666667%;
  }

  .grid__pull-8--l {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--l {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--l {
    width: 75%;
  }

  .grid__pull-9--l {
    margin-left: -75%;
  }

  .grid__push-9--l {
    margin-left: 75%;
  }

  .grid__col-10--l {
    width: 83.3333333333%;
  }

  .grid__pull-10--l {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--l {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--l {
    width: 91.6666666667%;
  }

  .grid__pull-11--l {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--l {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--l {
    width: 100%;
  }

  .grid__pull-12--l {
    margin-left: -100%;
  }

  .grid__push-12--l {
    margin-left: 100%;
  }
}
@media (min-width: 90em) {
  [class*=grid__col-][class*="--xl"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--xl {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--xl {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--xl {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--xl {
    float: left;
  }

  [class*=grid__col-].grid__col-right--xl {
    float: right;
  }

  .grid__col-0--xl {
    display: none;
  }

  .grid__pull-0--xl,
.grid__push-0--xl {
    margin-left: 0;
  }

  .grid__col-1--xl {
    width: 8.3333333333%;
  }

  .grid__pull-1--xl {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--xl {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--xl {
    width: 16.6666666667%;
  }

  .grid__pull-2--xl {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--xl {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--xl {
    width: 25%;
  }

  .grid__pull-3--xl {
    margin-left: -25%;
  }

  .grid__push-3--xl {
    margin-left: 25%;
  }

  .grid__col-4--xl {
    width: 33.3333333333%;
  }

  .grid__pull-4--xl {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--xl {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--xl {
    width: 41.6666666667%;
  }

  .grid__pull-5--xl {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--xl {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--xl {
    width: 50%;
  }

  .grid__pull-6--xl {
    margin-left: -50%;
  }

  .grid__push-6--xl {
    margin-left: 50%;
  }

  .grid__col-7--xl {
    width: 58.3333333333%;
  }

  .grid__pull-7--xl {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--xl {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--xl {
    width: 66.6666666667%;
  }

  .grid__pull-8--xl {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--xl {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--xl {
    width: 75%;
  }

  .grid__pull-9--xl {
    margin-left: -75%;
  }

  .grid__push-9--xl {
    margin-left: 75%;
  }

  .grid__col-10--xl {
    width: 83.3333333333%;
  }

  .grid__pull-10--xl {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--xl {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--xl {
    width: 91.6666666667%;
  }

  .grid__pull-11--xl {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--xl {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--xl {
    width: 100%;
  }

  .grid__pull-12--xl {
    margin-left: -100%;
  }

  .grid__push-12--xl {
    margin-left: 100%;
  }
}
@media (min-width: 120em) {
  [class*=grid__col-][class*="--xxl"] {
    float: left;
    display: block;
  }

  [class*=grid__col-].grid__col-center--xxl {
    float: none;
    clear: both;
    margin-right: auto;
    margin-left: auto;
  }

  [class*=grid__col-].grid__col-clear--xxl {
    clear: both;
  }

  [class*=grid__col-].grid__col-no-clear--xxl {
    clear: none;
  }

  [class*=grid__col-].grid__col-left--xxl {
    float: left;
  }

  [class*=grid__col-].grid__col-right--xxl {
    float: right;
  }

  .grid__col-0--xxl {
    display: none;
  }

  .grid__pull-0--xxl,
.grid__push-0--xxl {
    margin-left: 0;
  }

  .grid__col-1--xxl {
    width: 8.3333333333%;
  }

  .grid__pull-1--xxl {
    margin-left: -8.3333333333%;
  }

  .grid__push-1--xxl {
    margin-left: 8.3333333333%;
  }

  .grid__col-2--xxl {
    width: 16.6666666667%;
  }

  .grid__pull-2--xxl {
    margin-left: -16.6666666667%;
  }

  .grid__push-2--xxl {
    margin-left: 16.6666666667%;
  }

  .grid__col-3--xxl {
    width: 25%;
  }

  .grid__pull-3--xxl {
    margin-left: -25%;
  }

  .grid__push-3--xxl {
    margin-left: 25%;
  }

  .grid__col-4--xxl {
    width: 33.3333333333%;
  }

  .grid__pull-4--xxl {
    margin-left: -33.3333333333%;
  }

  .grid__push-4--xxl {
    margin-left: 33.3333333333%;
  }

  .grid__col-5--xxl {
    width: 41.6666666667%;
  }

  .grid__pull-5--xxl {
    margin-left: -41.6666666667%;
  }

  .grid__push-5--xxl {
    margin-left: 41.6666666667%;
  }

  .grid__col-6--xxl {
    width: 50%;
  }

  .grid__pull-6--xxl {
    margin-left: -50%;
  }

  .grid__push-6--xxl {
    margin-left: 50%;
  }

  .grid__col-7--xxl {
    width: 58.3333333333%;
  }

  .grid__pull-7--xxl {
    margin-left: -58.3333333333%;
  }

  .grid__push-7--xxl {
    margin-left: 58.3333333333%;
  }

  .grid__col-8--xxl {
    width: 66.6666666667%;
  }

  .grid__pull-8--xxl {
    margin-left: -66.6666666667%;
  }

  .grid__push-8--xxl {
    margin-left: 66.6666666667%;
  }

  .grid__col-9--xxl {
    width: 75%;
  }

  .grid__pull-9--xxl {
    margin-left: -75%;
  }

  .grid__push-9--xxl {
    margin-left: 75%;
  }

  .grid__col-10--xxl {
    width: 83.3333333333%;
  }

  .grid__pull-10--xxl {
    margin-left: -83.3333333333%;
  }

  .grid__push-10--xxl {
    margin-left: 83.3333333333%;
  }

  .grid__col-11--xxl {
    width: 91.6666666667%;
  }

  .grid__pull-11--xxl {
    margin-left: -91.6666666667%;
  }

  .grid__push-11--xxl {
    margin-left: 91.6666666667%;
  }

  .grid__col-12--xxl {
    width: 100%;
  }

  .grid__pull-12--xxl {
    margin-left: -100%;
  }

  .grid__push-12--xxl {
    margin-left: 100%;
  }
}
*,
::after,
::before {
  position: relative;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: neue-haas-grotesk-text, sans-serif;
  line-height: 1.6;
  color: #fffbf8;
  background: #fffbf8;
}

.container {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
}

.full-pic {
  display: block;
  width: 100%;
  height: auto;
}

.d-table {
  display: table;
  width: 100%;
  height: 100%;
}

.d-cell {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

.cf::after {
  content: "";
  display: block;
  clear: both;
}

@media (min-width: 48em) {
  .absolute-wrapper {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.circle {
  position: absolute;
}
@media not all and (min-width: 48em) {
  .circle {
    display: none;
  }
}

/*============================================================================*\
   Logo
\*============================================================================*/
.logo {
  z-index: 11;
  color: #fffbf8;
  text-decoration: none;
}
.logo span {
  display: block;
}

.logo--header {
  float: left;
}

@media (min-width: 48em) {
  .logo--footer {
    float: left;
  }
}

.logo__title {
  font-size: 20px;
  font-weight: 800;
  line-height: 17px;
  margin-bottom: 3px;
}

.logo__subtitle {
  font-size: 11px;
  line-height: 11px;
}

/*============================================================================*\
   Header
\*============================================================================*/
.header {
  background-size: cover;
  background-position: center center;
}

/**
 * Burger Menu
 */
.burger {
  z-index: 11;
  width: 30px;
  height: 23px;
  float: right;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.line {
  height: 2px;
  background: #fffbf8;
  position: absolute;
  width: 100%;
  display: block;
  transform-origin: center;
  transition: 0.4s transform cubic-bezier(0.175, 0.885, 0.32, 1.275), 0.4s opacity cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.line--top {
  top: 0;
}

.line--mid {
  top: 50%;
  margin-top: -1px;
}

.line--bot {
  bottom: 0;
}

.show-menu .burger .line--top {
  transform: rotate(45deg) translate(7px, 8px);
}
.show-menu .burger .line--mid {
  opacity: 0;
}
.show-menu .burger .line--bot {
  transform: rotate(-45deg) translate(7px, -8px);
}

/*============================================================================*\
   Navigation
\*============================================================================*/
.nav {
  float: right;
}

.menu__item {
  display: inline-block;
}
.menu__item + .menu__item {
  margin-left: 2rem;
}

.menu__link {
  font-size: 0.875em;
  font-weight: 500;
  display: block;
  color: #fffbf8;
  text-decoration: none;
}
@media (min-width: 80em) {
  .menu__link {
    font-size: 1em;
    font-weight: 500;
  }
}

@media not all and (min-width: 48em) {
  .nav--header {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #005350;
    padding-top: 8rem;
    float: none;
    opacity: 0;
    transition: 0.6s opacity cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .nav--header .menu {
    width: 100%;
  }
  .nav--header .menu .menu__item {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .nav--header .menu .menu__item + .menu__item {
    margin-top: 3rem;
  }

  .show-menu .nav--header {
    opacity: 1;
  }
}
/*============================================================================*\
   Content
\*============================================================================*/
.content {
  color: #005350;
}
.content p {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.625;
  max-width: 380px;
  color: #005350;
}
@media (min-width: 80em) {
  .content p {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }
}
/*============================================================================*\
   Buttons
\*============================================================================*/
.btn {
  font-size: 0.875em;
  font-weight: 500;
  min-width: 200px;
  padding: 15px 20px;
  color: #fffbf8;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 40px;
  transition: color 0.5s cubic-bezier(0.19, 1, 0.22, 1), background 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (min-width: 80em) {
  .btn {
    font-size: 1em;
    font-weight: 500;
    padding: 20px 30px;
  }
}

.btn--orange {
  background: #e39e70;
}
.btn--orange:hover {
  background: #739e9c;
}

/*============================================================================*\
   Contact
\*============================================================================*/
.contact {
  background: #005350;
}
@media (min-width: 48em) {
  .contact {
    margin-top: -140px;
  }
}

.list-contact {
  width: 100%;
}
.list-contact span {
  display: inline-block;
  vertical-align: top;
}

.list-contact__item {
  font-size: 0.875em;
  font-weight: 700;
}
.list-contact__item + .list-contact__item {
  margin-top: 1rem;
}
.list-contact__item a {
  color: #fffbf8;
  text-decoration: none;
}
@media (min-width: 48em) {
  .list-contact__item {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.6666666667;
  }
}

.list-contact__label {
  min-width: 100px;
}
@media (min-width: 48em) {
  .list-contact__label {
    min-width: 140px;
  }
}

.footer {
  background: #005350;
}

.footer__last {
  border-top: 1px solid #236a67;
}

/*============================================================================*\
   Titles
\*============================================================================*/
.title-medium, .content h2 {
  font-size: 2.375em;
  font-weight: 900;
  line-height: 1.3684210526;
  font-family: starling, serif;
  margin-bottom: 1.5rem;
}
@media (min-width: 80em) {
  .title-medium, .content h2 {
    font-size: 3.375em;
    font-weight: 900;
    line-height: 1.3333333333;
  }
}

.subtitle, .content h3 {
  font-size: 1.25em;
  font-weight: 500;
  line-height: 1.5;
  font-family: neue-haas-grotesk-text, sans-serif;
  margin-bottom: 2rem;
}
@media (min-width: 80em) {
  .subtitle, .content h3 {
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.4166666667;
  }
}

/*============================================================================*\
   Modal
\*============================================================================*/
.modal {
  z-index: 100;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
}

/*============================================================================*\
   Section
\*============================================================================*/
.section--image {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

/*============================================================================*\
   Homepage
\*============================================================================*/
.section--presentation .circle--orange {
  top: -140px;
  right: 0;
}

.section--interventions .circle--green {
  top: -72px;
  left: 0;
}

@media (min-width: 48em) {
  .section--contact {
    height: 890px;
  }
}

.section--rendezvous {
  overflow: hidden;
}
.section--rendezvous .circle--orange {
  top: 100%;
  margin-top: -128px;
}
.section--rendezvous .circle--green-light {
  top: -70px;
  right: 25%;
}

.figure--portrait .circle {
  z-index: 10;
  bottom: -40px;
  left: -40px;
}

.figure--interventions .circle {
  bottom: -140px;
  right: -140px;
}

.modal {
  z-index: 100;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
}

.modal__close {
  position: absolute;
}

.modal__iframe {
  height: 304px;
  min-height: 304px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
}