/*============================================================================*\
   Modal
\*============================================================================*/

.modal {
	z-index: 100;
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.4);
}
