/*============================================================================*\
   Header
\*============================================================================*/

.header {
	background-size: cover;
	background-position: center center;
}

/**
 * Burger Menu
 */

.burger {
	z-index: 11;
	width: 30px;
	height: 23px;
	float: right;
	padding: 0;
	margin: 0;
	border: none;
	background: none;
}

.line {
	height: 2px;
	background: c(white);
	position: absolute;
	width: 100%;
	display: block;
	transform-origin: center;
	transition: 0.4s transform $out-back, 0.4s opacity $out-back;
}

.line--top {
	top: 0;
}

.line--mid {
	top: 50%;
	margin-top: -1px;
}

.line--bot {
	bottom: 0;
}

.show-menu {
	.burger {
		.line--top {
			transform: rotate(45deg) translate(7px, 8px);
		}
		.line--mid {
			opacity: 0;
		}
		.line--bot {
			transform: rotate(-45deg) translate(7px, -8px);
		}
	}
}
