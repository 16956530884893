/*============================================================================*\
   Homepage
\*============================================================================*/

.section--presentation {
	.circle--orange {
		top: -140px;
		right: 0;
	}
}

.section--interventions {
	.circle--green {
		top: -72px;
		left: 0;
	}
}

.section--contact {
	@media #{md("s")} {
		height: 890px;
	}
}

.section--rendezvous {
	overflow: hidden;

	.circle--orange {
		top: 100%;
		margin-top: -128px;
	}

	.circle--green-light {
		top: -70px;
		right: 25%;
	}
}

.figure--portrait {
	.circle {
		z-index: 10;
		bottom: -40px;
		left: -40px;
	}
}

.figure--interventions {
	.circle {
		bottom: -140px;
		right: -140px;
	}
}
