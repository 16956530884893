/*============================================================================*\
   Section
\*============================================================================*/

.section {
}

.section--image {
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}
