/*============================================================================*\
   Content
\*============================================================================*/

.content {
	color: c(green);

	p {
		@include fz(body-small);
		max-width: 380px;
		color: c(green);

		@media #{md("l")} {
			@include fz(body);
		}
	}

	h2 {
		@extend .title-medium;
	}

	h3 {
		@extend .subtitle;
	}
}
