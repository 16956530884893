/*============================================================================*\
   Contact
\*============================================================================*/

.contact {
	background: c(green);

	@media #{md("s")} {
		margin-top: -140px;
	}
}

.list-contact {
	width: 100%;

	span {
		display: inline-block;
		vertical-align: top;
	}
}

.list-contact__item {
	@include fz(tiny);

	& + .list-contact__item {
		margin-top: space(2);
	}

	a {
		color: c(white);
		text-decoration: none;
	}

	@media #{md("s")} {
		@include fz(body);
	}
}

.list-contact__label {
	min-width: 100px;

	@media #{md("s")} {
		min-width: 140px;
	}
}
