/*============================================================================*\
   Titles
\*============================================================================*/

.title-medium {
	@include fz(display-3);
	font-family: ff(serif);
	margin-bottom: space(3);

	@media #{md("l")} {
		@include fz(display-2);
	}
}

.subtitle {
	@include fz(subtitle-small);
	font-family: ff(sans);
	margin-bottom: space(4);

	@media #{md("l")} {
		@include fz(subtitle);
	}
}
