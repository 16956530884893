/*============================================================================*\
	 Main file
\*============================================================================*/

@import "./config";

$has-classes: true;
@import "~@studiometa/scss-toolkit";

@import "~@studiometa/scss-toolkit/src/components/reset";
@import "~@studiometa/scss-toolkit/src/components/grid";

*,
::after,
::before {
	position: relative;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
	font-family: ff(sans);
	line-height: 1.6;
	color: c(white);
	background: c(white);
}

.container {
	width: 100%;
	max-width: 1248px;
	margin: 0 auto;
}

.full-pic {
	display: block;
	width: 100%;
	height: auto;
}

.d-table {
	display: table;
	width: 100%;
	height: 100%;
}

.d-cell {
	display: table-cell;
	width: 100%;
	vertical-align: middle;
}

.cf {
	&::after {
		content: "";
		display: block;
		clear: both;
	}
}

.absolute-wrapper {
	@media #{md("s")} {
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.circle {
	position: absolute;

	@media #{md("s", "max")} {
		display: none;
	}
}

@import "components/logo";
@import "components/header";
@import "components/menu";
@import "components/content";
@import "components/buttons";
@import "components/contact";
@import "components/footer";
@import "components/title";
@import "components/modal";
@import "components/section";
@import "pages/home";

.modal {
	z-index: 100;
	display: block;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
}

.modal__close {
	position: absolute;
}

.modal__iframe {
	height: 304px;
	min-height: 304px;
	width: 100%;
	max-width: 1000px;
	margin: 0 auto;
	// display: block;
	display: flex;
}
