/*============================================================================*\
   Buttons
\*============================================================================*/

.btn {
	@include fz("button-small");
	min-width: 200px;
	padding: 15px 20px;
	color: c(white);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border-radius: 40px;
	transition: color 0.5s $out-expo, background 0.5s $out-expo;

	@media #{md("l")} {
		@include fz("button");
		padding: 20px 30px;
	}
}

.btn--orange {
	background: c(orange);

	&:hover {
		background: c(green-light);
	}
}
