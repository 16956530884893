/*============================================================================*\
   Navigation
\*============================================================================*/

.nav {
	float: right;
}

.menu__item {
	display: inline-block;

	+ .menu__item {
		margin-left: space(4);
	}
}

.menu__link {
	@include fz("button-small");
	display: block;
	color: c(white);
	text-decoration: none;

	@media #{md("l")} {
		@include fz("button");
	}
}

@media #{md("s", "max")} {
	.nav--header {
		z-index: 10;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: c(green);
		padding-top: space(16);
		float: none;
		opacity: 0;
		transition: 0.6s opacity $out-cubic;

		.menu {
			width: 100%;

			.menu__item {
				margin: 0;
				width: 100%;
				text-align: center;
				+ .menu__item {
					margin-top: space(6);
				}
			}
		}
	}

	.show-menu {
		.nav--header {
			opacity: 1;
		}
	}
}
